import React from 'react';
import { IconUserShield } from '@tabler/icons-react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import createElementRoutes from '@/router/create';
import dnaConfig from '../../../config/dna.config.json';
import AdminDashboard from './Dashboard/adminDashboard';
import { routes as routesFactory } from './factory';
import routeDashboard from './Dashboard/routes.dashboard';
import routeDataElements from './DataElements/routes.dataElements';
import routeEntities from './Entities/route.entities';
import routeDataBlocks from './DataBlocks/route.dataBlocks';
import HomePageAdminComponent from './components/tabs/adminHomePage';
import { CustomRouteObject } from '@/router/types';

const routesAdmin: CustomRouteObject[] = [
  createElementRoutes< PersonEntity | CompanyEntity>({
    id: dnaConfig.routes.admin.label,
    path: dnaConfig.routes.admin.baseUrl,
    icon: IconUserShield,
    sectionTitle: '',
    component: <HomePageAdminComponent />,
    idComponent: <AdminDashboard />,
    idGetAll: true,
    childrens: [
      routesFactory,
      routeDashboard,
      routeDataElements,
      routeEntities,
      routeDataBlocks
    ]
  })
];

export default routesAdmin;
