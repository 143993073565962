import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { BasePortfolioDashboardProps, DashboardConfig, dashboardSpacing } from './types';
import PortfolioDashboardGroup from './group';
import PortfolioDashboardDataflow from './dataflow';
import PortfolioDashboardGroupSelect from './groupselect';
import PortfolioDashboardGroupSegmentation from './groupsegmentation';
import PatrimonialComplexityPortfolioDashboardContainers from './patrimonialcomplexity';
import PortfolioDashboardProxemee, { getProxemeeSubGroups } from './proxemee';

function PortfolioDashboardContainers(props: { dashboardConfig: DashboardConfig } & Omit<BasePortfolioDashboardProps, 'group'>): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container alignItems='stretch' spacing={6}>
      {props.dashboardConfig.sections.map(section => (
        <Grid size={section.type === 'compact' ? 12 : section.id === 'alerts' ? 'auto' : 'grow'} key={section.id} overflow='visible'>
          <Paper sx={{ bgcolor: 'transparent', width: section.id === 'alerts' ? 'fit-content' : '100%', height: '100%', borderStyle: 'dashed' }}>
            <Grid container spacing={dashboardSpacing} p={1} width={section.id === 'alerts' ? 'min-content' : '100%'}>
              {!section.noLabel &&
                <Grid>
                  <Stack spacing={dashboardSpacing} direction='row' alignItems='center' p={1} mt='-30px !important' bgcolor={theme.palette.grey['50']} width='min-content'>
                    {section.icon && <section.icon />}
                    <Typography variant='h3' whiteSpace='nowrap'>
                      {t(`segmentation.dashboard.sections.${section.id}.label`)}
                    </Typography>
                  </Stack>
                </Grid>
              }
              {section.type === 'compact' && section.groups?.map(group => (
                <Grid size={12} key={`compact-${group.subGroupId}`} overflow='auto'>
                  <PortfolioDashboardDataflow {...props} group={group} />
                </Grid>
              ))}
              {section.type === 'full' &&
                ((section.groups && getProxemeeSubGroups({ subGroupId: section.id, subGroups: section.groups } ).length > 0) ?
                  <PortfolioDashboardProxemee full {...{ baseGroupId: props.baseGroupId, group: { subGroupId: section.id, subGroups: section.groups }, callSegmentation: props.callSegmentation }} />
                  :
                  section.groups?.map(group => (
                    <Grid size={12} key={`full-${group.subGroupId}`} overflow='auto'>
                      {group.type === 'select' && <PortfolioDashboardGroupSelect {...props} group={group} />}
                      {group.type === 'segmentation' && <PortfolioDashboardGroupSegmentation {...props} group={group} />}
                      {(!group.type || group.type === 'parent') && <PortfolioDashboardGroup {...props} group={group} />}
                      {group.type === 'custom' &&
                      <>
                        {group.subGroupId === 'patrimonialComplexity' && <PatrimonialComplexityPortfolioDashboardContainers {...props} group={group} />}
                      </>
                      }
                    </Grid>
                  )))
              }
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default PortfolioDashboardContainers;
