import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { CompanyEntity, EntityLink, EntityLinksAggregation, EntityRef, PersonEntity } from '@deecision/dna-interfaces';
import { EChartsOption } from 'echarts-for-react/src/types';
import { useTheme } from '@mui/material/styles';
import { EntityLinksQueryAggregatorService } from '../../../../api/services/query';
import { SimpleFilter } from '@/components/filters/simple';
import EntitiesCards from '@/main/containers/cards/dispatch';
import { getEntity } from '../../../providers/getter';
import dnaConfig from '../../../../config/dna.config.json';
import { WEBAPP_NAME } from '../../../../env/env';
import RelationComponent from '../../../modules/entities/relations/components';
import { proxemeeCategories } from '../../proxemee/proxemee';
import { useFullscreen } from '@/wrappers/fullscreen';

export interface RelationsChartProps {
  entity: EntityRef,
  id: string,
  filters: SimpleFilter[]
}

interface ExtendedEntityLinksAggregation extends EntityLinksAggregation {
  deployAction?: boolean,
  deployedFrom?: number,
  linkTo?: number
}

type GraphDataType = {
  commonRelationsWithMain?: number,
  index: number,
  id: string,
  name: string,
  value: string,
  type: string,
  category: number,
  symbolSize: number,
  x: number,
  y: number,
  deployed?: boolean,
  deployedFrom?: number
}

type GraphLinkType = {
  source: number,
  target: number,
  entitySource: {
    name: string | null,
    entityType: string,
    entityId: string
},
  entityTarget: {
    name: string | null,
    entityType: string,
    entityId: string
},
  links: EntityLink[],
  deployedFrom: number | undefined,
  label: {
    normal: {
      show: boolean,
      formatter: string,
      position: string
    }
  }
};

const calculateCategory = (proximityScore: number, highestProximityScore: number) => {
  const range = highestProximityScore / 4;

  return proximityScore >= 0 && proximityScore <= highestProximityScore
    ? 5 - Math.ceil(proximityScore / range)
    : 4;
};

const calculateHighestPrxmee = (data: EntityLinksAggregation[]) => Math.max(...data
  .filter(d => d.links.find(link => link.details?.prxmee?.score))
  .map(d => d.links.find(link => link.details?.prxmee?.score)?.details?.prxmee?.score || 0));

const isSameFamily = (entity1: Omit<EntityRef, 'name'> & { name: string | null }, entity2: Omit<EntityRef, 'name'> & { name: string | null }) => {
  const getFirstName = (name: string | null) => name?.split(',')[0].split('(')[0];

  return getFirstName(entity1.name) === getFirstName(entity2.name);
};

const categories = [
  { name: `PrincipalEntity`, keyword: {}, base: 'PrincipalEntity' },
  ...proxemeeCategories
];

const baseSeriesForOption = {
  type: 'graph',
  layout: 'none',
  animation: false,
  label: {
    normal: {
      show: true,
      position: 'bottom',
      formatter: (params: { data: { name: string } }) => ( `${params.data.name}`)
    }
  },
  draggable: false,
  // roam: 'roam',
  roam: 'drag',
  zoom: 1,
  lineStyle: {
    color: 'source',
    curveness: 0.01,
    width: 2.5
  },
  emphasis: {
    focus: 'adjacency',
    lineStyle: {
      width: 15
    }
  }
};

const baseLegendForOption = {
  selectedMode: true,
  left: 0,
  top: 20,
  orient: 'vertical',
  data: categories.filter(category => category.base !== 'PrincipalEntity').map(a => a.name)
};

const baseRadarForOptions = {
  indicator: [
    { text: 'Personal' },
    { text: 'Business' },
    { text: 'Public' },
    { text: 'Extended' }
  ],
  center: ['50%', '50%'],
  radius: 630,
  startAngle: 90,
  splitNumber: 4,
  shape: 'circle',
  splitArea: {
    areaStyle: {
      color: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 0)'],
      shadowColor: 'rgba(0, 0, 0, 0.2)',
      shadowBlur: 10
    }
  },
  axisLine: {
    lineStyle: {
      color: 'rgba(0, 0, 0, 0)'
    }
  },
  splitLine: {
    lineStyle: {
      color: 'lightgrey',
      width: 1
    }
  }
};

function RelationsChart(props: RelationsChartProps) {
  const theme = useTheme();
  const { isFullscreen } = useFullscreen();
  const [centralPoint] = useState({ x: 500, y: 300 });
  const [data, setData] = useState<ExtendedEntityLinksAggregation[]>([]);
  const [initBaseDataLength, setInitBaseDataLength] = useState<number>(0);
  const [baseDataLength, setBaseDataLength] = useState<number>(0);
  const [graphData, setGraphData] = useState<GraphDataType[]>();
  const [graphLink, setGraphLink] = useState<GraphLinkType[]>();
  const [newDataToAdd, setNewDataToAdd] = useState<ExtendedEntityLinksAggregation[]>([]);
  const [addedLinks, setAddedLinks] = useState<GraphLinkType[]>([]);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [entityOnHover, setEntityOnHover] = useState<EntityRef & {commonRelationsWithMain?: number}>();
  const [linkOnHover, setLinkOnHover] = useState<{
    entity1: CompanyEntity | PersonEntity | undefined,
    entity2: CompanyEntity | PersonEntity | undefined,
    links: EntityLinksAggregation | undefined
  }>({ entity1: undefined, entity2: undefined, links: undefined });
  const [switchLabelLink, setSwitchLabelLink] = useState<boolean>(true);
  const [option, setOption] = useState<EChartsOption>({
    color: [
      theme.palette.primary.main,
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.secondary.main,
      theme.palette.error.main,
      theme.palette.primary.dark
    ],
    legend: [ baseLegendForOption ],
    series: [ baseSeriesForOption ],
    radar: [ baseRadarForOptions ]
  });
  let undeployObj = {
    deploying: false,
    deployIndex: 0
  };
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout[]>([]);
  const refreshTimeout = () => {
    const newTimeoutId: typeof timeoutId = [];
    const resetValue = () => {
      setEntityOnHover(undefined);
      setIsHovered(false);
      setLinkOnHover({ entity1: undefined, entity2: undefined, links: undefined });
    };
    timeoutId?.forEach((id) => {
      clearTimeout(id);
    });
    setTimeoutId([]);
    newTimeoutId?.push(setTimeout(() => {
      resetValue();
    }, 300000));
    setTimeoutId(prevState => [...prevState, ...newTimeoutId]);
  };

  const makeChart = (params: { data: EntityLinksAggregation[], tmpAddedLinks?: GraphLinkType[], tmpGraphData?: GraphDataType[] }) => {
    const categoryNodeCount: { [category: number]: number } = {};
    const highestPrxmeeScore = calculateHighestPrxmee(data);

    params.data.forEach((link) => {
      const categoryProxemeeObj = proxemeeCategories.find(category => category.name.toLowerCase() === (link.links[0].details?.prxmee?.level?.code || 'Unknown'));
      const category = isSameFamily(link.entity1, link.entity2) ? 1 :  calculateCategory(link.links[0].details?.prxmee?.score || 0, highestPrxmeeScore);
      const categoryId = categoryProxemeeObj?.id ? categoryProxemeeObj.id : 4;

      categoryNodeCount[categoryId || category] = (categoryNodeCount[categoryId || category] || 0) + 1;
    });

    setOption((prevState: EChartsOption) => ({
      ...prevState,
      // toolbox,
      series: [
        {
          ...baseSeriesForOption,
          zoom: 0.8, // Configure zoom onClick for zoom filters
          center: [centralPoint.x, centralPoint.y],
          data: [
            {
              id: props.entity.entityId,
              name: props.entity.name?.substring(0, props.entity.name.indexOf('(')) || '',
              value: '',
              type: props.entity.entityType,
              category: 0,
              symbolSize: 50,
              x: centralPoint.x,
              y: centralPoint.y
            },
            ...(params.tmpGraphData  ? params.tmpGraphData : [])
          ],
          categories: categories
            .map((a, index) => ({
              ...a,
              name: `${a.name} (${categoryNodeCount[index] || 0})`
            })),
          edges: [
            {
              source: 0,
              target: 0,
              label: '',
              commonCompagnies: ''
            },
            ...params.tmpAddedLinks ? params.tmpAddedLinks : addedLinks,
            ...data.map((link, index) => ({
              source: (link as unknown as Record<string, string>).linkTo ? (link as unknown as Record<string, string>).linkTo : 0,
              target: index + 1,
              entitySource: link.entity1,
              entityTarget: link.entity2,
              links: link,
              label: {
                normal: {
                  show: true,
                  formatter: `Prxmee: ${link.links[0].details?.prxmee?.score.toString() || '0'}${link.links[0].details?.nbCompaniesInCommon !== undefined ? ` - Comp: ${link.links[0].details?.nbCompaniesInCommon}` : ''}`,
                  position: 'middle',
                  fontSize: switchLabelLink ? 14 : 0
                }
              }
            }))
          ]
        }
      ],
      legend: {
        selectedMode: true,
        left: 0,
        // top: 30,
        top: isFullscreen ? 85 : 30,
        orient: 'vertical',
        data: categories
          .filter(category => category.base !== 'PrincipalEntity')
          .map((a, index) => ({
            ...a,
            name: `${a.name} (${categoryNodeCount[index + 1] || 0})`
          }))
      }
    }));
  };

  const getRelations = (params: { entity: EntityRef, toEntityType?: EntityRef['entityType'], deploy?: boolean, deployedFromIndex?: number, action: React.Dispatch<React.SetStateAction<ExtendedEntityLinksAggregation[]>> }) => {
    const EntityAggregatorService = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({ entityType: props.entity.entityType, toEntityType: params.toEntityType || 'deecPerson' });

    EntityAggregatorService.get(
      params.entity.entityId,
      props.filters.find(filter => filter.id === 'showActive')?.active && props.filters.find(filter => filter.id === 'showInactive')?.active ? 'all' : !props.filters.find(filter => filter.id === 'showInactive')?.active
    )
      .then((res) => {
        if (res.data) {
          const modifiedData = res.data.map(item => ({ ...item, deployedFrom: params.deployedFromIndex, deployAction: params.deploy }));

          params.action(params.deployedFromIndex && params.deploy !== undefined? modifiedData : res.data);
        }
      });
  };

  const handleChartClick = (params: { data: { type?: string, id?: string, entitySource? : EntityRef, entityTarget?: EntityRef } }) => {
    if (params.data.id && params.data.type) {
      const entityRefClicked = { entityType: params.data.type, entityId: params.data.id };
      const indexEntityClickedGraphData = graphData?.findIndex(obj => (obj.id) === entityRefClicked.entityId);

      if (indexEntityClickedGraphData !== -1 && graphData && indexEntityClickedGraphData) {
        !graphData[indexEntityClickedGraphData].deployed
          ? undeployObj = { deploying: true,  deployIndex: indexEntityClickedGraphData }
          : undeployObj = { deploying: false,  deployIndex: indexEntityClickedGraphData };
      }
      getRelations({ entity: entityRefClicked, deploy: undeployObj.deploying, deployedFromIndex: indexEntityClickedGraphData, action: setNewDataToAdd });
    }
    if (params.data.entitySource) {
      window.open(`/${WEBAPP_NAME}/${dnaConfig.routes.relations.relativeUrl}/show?entity1=${params.data.entitySource?.entityId}&entity1Type=${params.data.entitySource?.entityType}&entity2=${params.data.entityTarget?.entityId}&entity2Type=${params.data.entityTarget?.entityType}`, '_blank');
    }
  };

  const handleChartMouseHover = ( params: { data: {
    commonRelationsWithMain: number | undefined, type: string, id?: string, links?: EntityLinksAggregation, entitySource: EntityRef, entityTarget: EntityRef
}, event: {event: {offsetX: number, offsetY: number}} }) => {
    const { offsetX, offsetY } = params.event as unknown as { offsetX: number, offsetY: number };

    if (isHovered) {
      return;
    }
    setIsHovered(true);
    setMousePos({ x: offsetX, y: offsetY });
    if (timeoutId.length > 0) {
      refreshTimeout();
    }
    if (params.data.links) {
      entityOnHover ? setEntityOnHover(undefined) : '';
      getEntity({ entityType: params.data.entitySource.entityType, entityId: params.data.entitySource.entityId }).then((res) => {
        const entity1 = res as PersonEntity | CompanyEntity | undefined;

        setLinkOnHover(prevState => ({
          ...prevState,
          links: params.data.links,
          entity1
        }));
      });
      getEntity({ entityType: params.data.entityTarget.entityType, entityId: params.data.entityTarget.entityId }).then((res) => {
        const entity2 = res as PersonEntity | CompanyEntity | undefined;

        setLinkOnHover(prevState => ({
          ...prevState,
          links: params.data.links,
          entity2
        }));
      });

      return;
    }

    if (params.data.id) {
      setLinkOnHover({ entity1: undefined, entity2: undefined, links: undefined });
      setEntityOnHover({ entityId: params.data.id, entityType: params.data.type, commonRelationsWithMain: params.data.commonRelationsWithMain });
    }
  };

  const handleChartMouseLeave = () => {
    const newTimeoutId: typeof timeoutId = [];

    setIsHovered(false);
    newTimeoutId?.push(setTimeout(() => {
      setEntityOnHover(undefined);
      setLinkOnHover({ entity1: undefined, entity2: undefined, links: undefined });
    }, 900));
    setTimeoutId(prevState => [...prevState, ...newTimeoutId]);
  };

  const onEvents = {
    click: handleChartClick,
    mouseover: handleChartMouseHover,
    mouseout: handleChartMouseLeave,
    mouseleave: handleChartMouseLeave
  };

  useEffect(() => {
    getRelations({ ...props, action: setData });
  }, [props.entity, props.id, props.filters]);

  useEffect(() => {
    const highestPrxmeeScore = calculateHighestPrxmee(data);

    const tmpGraphData = data.map((link, index) => {
      const distance = (3 / ((isSameFamily(link.entity1, link.entity2) ? highestPrxmeeScore : link.links[0].details?.prxmee?.score || 0) + 8)); // set the 8 to something else to configure distance
      const categoryProxemeeObj = proxemeeCategories.find(category => category.name.toLowerCase() === (link.links[0].details?.prxmee?.level?.code || 'Unknown'));
      const tmpGraphDataElement: GraphDataType = {
        index,
        id: link.entity2.entityId,
        name: link.entity2.name?.substring(0, link.entity2.name.indexOf('(')) || '',
        value: isSameFamily(link.entity1, link.entity2) ? highestPrxmeeScore.toString() : link.links[0].details?.prxmee?.score ? link.links[0].details?.prxmee?.score.toString() : '0',
        type: link.entity2.entityType,
        category: isSameFamily(link.entity1, link.entity2) ? 1 : categoryProxemeeObj?.id || 4 || calculateCategory(link.links[0].details?.prxmee?.score || 0, highestPrxmeeScore),
        symbolSize: ((data && data.length > 15) ? ((data && data.length < 50) ? 18 : 12) : 25),
        x: (centralPoint.x) + distance * Math.cos((2 * Math.PI) / (data.length) * data.indexOf(link)),
        y: (centralPoint.y) + distance * Math.sin((2 * Math.PI) / (data.length) * data.indexOf(link)),
        deployed: false
      };

      return tmpGraphDataElement;
    });

    if (initBaseDataLength === 1) {
      setBaseDataLength(data.length);
    }
    setInitBaseDataLength(initBaseDataLength + 1);
    setGraphData(tmpGraphData);
    makeChart({ data, tmpGraphData });
  }, [data, isFullscreen]);

  useEffect(() => {
    let tmpAddedLinks = graphLink || [];
    const tmp: GraphDataType[] = graphData || [];
    let tmpData = data;
    const nbNewNode = newDataToAdd.filter(entity => data.findIndex(obj => (obj.entity2.entityId || obj.entity1.entityId) === entity.entity2.entityId) === -1 && props.id !== entity.entity2.entityId).length;
    let newNodeIndex = 0;

    newDataToAdd.forEach((entity, index) => {
      const sourceIndex = data.findIndex(obj => obj.entity2.entityId === entity.entity1.entityId);
      const indexOfName = data.findIndex(obj => (obj.entity2.entityId || obj.entity1.entityId) === entity.entity2.entityId);

      if (indexOfName !== -1) {
        const linkExists = tmpAddedLinks.some((item: { source: number, target: number }) => item.source === sourceIndex + 1 && item.target === indexOfName + 1);

        if (!linkExists) {
          tmpAddedLinks.push({
            source: sourceIndex + 1,
            target: indexOfName + 1,
            entitySource: entity.entity1,
            entityTarget: entity.entity2,
            links: entity.links,
            deployedFrom: entity.deployedFrom,
            label: {
              normal: {
                show: true,
                formatter: `${entity.links[0].details?.prxmee?.score.toString() || '0'}${entity.links[0].details?.nbCompaniesInCommon !== undefined ? ` - ${entity.links[0].details?.nbCompaniesInCommon}` : ''}`,
                position: 'middle'
              }
            }
          }
          );
        }
      } else if (indexOfName === -1 && props.id !== entity.entity2.entityId && graphData) {
        const deltaX = Math.cos(((2 * Math.PI) / (nbNewNode + newNodeIndex)) * index);
        const deltaY = Math.sin(((2 * Math.PI) / (nbNewNode + newNodeIndex)) * index);
        const distanceFactor = 0.6 + (entity.links[0].details?.prxmee?.score || 0) / 100;
        const distanceToCenter = Math.sqrt((graphData[sourceIndex - 1].x - centralPoint.x) ** 2 + (graphData[sourceIndex - 1].y - centralPoint.y) ** 2) * distanceFactor;
        const distanceToNode = 0.4;
        const categoryProxemeeObj = proxemeeCategories.find(category => category.keyword === (entity.links[0].details?.prxmee?.level?.code || 'Unknown'));

        tmpData.push({ ...entity, linkTo: sourceIndex + 1, deployedFrom: newDataToAdd[0].deployedFrom });
        tmp.push({
          index: data.length + newNodeIndex,
          id: entity.entity2.entityId,
          name: entity.entity2.name?.substring(0, entity.entity2.name.indexOf('(')) || '',
          value: entity.links[0].details?.prxmee?.score ? entity.links[0].details?.prxmee?.score.toString() : '0',
          type: entity.entity2.entityType,
          category: isSameFamily(entity.entity1, entity.entity2) ? 1 : categoryProxemeeObj?.id || calculateCategory(entity.links[0].details?.prxmee?.score || 0, calculateHighestPrxmee(data)),
          symbolSize: ((newDataToAdd && newDataToAdd.length > 15) ? ((newDataToAdd && newDataToAdd.length < 50) ? 18 : 7) : 25),
          x: graphData[sourceIndex - 1].x - distanceToCenter * deltaX + distanceToNode * deltaX,
          y: graphData[sourceIndex - 1].y - distanceToCenter * deltaY + distanceToNode * deltaY,
          deployed: false,
          deployedFrom: entity.deployedFrom
        });
        newNodeIndex += 1;
      }
    });
    const uniqueSources = new Set(tmpAddedLinks.map((addedData: { source: number }) => addedData.source));
    const targetCountMap = new Map<number, number>();

    uniqueSources.forEach((source) => {
      const count = tmpAddedLinks.reduce((c: number, addedData: { source: number, target: number }) => {
        if (addedData.source === source && addedData.target <= baseDataLength) {
          return c + 1;
        }

        return c;
      }, 0);
      targetCountMap.set(source as number - 2, count);
    });
    tmp.forEach((item) => {
      const commonRelationsCount = targetCountMap.get(item.index - 1);
      if (commonRelationsCount !== undefined) {
        item.commonRelationsWithMain = commonRelationsCount;
      }
    });
    /// UNDEPLOYED HANDLING remove into other function ////
    if (newDataToAdd.length > 0 && !newDataToAdd[0].deployAction) {
      tmpData = tmpData.filter(item =>
        !(item.deployAction !== undefined && item.deployedFrom !== undefined && item.deployedFrom === newDataToAdd[0].deployedFrom && !newDataToAdd[0].deployAction)
      );
      tmpAddedLinks = tmpAddedLinks.filter((link: { deployedFrom: number | undefined }) =>
        !(newDataToAdd[0].deployAction !== undefined && link.deployedFrom !== undefined && link.deployedFrom === newDataToAdd[0].deployedFrom && !newDataToAdd[0].deployAction)
      );
      setData(prevState => (prevState || []).filter(item => !(item.deployedFrom && item.deployedFrom === newDataToAdd[0].deployedFrom)));
    }
    const tmpTest = tmp.map((item, index) =>
      (index === newDataToAdd[0].deployedFrom ?
        { ...item, deployed: newDataToAdd[0].deployAction }
        : (item.deployedFrom === newDataToAdd[0].deployedFrom && !newDataToAdd[0].deployAction ? null : item))
    ).filter(item => item !== null);

    setGraphData( newDataToAdd.length > 0 ? tmpTest : tmp);
    setAddedLinks(tmpAddedLinks);
    setGraphLink(tmpAddedLinks);
    /// UNDEPLOYED HANDLING ////

    makeChart( { data: tmpData, tmpAddedLinks, tmpGraphData: tmp } );
  }, [newDataToAdd]);

  useEffect(()=> {
    setOption((prevState: EChartsOption) => {
      const modifiedEdges = prevState.series[0].edges.map((edge: { label: { normal: {
        show: boolean,
        formatter: string,
        position: string
      }}}) => {
        if (edge.label && edge.label.normal) {
          return {
            ...edge,
            label: {
              ...edge.label,
              normal: {
                ...edge.label.normal,
                fontSize: switchLabelLink ? 14 : 0
              }
            }
          };
        }

        return edge;
      });

      return {
        ...prevState,
        series: [
          {
            ...prevState.series[0],
            edges: modifiedEdges
          }
        ]
      };
    });
  }, [switchLabelLink]);

  return (
    <Box height='700px' width='100%' position='relative'>
      <FormGroup style={{ width: 'fit-content', position: 'absolute', top: isFullscreen ? 60 : undefined, left: 6, zIndex: 10 }}>
        <FormControlLabel
          label='Show Link Label'
          control={
            <Switch
              defaultChecked
              size='small'
              value={switchLabelLink}
              onChange={() => setSwitchLabelLink(!switchLabelLink)}
            />
          }
        />
      </FormGroup>
      <ReactECharts
        option={option}
        onEvents={onEvents}
        style={{ height: '100%', width: '100%' }}
      />
      { entityOnHover &&
        <Box p={1000} style={{ position: 'absolute', left: mousePos.x - 240, top: mousePos.y - ( entityOnHover.commonRelationsWithMain ? 340 : 240) }}>
          <EntitiesCards entityRef={entityOnHover} commonRelationsWithMain={`${entityOnHover.commonRelationsWithMain} (${(((entityOnHover.commonRelationsWithMain || 0) / (baseDataLength - 1)) * 100).toFixed(2) }%)`} onMouseEnter={refreshTimeout} onMouseLeave={handleChartMouseLeave} />
        </Box>
      }
      { linkOnHover.entity1 && linkOnHover.entity2 && linkOnHover.links && !entityOnHover &&
        <Box style={{ position: 'absolute', left: mousePos.x - 550, top: mousePos.y - 80 }}>
          <RelationComponent data={linkOnHover} onMouseEnter={refreshTimeout} onMouseLeave={handleChartMouseLeave} />
        </Box>
      }
    </Box>
  );
}

export default RelationsChart;
