import React, { ReactElement } from 'react';
import Keycloak from 'keycloak-js';
import ParserModules from '../wrappers';
import DeecisionRouter from '../router';
import axiosInstance from '../api';
import { API_BASE_URL, PLATFORM, WEBAPP_NAME } from '@/env/env';
import dnaRoutes from './routes';
import dnaConfig from '../config/dna.config.json';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tabContained: true,
    tabText: true,
    containedIcon: true,
    outlinedIcon: true,
    textIcon: true
  }
}

declare module '@mui/material/styles' {
  // fix the type error when referencing the Theme object in your styled component
  interface Theme {
    primaryColors?: {
      lighter?: string,
      light?: string,
      lightest?: string,
      main?: string,
      darkest?: string,
      dark?: string,
      darker?: string
    }
  }
  // fix the type error when calling `createTheme()` with a custom theme option
  interface ThemeOptions {
    primaryColors?: {
      lighter?: string,
      light?: string,
      lightest?: string,
      main?: string,
      darkest?: string,
      dark?: string,
      darker?: string
    }
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    hoverElevation1: true,
    hoverElevation2: true,
    hoverElevation3: true,
    hoverElevation4: true
  }
}

const availableApps = [
  'dna',
  'deeligenz'
];

const iconList: string[] = [
  // 'notifications'
];

function Dna(): ReactElement {

  return (
    <ParserModules
      keycloak={{ keycloakInstance: new Keycloak('/dna/keycloak.json'), axiosInstance }}
      axios={{ baseApiUrl: API_BASE_URL, apiUrlSuffix: dnaConfig.api.url.suffix, axiosInstance }}
      mui
      dnd
      error={{ axiosInstance }}
      i18n={{ app: WEBAPP_NAME, axiosInstance }}
      maintenance={{ app: WEBAPP_NAME, axiosInstance }}
    >
      <DeecisionRouter
        app={WEBAPP_NAME}
        availableApps={availableApps}
        axiosInstance={axiosInstance}
        env={PLATFORM}
        iconList={iconList}
        routeObjects={dnaRoutes} // all the routes to build the app
      />
    </ParserModules>
  );
}

export default Dna;
