import { TFunction } from 'i18next';
import { APE_CODE_MAPPING, RNCS_FORME_JURIDIQUE_MAPPING, SegmentationFilterRange } from '@deecision/dna-interfaces';
import { currencyFormatter } from '@/utils/currencyFormatter';
import { getFlagEmoji } from '@/utils/getFlagEmoji';

export type ValueType = 'string' | 'number' | 'boolean' | 'countryCode' | 'amount' | 'countryCode[]' | 'color' | 'veegilenzColor' | 'positionTypes';

export type ValuePossibleTypes = string | string[] | number | number[] | boolean | boolean[] | undefined | null | undefined[] | null[]; // | { value: string | number | boolean, range?: { min: number, max: number } } | { value: string | number | boolean, range?: { min: number, max: number } }[];

interface ValueParserOptions {
  value?: ValuePossibleTypes,
  range?: SegmentationFilterRange,
  valueType?: ValueType,
  currency?: string | null,
  t?: TFunction<'translation', undefined>
}

function getIfApeCode(value: string) {
  const regexCodeAPE = /^[0-9][0-9].[0-9][0-9][A-Z]$/;

  if (regexCodeAPE.test(value)) {
    return `${APE_CODE_MAPPING[value as keyof typeof APE_CODE_MAPPING]} (${value})`;
  }

  return value;
}

const getIfRncsCode = (value: string) => {
  if (value.toLowerCase().includes('rncs:')) {
    return `${RNCS_FORME_JURIDIQUE_MAPPING[value.split(':')[1] as keyof typeof RNCS_FORME_JURIDIQUE_MAPPING]} (${value})`;
  }

  return value;
};

function format(value: ValuePossibleTypes, valueType?: ValueType, currency?: string | null, t?: TFunction<'translation', undefined>) {
  if (value === undefined || value === null || value?.toString() === t?.('common.utils.notFound')) return t?.('common.utils.notFound') || '';


  switch (valueType) {
  case undefined:
  case 'string':
  case 'number':
  case 'color':
  case 'veegilenzColor':
    return Array.isArray(value) ? value.map(v => (v ? getIfApeCode(getIfRncsCode(v.toString().replace('undefined', t?.('common.utils.notFound') || ''))) : v?.toString() || t?.('common.utils.notFound') || '')).join(', ') : typeof value === 'string' ? getIfApeCode(getIfRncsCode(value.replace('undefined', t?.('common.utils.notFound') || ''))) : value.toString();
  case 'boolean':
    return t ? t(value ? 'common.utils.yes' : 'common.utils.no') : value.toString();
  case 'amount':
    return Array.isArray(value) ? value.map(v => (currencyFormatter({ value: parseFloat(v?.toString() || '0'), currency }))).join(',') : currencyFormatter({ value: parseFloat(value?.toString() || '0'), currency }) || '0';
  case 'countryCode':
  case 'countryCode[]':
    return Array.isArray(value) ? value.map(v => getFlagEmoji(v?.toString())).join('') : getFlagEmoji(value.toString());
  case 'positionTypes':
    return Array.isArray(value) ? value.map(v => (v ? t?.(`common.positionTypes.${v?.toString()}`) || v?.toString() : t?.('common.utils.notFound') || '')).join(', ') : value ? t?.(`common.positionTypes.${value?.toString()}`) || value?.toString() : t?.('common.utils.notFound') || '';
  default:
    return value?.toString();
  }
}

function valueParser({ value, range, valueType, currency, t }: ValueParserOptions) {
  if (value !== undefined && value !== null) {
    return format(value, valueType, currency, t);
  }

  if (range) {
    if (range.min === undefined || range.min === null) {
      return `< ${format(range.max, valueType, currency, t)}`;
    }

    if (range.max === undefined || range.max === null) {
      return `${format(range.min, valueType, currency, t)} <`;
    }

    return `${format(range.min, valueType, currency, t)} - ${format(range.max, valueType, currency, t)}`;
  }

  return t?.('common.utils.notFound') || '';
}

export default valueParser;
