import React, { ReactElement } from 'react';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import { IconFilePlus, IconFolderPlus, IconPlus } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useLoaderData } from 'react-router-dom';
import { IDataBlock, VeegilenzPersonSummaryV1DataBlockContent } from '@deecision/dna-interfaces';
import DirectIndexVeegilenz from './directindex';
import CorporateNetworkVeegilenz from './corporatenetwork';
import IndividualNetworkVeegilenz from './individualnetwork';
import VeegilenzHeader from './header';
import { VeegilenzProps } from './types';
import VeegilenzCountriesLists from '@/main/containers/veegilenz/countrieslists';
import { API_BASE_URL } from '@/env/env';

export const veegilenzPersonPaths = ['person.veegilenz.facts', 'person.veegilenz.summary'];
export const veegilenzCompanyPaths = ['company.veegilenz.facts', 'company.veegilenz.summary'];

export interface VeegilenzScores {
  directIndex?: number,
  directIndexReason?: string,
  individualNetwork?: number,
  individualNetworkReason?: string,
  corporateNetwork?: number,
  corporateNetworkReason?: string
}

function Veegilenz(props: VeegilenzProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const data = useLoaderData() as { data: IDataBlock<VeegilenzPersonSummaryV1DataBlockContent>[] };
  const entityId = data.data[0]?.entityRef.entityId;

  const scoreColor = [
    theme.palette.success,
    theme.palette.warning,
    theme.palette.error,
    {
      light: theme.palette.grey['800'],
      main: theme.palette.grey['800'],
      dark: theme.palette.grey['200'],
      contrastText: theme.palette.grey['300']
    }
  ];

  return (
    <Grid container spacing={4}>
      <Grid size={12}>
        <VeegilenzHeader />
      </Grid>
      <Grid container spacing={4}>
        <Grid size='grow' container spacing={4} alignContent='flex-start'>
          <Grid size={12}>
            <Stack direction='row' spacing={2} justifyContent='flex-start' width='100%'>
              <Button
                variant='contained'
                startIcon={<IconPlus />}
              >
                {t('veegilenz.addCriteria')}
              </Button>
              <Button
                variant='outlined'
                href={`${API_BASE_URL}/dna-server/veegilenz/reports/deecPerson/${entityId}`}
                startIcon={<IconFilePlus />}
                download
              >
                {t('report.createComplianceReport')}
              </Button>
              <Button
                variant='outlined'
                startIcon={<IconFolderPlus />}
              >
                {t('folder.createComplianceFolder')}
              </Button>
            </Stack>
          </Grid>
          <Grid>
            <DirectIndexVeegilenz { ...props } />
          </Grid>
          <Grid>
            <CorporateNetworkVeegilenz
              score={0}
              scoreColor={scoreColor}
              disabled
            />
          </Grid>
          <Grid>
            <IndividualNetworkVeegilenz
              score={0}
              scoreColor={scoreColor}
              disabled
            />
          </Grid>
        </Grid>
        <Grid>
          <VeegilenzCountriesLists />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Veegilenz;
