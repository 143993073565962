import { FinancialReportDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { IconTable } from '@tabler/icons-react';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import { currencyFormatter } from '@/utils';
import Indicator from '@/components/indicator';
import FinancialActions from '@/main/containers/financial/actions';
import { getFinancialYears } from '@/main/containers/financial/utils';

function FinancialGrid(props: { datas: IDataBlock<FinancialReportDataBlock>[], selectedKey: string[], setSelectedKey: (keyPath: string[]) => void, pathList: Record<string, string[]> }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const years = useMemo(() => getFinancialYears(props.datas), [props.datas]);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    let listTmp: string[] = [];

    Object.keys(props.pathList).forEach((key) => {
      listTmp = [ ...listTmp, ...props.pathList[key] ];
    });

    setSelected(listTmp);
  }, [props.pathList]);

  return (
    <Box sx={{ width: '100%', pl: 0, pr: 0, pt: 1, border: `solid 1px ${theme.palette.grey['500']}`, borderRadius: `${theme.shape.borderRadius}px` }}>
      <Stack spacing={4}>
        <Stack direction='row' spacing={2} width='100%' alignItems='center' pl={2}>
          <IconTable size={20} />
          <Typography variant='h3'>
            {t('common.financial.financialGrid')}
          </Typography>
          <Box ml='auto !important' p={2} pt={1} pb={0}>
            <FinancialActions onlyFolder />
          </Box>
        </Stack>
        {Object.keys(props.pathList).map(key => (
          <TableContainer key={key}>
            <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
              <TableHead>
                <TableRow>
                  <TableCell width='40px' sx={{ pl: 1, pr: 1 }}>
                    <Checkbox
                      checked={props.pathList[key].every(subKey => selected.includes(subKey))}
                      indeterminate={!props.pathList[key].every(subKey => selected.includes(subKey)) && props.pathList[key].some(subKey => selected.includes(subKey))}
                      onChange={() => {
                        if (props.pathList[key].every(subKey => selected.includes(subKey))) {
                          setSelected(prevState => prevState.filter(subKey => !props.pathList[key].includes(subKey)));
                        } else if (props.pathList[key].some(subKey => selected.includes(subKey))) {
                          setSelected(prevState => [...prevState, ...props.pathList[key].filter(subKey => !prevState.includes(subKey))]);
                        } else {
                          setSelected(prevState => [...prevState, ...props.pathList[key]]);
                        }
                      }}
                      size='small'
                    />
                  </TableCell>
                  <TableCell width='340px' sx={{ pl: 1 }}>
                    <Typography variant='h5'>
                      {`${key.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() || ''}${key.replace(/([A-Z])/g, ' $1').slice(1) || ''}`}
                    </Typography>
                  </TableCell>
                  {years.map(year => (
                    <TableCell key={`columnLabel-${year}`} align='right' width='160px'>
                      <Tooltip title={props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.balanceSheetType} arrow placement='right'>
                        <Typography variant='h5'>
                          {year}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.pathList[key].map(subKey => (
                  <TableRow
                    key={`row-${subKey}`}
                    sx={{
                      bgcolor: props.selectedKey.includes(subKey) ? theme.palette.primary.light : undefined,
                      '&:last-child td, &:last-child th': {
                        border: 0,
                        borderRadius: 0
                      },
                      '&:hover': {
                        cursor: 'pointer',
                        bgcolor: props.selectedKey.includes(subKey) && theme.primaryColors?.light || theme.palette.grey['200']
                      }
                    }}
                  >
                    <TableCell sx={{ pl: 1, pr: 1 }}>
                      <Checkbox
                        key={`${subKey}-${selected.includes(subKey)}`}
                        checked={selected.includes(subKey)}
                        onChange={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelected(prevState => (prevState.includes(subKey) ? prevState.filter(f => f !== subKey) : [...prevState, subKey]));
                        }}
                        size='small'
                      />
                    </TableCell>
                    <TableCell
                      scope='row'
                      sx={{ pl: 1 }}
                      onClick={() => props.setSelectedKey(props.selectedKey.find(k => k === subKey) ? props.selectedKey.filter(k => k !== subKey) : [...props.selectedKey, subKey])}
                    >
                      <Typography
                        variant='body1'
                        fontWeight={400}
                        color={theme.palette.text.secondary}
                      >
                        {`${subKey.split('.')[1]?.replace(/([A-Z])/g, ' $1').charAt(0).toUpperCase() || ''}${subKey.split('.')[1]?.replace(/([A-Z])/g, ' $1').slice(1) || ''}`}
                      </Typography>
                    </TableCell>
                    {years.map(year => (
                      <TableCell
                        key={`cell-${subKey}-${year}`}
                        align='right'
                        onClick={() => props.setSelectedKey(props.selectedKey.find(k => k === subKey) ? props.selectedKey.filter(k => k !== subKey) : [...props.selectedKey, subKey])}
                      >
                        <Stack direction='row' spacing={2} alignItems='center' justifyContent='flex-end'>
                          <Typography
                            variant='body1'
                          >
                            {
                              currencyFormatter({
                                value: get(props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey),
                                currency: props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.currencyCode
                              })
                            }
                          </Typography>
                          <Indicator
                            indicator={
                              get(props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) ?
                                parseFloat(get(props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) || '0') - parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) || '0')
                                : t('common.utils.unknown') || ''
                            }
                            percentage={
                              get(props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) && get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) ?
                                (100 / parseFloat(get(props.datas.find(d => (parseInt(year, 10) - 1).toString() === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) || '0') * parseFloat(get(props.datas.find(d => year === new Date(d.data.closingDate).getFullYear().toString())?.data.details, subKey) || '0') - 100)
                                : undefined
                            }
                          />
                        </Stack>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </Stack>
    </Box>
  );
}

export default FinancialGrid;
