import React, { ReactElement, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { IconArrowsSort, IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

export interface SimpleSort {
  id: string,
  label: string,
  direction?: 'asc' | 'dsc'
}

function SimpleDisplaySort(props: { sortList: SimpleSort[], setSortList: (sortList: SimpleSort[]) => void, sortLabel?: string, defaultDirection?: 'asc' | 'dsc' }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.sortList.length === 1) {
      props.setSortList([{ ...props.sortList[0], direction: props.sortList[0].direction === 'asc' ? 'dsc' : 'asc' }]);
    } else if (props.sortList.some(sort => !sort.direction)) {
      setAnchorEl(event.currentTarget);
    } else {
      props.setSortList(props.sortList.map(sort => ({ ...sort, direction: undefined })));
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (sort: SimpleSort) => {
    handleClose();
    props.setSortList(props.sortList.map(s => ({ ...s, direction: s.id === sort.id ? props.defaultDirection || 'asc' : undefined })));
  };

  const handleChangeDirection = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    props.setSortList(props.sortList.map(s => ({ ...s, direction: s.direction ? s.direction === 'asc' ? 'dsc' : 'asc' : undefined })));
  };

  useEffect(() => {
    if (props.sortList.length === 1 && !props.sortList[0].direction) {
      props.setSortList([{ ...props.sortList[0], direction: 'asc' }]);
    }
  }, [props.sortList]);

  return (
    <Stack direction='row' alignItems='center' spacing={2}>
      <IconArrowsSort />
      <Button
        variant='outlined'
        size='small'
        onClick={handleClick}
        sx={{
          border: 1,
          borderColor: '#C0C0C1',
          backgroundColor: theme.palette.grey['50'],
          '&:hover': {
            backgroundColor: theme.palette.grey['200']
          }
        }}
      >
        {`${props.sortLabel || t('filters.sortBy')}${props.sortList.find(sort => sort.direction) ? ':' : ''} ${props.sortList.find(sort => sort.direction)?.label || ''}`}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ zIndex: 10000 }} // If used with a FullscreenProvider needs to set a zIndex > to the fullscreen zIndex, otherwise the Menu will be hidden under
      >
        {props.sortList.filter(sort => !sort.direction).map(sort => (
          <MenuItem
            key={sort.id}
            onClick={() => handleSort(sort)}
            sx={{
              minWidth: '80px'
            }}
          >
            {sort.label}
          </MenuItem>
        ))}
      </Menu>
      <IconButton
        size='small'
        onClick={handleChangeDirection}
        sx={{
          border: 1,
          borderColor: '#C0C0C1',
          backgroundColor: theme.palette.grey['50'],
          '&:hover': {
            backgroundColor: theme.palette.grey['200']
          }
        }}
      >
        {props.sortList.find(sort => sort.direction) ?
          props.sortList.find(sort => sort.direction)?.direction === 'asc' ?
            <IconSortAscending /> : <IconSortDescending /> : <IconArrowsSort /> }
      </IconButton>
    </Stack>
  );
}

export default SimpleDisplaySort;
