import React from 'react';
import {  Paper, Stack, Typography, useTheme } from '@mui/material';
import { Handle, Position, useStore } from 'reactflow';
import { IconBuilding, IconUser } from '@tabler/icons-react';

export function MainEntity(data: { data: {label: string, entityType: string} }) {
  const theme = useTheme();
  const zoomLevel = useStore(state => state.transform[2]);
  // const personGetter = useMemo(() => new PersonGetter({
  //   data: data.person as unknown as PersonEntity,
  //   errorMsg: t('common.utils.unknown')
  // }), [data.person]);c

  return (
    <Paper variant='hoverElevation2' sx={{ boxShadow: 1, width: '500px', height: '108px', backgroundColor: theme.palette.primary.main,  borderRadius: '12px', borderColor: theme.palette.grey[400], p: 1, pl: 4, pr: 4 }}>
      <Stack spacing={1} height='100%' justifyContent='space-between' pb={1}>
        <Handle id='top' type='source' position={Position.Top} isConnectable style={{ visibility: 'hidden' }} />
        <Handle id='left' type='target' position={Position.Left} isConnectable style={{ visibility: 'hidden' }} />
        <Handle id='right' type='source' position={Position.Right} isConnectable style={{ visibility: 'hidden' }} />
        <Handle id='bottom' type='source' position={Position.Bottom} isConnectable style={{ visibility: 'hidden', marginRight: '80px' }} />

        <Stack height={24} pt={4} spacing={1} direction='row' alignItems='center' width='calc(100% + 16px)' sx={{ ml: '-8px !important' }}>
          {data.data.entityType.includes('Person') ?
            <IconUser size={zoomLevel < 0.5 ? 40 : 30 } color='white' /> :
            <IconBuilding size={zoomLevel < 0.5 ? 40 : 30 } color='white' />
          }
        </Stack>
        <Typography color='white' variant='body1' align='center' fontSize={zoomLevel < 0.5 ? 38 : 22} pl={2} mr={2} pb={6} fontWeight={500} noWrap>
          {data.data.label}
        </Typography>
      </Stack>
    </Paper>
  );
}
