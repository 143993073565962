import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconArrowRight, IconBuildingFactory, IconCirclesRelation, IconDashboard, IconFolders, IconLayoutList, IconListDetails, IconUsers, IconUserScan, IconWallet } from '@tabler/icons-react';
import { darken, lighten, useTheme } from '@mui/material/styles';
import { Paper, Stack, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { PersonEntity, CompanyEntity } from '@deecision/dna-interfaces';
import IconBuildings from '@/assets/custom/IconBuildings';
import SearchEngine from '../modules/entities/search/components/engine';
import BaseDnaEntitiesService from '../modules/entities/services/entities';
import PortfoliosServices from '../modules/deetect/portfolios/services';

type CategorieCardProps = {
  title: string,
  icon: JSX.Element,
  bgcolor: string,
  redirectLink: string,
  totalEntities? : {
    persons: number,
    companies: number,
    portfolios: number,
    folders: number
  },
  width?: string | undefined,
  height?: string | undefined
}

function SubCard({ title, icon, bgcolor, redirectLink }: CategorieCardProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isPersonsOrCompanies = redirectLink.includes('relations');

  return (
    <Paper
      elevation={0}
      variant='hoverElevation2'
      onClick={(event) => {
        if (isPersonsOrCompanies) {
          event.stopPropagation();
          navigate({ pathname: 'relations', search: `searchEntityType=${  redirectLink === 'network/persons' ? 'deecPerson' : 'deecCompany'}` });
        } else {
          navigate(redirectLink.toLowerCase());
        }
      }}
      sx={{
        width: '100%',
        border: 0.7,
        p: 2,
        bgcolor,
        overflowX: 'auto',
        borderRadius: 1,
        cursor: 'pointer'
      }}>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid minWidth='fit-content' minHeight='fit-content'>
          <Box
            width='fit-content'
            borderRadius={1}
            borderColor={darken(bgcolor, 0.3)}
            bgcolor={darken(bgcolor, 0.1)}
            p={2}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              aspectRatio: '1 / 1'
            }}>
            {icon}
          </Box>
          <Typography fontWeight={500} fontSize={isPersonsOrCompanies ? 13 : 14}>{title}</Typography>
        </Grid>
      </Grid>
      <Grid flexDirection='row' justifyContent='end'>
        <Button
          size='small'
          variant='outlinedIcon'
          sx={{
            alignSelf: 'flex-end',
            marginLeft: 'auto !important',
            border: 'solid 1px',
            '&:hover': {
              color: theme.palette.grey['800'],
              border: 'solid 1px transparent',
              bgcolor: darken(bgcolor, 0.1)
            }
          }}
          onClick={(event) => {
            if (isPersonsOrCompanies) {
              event.stopPropagation();
              navigate({ pathname: 'relations', search: `searchEntityType=${  redirectLink === 'network/persons' ? 'deecPerson' : 'deecCompany'}` });
            } else {
              navigate(redirectLink.toLowerCase());
            }
          }}
        >
          <IconArrowRight size={20} />
        </Button>
      </Grid>
    </Paper>
  );
}

function CategorieCard({ title, icon, bgcolor, redirectLink, totalEntities }: CategorieCardProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isPersonsOrCompanies = redirectLink === 'persons' || redirectLink === 'companies';

  return (
    <Paper
      elevation={0}
      variant='hoverElevation2'
      onClick={() => {
        navigate(redirectLink.toLowerCase());
      }}
      sx={{
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        border: 0.7,
        p: 2,
        bgcolor,
        overflowX: 'auto',
        borderRadius: 1,
        cursor: 'pointer'
      }}>
      <Grid container>
        <Grid minWidth='fit-content'>
          <Box
            borderRadius={1}
            borderColor={darken(bgcolor, 0.3)}
            bgcolor={darken(bgcolor, 0.1)}
            p={2}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{
              aspectRatio: '1 / 1'
            }}>
            {icon}
          </Box>
        </Grid>
        <Grid size='grow' />
        <Grid minWidth='fit-content'>
          {totalEntities &&
            <Typography
              p={1}
              fontWeight={600}
              fontSize={20}
            >
              {totalEntities[redirectLink as keyof typeof totalEntities] || '0'}
            </Typography>
          }
        </Grid>
        <Grid size={12} minWidth='fit-content'>
          <Typography fontWeight={500} fontSize={16}>{title}</Typography>
        </Grid>
        <Grid size={12} height={12} />
        {isPersonsOrCompanies ?
          <Grid
            size={6}
            onClick={(event: { stopPropagation: () => void }) => {
              event.stopPropagation();
              navigate({ pathname: 'relations', search: `searchEntityType=${ redirectLink === 'persons' ? 'deecPerson' : 'deecCompany'}` });
            }}
          >
            <SubCard
              title='Relations'
              icon={<IconCirclesRelation size={15} color='black'/>}
              bgcolor={lighten(bgcolor, 0.3)}
              redirectLink={`relations/${redirectLink}`}
            />
          </Grid> :
          <Grid size={6} />
        }
        <Grid size='grow' alignContent='end'>
          <Button
            size='large'
            variant='outlinedIcon'
            sx={{
              marginLeft: 'auto !important',
              border: 'solid 1px',
              '&:hover': {
                color: theme.palette.grey['800'],
                border: 'solid 1px transparent',
                bgcolor: darken(bgcolor, 0.1)
              }
            }}
            onClick={() => {
              navigate(redirectLink.toLowerCase());
            } }
          >
            <IconArrowRight size={23} />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

function HomePageDnaComponent(): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const personService = new BaseDnaEntitiesService<PersonEntity>({ entityType: 'person' });
  const companyService = new BaseDnaEntitiesService<CompanyEntity>({ entityType: 'company' });
  const portfoliosService = new PortfoliosServices();
  const sectionLeftWidth = 70;

  const [totalEntities, setTotalEntities] = useState({
    persons: 0,
    companies: 0,
    portfolios: 0,
    folders: 0
  });

  const entitiesCategorie = [
    {
      title: t('entities.persons.label'),
      icon: <IconUsers color={theme.palette.grey['800']}/>,
      bgColor: lighten(theme.palette.primary.main, 0.6),
      redirectTo: 'persons',
      width: '50%'
    },
    {
      title: t('entities.companies.label'),
      icon: <IconBuildings color={theme.palette.grey['800']}/>,
      bgColor: lighten(theme.palette.secondary.main, 0.6),
      redirectTo: 'companies',
      width: '50%'
    }
  ];

  const deetectCategorie = [
    {
      title: t('portfolios.label'),
      icon: <IconWallet color={theme.palette.grey['800']}/>,
      bgColor: lighten(theme.palette.primary.main, 0.9),
      redirectTo: 'portfolios',
      width: '50%'
    },
    {
      title: t('folder.label'),
      icon: <IconFolders color={theme.palette.grey['800']}/>,
      bgColor: lighten(theme.palette.primary.main, 0.9),
      redirectTo: 'folders',
      width: '50%'
    }
  ];

  const adminCategorie = [
    {
      title: 'Admin',
      pt: 0,
      card: [{
        title: 'Factory',
        icon: <IconBuildingFactory color={theme.palette.grey['800']}/>,
        bgColor: theme.palette.error.light,
        redirectTo: 'admin/factory'
      },
      {
        title: 'Dashboard',
        icon: <IconDashboard color={theme.palette.grey['800']}/>,
        bgColor: theme.palette.error.light,
        redirectTo: 'admin/dashboard'
      },
      {
        title: 'DataElements',
        icon: <IconListDetails color={theme.palette.grey['800']}/>,
        bgColor: theme.palette.error.light,
        redirectTo: 'admin/dataelements'
      },
      {
        title: t('entities.label'),
        icon: <IconUserScan color={theme.palette.grey['800']}/>,
        bgColor: theme.palette.error.light,
        redirectTo: 'admin/entities'
      },
      {
        title: 'DataBlocks',
        icon: <IconLayoutList color={theme.palette.grey['800']}/>,
        bgColor: theme.palette.error.light,
        redirectTo: 'admin/datablocks'
      }]
    }
  ];

  useEffect(() => {
    personService.getAll({}).then(
      (res: {totalCount: number}) => {
        setTotalEntities(prevState => ({
          ...prevState,
          persons: res.totalCount
        }));
      }
    ).catch((err: string) => {
      console.error(err);
    });
    companyService.getAll({}).then(
      (res: {totalCount: number}) => {
        setTotalEntities(prevState => ({
          ...prevState,
          companies: res.totalCount
        }));
      }
    ).catch((err: string) => {
      console.error(err);
    });
    portfoliosService.getAll({}).then(
      (res: {totalCount: number}) => {
        setTotalEntities(prevState => ({
          ...prevState,
          portfolios: res.totalCount
        }));
      }
    ).catch((err: string) => {
      console.error(err);
    });
  }, []);

  return (
    <Box width='100%' height='100%' overflow='auto'>
      <Grid container flexDirection='row' overflow='auto'>
        <Stack width={`${sectionLeftWidth}%`} pr={4}>
          <Stack height='15%' pt={2} mt='4vh' mb='5vh' overflow='auto'>
            <SearchEngine noActions onlySearchBar/>
          </Stack>
          <Grid container width='100%' justifyContent='center' pb={4}>
            {entitiesCategorie.map(entitie =>
              <Grid
                p={2}
                pt={0}
                pb={2}
                minWidth={entitie.width || 138}
              >
                <CategorieCard
                  title={entitie.title}
                  icon={entitie.icon}
                  bgcolor={entitie.bgColor}
                  redirectLink={entitie.redirectTo}
                  totalEntities={totalEntities}
                />
              </Grid>
            )}
          </Grid>
          <Grid container width='100%' justifyContent='center' pb={4}>
            {deetectCategorie.map(entitie =>
              <Grid
                p={2}
                pt={0}
                pb={2}
                minWidth={entitie.width || 138}
              >
                <CategorieCard
                  title={entitie.title}
                  icon={entitie.icon}
                  bgcolor={entitie.bgColor}
                  redirectLink={entitie.redirectTo}
                  totalEntities={totalEntities}
                />
              </Grid>
            )}
          </Grid>
          {adminCategorie.map(category =>
            <Stack borderRadius={1} p={4} pt={category.pt} spacing={2}>
              <Typography fontSize={20} >
                {category.title}
              </Typography>
              <Grid container height='85%' spacing={2}>
                {category.card.map(card =>
                  <Grid minWidth={123} >
                    <SubCard title={card.title} icon={card.icon} bgcolor={card.bgColor} redirectLink={card.redirectTo}/>
                  </Grid>
                )}
              </Grid>
            </Stack>
          )}
        </Stack>

        <Grid container height='80vh' width='30%' bgcolor='#E9ECEF' borderRadius={2} />

      </Grid>
    </Box>
  );
}

export default HomePageDnaComponent;
