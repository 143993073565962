import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { IconDownload, IconExternalLink, IconFilePlus } from '@tabler/icons-react';

function FinancialActions(props: { onlyFolder?: true }): ReactElement {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} direction='row' alignItems='center'>
      {!props.onlyFolder &&
        <>
          <Tooltip title={t('common.utils.openLink')} arrow placement='top'>
            <Button
              variant='icon'
              size='small'
              sx={{
                mr: '-4px',
                transform: 'scale(0.8)'
              }}
            >
              <IconExternalLink size={22} />
            </Button>
          </Tooltip>
          <Tooltip title={t('common.utils.downloadFile')} arrow placement='top'>
            <Button
              variant='icon'
              size='small'
              sx={{
                transform: 'scale(0.8)'
              }}
            >
              <IconDownload size={22} />
            </Button>
          </Tooltip>
        </>
      }
      <Button
        variant='contained'
        sx={{
          ml: '8px !important'
        }}
        startIcon={
          <IconFilePlus size={16} />
        }
      >
        {t('folder.addSelectedElementsToMyFolder')}
      </Button>
    </Stack>
  );
}

export default FinancialActions;
