import React from 'react';
import { IconLayoutList } from '@tabler/icons-react';
import createElementRoutes from '@/router/create';
import Construction from '@/components/construction';
import DataBlocksScreen from './dataBlocksList';
import DataBlocksDetailsHeader from './dataBlocksDetailHeader';
import JsonDataBlocksBrutTabs from './tabs/dataBlocks.tabs.jsonBrut';
import dnaConfig from '../../../../config/dna.config.json';
import { CustomRouteObject } from '@/router/types';

const routeDataBlocks: CustomRouteObject = createElementRoutes({
  id: 'DataBlocks',
  path: 'datablocks',
  icon: IconLayoutList,
  component: <DataBlocksScreen />,
  idComponent: <DataBlocksDetailsHeader />,
  tabs: [{
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.summary.label,
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.summary.baseUrl,
    element: <Construction />
  },
  {
    id: dnaConfig.routes.admin.tabs.dataElements.tabs.json.label,
    path: dnaConfig.routes.admin.tabs.dataElements.tabs.json.baseUrl,
    element: <JsonDataBlocksBrutTabs />
  }],
  displayIndex: true
});

export default routeDataBlocks;
