import React from 'react';
import { IconHome } from '@tabler/icons-react';
import entitiesRoutes from './modules/entities/routes';
import deetectRoutes from './modules/deetect/routes';
import { adminRoutes } from './modules/admin';
import HomePageDnaComponent from './containers/home';
import { CustomRouteObject } from '@/router/types';

const dnaRoutes: CustomRouteObject[][] = [
  [
    {
      index: true,
      i18nKey: 'common.utils.home',
      element: <HomePageDnaComponent />,
      handle: {
        icon: IconHome
      }
    }
  ],
  entitiesRoutes,
  deetectRoutes,
  adminRoutes
];

export default dnaRoutes;
