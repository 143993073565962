import React, { ReactElement, ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { IconArrowRight, IconCirclesRelation, IconUsers } from '@tabler/icons-react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import IconBuildings from '@/assets/custom/IconBuildings';
import { BasePortfolioDashboardProps, dashboardSpacing } from './types';

function ContainerGroupQuickDashboard(props: BasePortfolioDashboardProps & { titleAddon?: ReactNode, customIcon?: ReactNode, children?: ReactNode }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const iconMapper = (key: string) => {
    if (props.customIcon) return props.customIcon;

    switch (key) {
    case 'persons.companies':
      return <IconBuildings />;
    case 'persons.network':
      return <IconCirclesRelation />;
    case 'persons':
      return <IconUsers />;
    case 'companies':
      return <IconBuildings />;
    default:
      return <IconUsers />;
    }
  };

  return (
    <Paper
      variant='hoverElevation2'
      sx={{ minWidth: 'min-content', width: '100%', bgcolor: theme.palette.grey['200'], p: dashboardSpacing }}
    >
      <Grid container spacing={dashboardSpacing} height='fit-content'>
        <Grid
          size={12}
          sx={{
            cursor: props.group.link ? 'pointer' : 'default'
          }}
          onClick={props.group.link ? () => navigate({ pathname: props.group.link }) : undefined}
        >
          <Stack direction='row' spacing={dashboardSpacing} alignItems='center' width='100%'>
            <Box height={24}>
              {props.group.icon ? <props.group.icon /> : iconMapper(props.group.subGroupId.split('.').at(0) || '')}
            </Box>
            {props.group.img ?
              <img src={props.group.img} alt={props.group.subGroupId} height={24} width={124} /> :
              <Typography variant='h4' whiteSpace='nowrap' pr={2}>
                {(props.group.subGroupId.split('.').at(-1) && ['sameFamily', 'personal', 'business', 'extended', 'client', 'notClient'].includes(props.group.subGroupId.split('.').at(-1) || 'unknown')) ? t(`portfolios.dashboard.groups.labels.${props.group.subGroupId.split('.').at(-1)}.label`) : t(`portfolios.dashboard.groups.labels.${props.group.subGroupId}.label`)}
              </Typography>
            }
            {props.titleAddon}
            {props.group.link &&
              <IconButton href={props.group.link} sx={{ ml: 'auto !important' }}>
                <IconArrowRight size={24} />
              </IconButton>
            }
          </Stack>
        </Grid>
        {props.group.description &&
          <Grid size={12}>
            <Typography variant='body2'>{t(`portfolios.dashboard.groups.labels.${props.group.subGroupId}.description`)}</Typography>
          </Grid>
        }
        {props.children}
      </Grid>
    </Paper>
  );
}

export default ContainerGroupQuickDashboard;
