import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
  IconPoint
} from '@tabler/icons-react';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useSidebarContext } from './hooks/sidebar';
import { CustomRouteObject, HandleType } from '@/router/types';

export interface SidebarProps {
  routeObjects: CustomRouteObject[][] | CustomRouteObject[],
  bottomComponent?: {
    open: ReactElement,
    close?: ReactElement
  }
}

export interface SidebarMenuProps {
  routeObject: CustomRouteObject
}

export interface SidebarSubMenuProps extends Partial<SidebarProps>, SidebarMenuProps {
  chevronOpen: boolean
}

function SidebarSubMenuButton(props: SidebarMenuProps & { routeObjectChild: CustomRouteObject, open?: true }): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();
  const CustomMenuIcon = (props.routeObjectChild.handle as HandleType)?.icon;

  return (
    <Button
      href={`${props.routeObject.path}/${props.routeObjectChild.path}`}
      sx={{ height: '40px', pl: props.open ? 7 : undefined }}
      variant={
        props.routeObjectChild.path &&
        location.pathname.includes(`${props.routeObject.path}/${props.routeObjectChild.path}`) ?
          'menuActive' : 'menu'
      }
      startIcon={CustomMenuIcon ? <CustomMenuIcon size={14} /> : <IconPoint size={14} />}
    >
      <Typography variant='subtitle2'>
        {props.routeObject.i18nKey ?
          t(`${props.routeObjectChild.i18nKey}`)
          : props.routeObjectChild.id ? t(`${props.routeObjectChild.id}`) : t(`${(props.routeObjectChild.path?.split('/').at(-1))}`)}
      </Typography>
    </Button>
  );
}

function SidebarOpenSubMenu(props: SidebarSubMenuProps): ReactElement {

  return (
    <Grid container direction='column' rowSpacing={1}>
      {props.routeObjects &&
        (props.routeObjects as CustomRouteObject[]).filter(routeObject => !(routeObject.handle && routeObject.handle.top)).map(routeObjectChild => (
          <Grid key={routeObjectChild.path}>
            <SidebarSubMenuButton routeObjectChild={routeObjectChild} open {...props} />
          </Grid>
        ))}
    </Grid>
  );
}

function SidebarCloseSubMenu(props: Omit<SidebarSubMenuProps, 'chevronOpen'>): ReactElement {

  return (
    <>
      {props.routeObjects &&
        (props.routeObjects as CustomRouteObject[])
          .filter(routeObject => !(routeObject.handle && routeObject.handle.top))
          .map(routeObjectChild => (
            <MenuItem
              key={routeObjectChild.path}
              sx={{
                minWidth: '228px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                  cursor: 'default'
                }
              }}
              disableTouchRipple
              disableRipple
            >
              <SidebarSubMenuButton routeObjectChild={routeObjectChild} {...props} />
            </MenuItem>
          ))}
    </>
  );
}

function SidebarMenu(props: SidebarMenuProps & {open?: boolean}): ReactElement {
  const { t } = useTranslation();
  const [chevronOpen, setChevronOpen] = useState(false);
  const [disableRipple, setDisableRipple] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hover, setHover] = useState(false);
  const [onIcon, setOnIcon] = useState(false);
  const [onPopper, setOnPopper] = useState(false);
  const theme = useTheme();
  const CustomMenuIcon = (props.routeObject.handle as HandleType)?.icon;
  const location = useLocation();
  const open = Boolean(anchorEl);
  const routeObjectChildrens =
    props.routeObject.children?.filter(routeObjectChild => (routeObjectChild.path && !routeObjectChild.path.includes(':')))
      .filter(routeObjectChild => !routeObjectChild.index)
      .filter(routeObjectChild => !(routeObjectChild.handle && routeObjectChild.handle.hideMenu));

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!onIcon && !onPopper) {
        setAnchorEl(null);
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [onIcon, onPopper]);

  useEffect(() => {
    setChevronOpen(false);
  }, [props.open]);

  useEffect(() => {
    if (props.open && props.routeObject.path && location.pathname.split('/')[1].includes(props.routeObject.path)) {
      setChevronOpen(true);
    } else {
      setChevronOpen(false);
    }
  }, [location.pathname, props.open]);

  const handleClose = () => {
    setOnIcon(false);
    setOnPopper(false);
    setAnchorEl(null);
  };

  const handleEnterIcon = () => {
    setOnIcon(true);
  };

  const handleLeaveIcon = () => {
    setOnIcon(false);
    setHover(false);
  };

  const handleEnterPopper = () => {
    setOnPopper(true);
  };

  const handleLeavePopper = () => {
    setOnPopper(false);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHover(true);
    if (!props.open && routeObjectChildrens && routeObjectChildrens.length > 0) {
      setAnchorEl(event.currentTarget);
      handleEnterIcon();
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setDisableRipple(true);
    setChevronOpen(prevState => !prevState);
  };

  return (
    <>
      <Grid container direction='column' rowSpacing={1} height='100%'>
        <Grid>
          <Button
            href={props.routeObject.path || '/'}
            variant={
              (props.routeObject.path && location.pathname.split('/')[1].includes(props.routeObject.path)) ||
              (!props.routeObject.path && location.pathname === '/') ?
                props.open ? 'menuActive' : 'menuIconActive' :
                props.open ? 'menu' : 'menuIcon'
            }
            size='large'
            disableRipple={disableRipple}
            disableTouchRipple={disableRipple}
            onClick={handleClose}
            onMouseEnter={handleOpen}
            onMouseLeave={handleLeaveIcon}
            startIcon={
              (props.open && CustomMenuIcon) &&
              <CustomMenuIcon
                size={16}
                stroke={
                  ((props.routeObject.path && location.pathname.split('/')[1].includes(props.routeObject.path)) ||
                  (!props.routeObject.path && location.pathname === '/') || onIcon || hover) ?
                    theme.palette.primary.main : theme.palette.text.primary
                }
                strokeWidth='2'
              />
            }
            endIcon={
              (!isEmpty(routeObjectChildrens) && props.open) &&
              (chevronOpen ?
                <IconChevronUp size={16} onMouseLeave={() => setDisableRipple(false)} onMouseDown={handleClick} /> :
                <IconChevronDown size={16} onMouseLeave={() => setDisableRipple(false)} onMouseDown={handleClick} />
              )
            }
          >
            {props.open ?
              <Typography variant='subtitle1' noWrap>
                {props.routeObject.i18nKey ?
                  t(`${props.routeObject.i18nKey}`)
                  :
                  props.routeObject.id ?
                    t(`${props.routeObject.id}`)
                    :
                    t(`${props.routeObject.path?.split('/').at(-1)}`)
                }
              </Typography> :
              <div
                style={{
                  display: 'block',
                  width: '16px',
                  height: '16px',
                  transform: 'scale(1.5)',
                  marginLeft: CustomMenuIcon ? '4px' : '8px',
                  marginTop: CustomMenuIcon ? '-1px' : '-5px'
                }}
              >
                {CustomMenuIcon ?
                  <>
                    <CustomMenuIcon
                      size={16}
                      color={
                        ((props.routeObject.path && location.pathname.split('/')[1].includes(props.routeObject.path)) ||
                          (!props.routeObject.path && location.pathname === '/') || onIcon || hover) ?
                          theme.palette.primary.main : theme.palette.text.primary
                      }
                      stroke={
                        ((props.routeObject.path && location.pathname.split('/')[1].includes(props.routeObject.path)) ||
                          (!props.routeObject.path && location.pathname === '/') || onIcon || hover) ?
                          theme.palette.primary.main : theme.palette.text.primary
                      }
                      strokeWidth='2'
                    />
                  </> :
                  props.routeObject.i18nKey ?
                    t(`${props.routeObject.i18nKey}`)
                    : props.routeObject.id ? t(`${props.routeObject.id.charAt(0).toUpperCase()}`) : t(`${props.routeObject.path?.split('/').at(-1)?.charAt(0).toUpperCase()}`)
                }
              </div>
            }
          </Button>
        </Grid>
        <Grid mb={routeObjectChildrens && routeObjectChildrens.length > 0 && chevronOpen ? 2 : undefined}>
          <Collapse orientation='vertical' in={routeObjectChildrens && routeObjectChildrens.length > 0 && chevronOpen}>
            <SidebarOpenSubMenu chevronOpen={chevronOpen} routeObjects={routeObjectChildrens} {...props} />
          </Collapse>
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='right'
        sx={{ zIndex: 99999 }}
        onMouseEnter={handleEnterPopper}
        onMouseLeave={handleLeavePopper}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Grid container>
              <Grid>
                <Box sx={{ width: '8px', height: '64px' }} />
              </Grid>
              <Grid>
                <Paper elevation={4}>
                  <SidebarCloseSubMenu routeObjects={routeObjectChildrens} {...props} />
                </Paper>
              </Grid>
            </Grid>
          </Grow>
        )}
      </Popper>
    </>
  );
}

function Sidebar(props: SidebarProps): ReactElement {
  const { t } = useTranslation();
  const [dispChevron, setDispChevron] = useState(false);
  const sidebarValue = useSidebarContext();

  const handleSidebarOpen = () => {
    sidebarValue?.setSidebarOpen(prevState => !prevState);
  };

  const index = Array.isArray(props.routeObjects[0]) ?
    props.routeObjects[0].find(routeObject => !routeObject.path && !(routeObject.handle && routeObject.handle.hideMenu)) :
    (props.routeObjects as CustomRouteObject[]).find(routeObject => !routeObject.path && !(routeObject.handle && routeObject.handle.hideMenu));

  return (
    <>
      <Collapse
        in={sidebarValue?.sidebarOpen}
        orientation='horizontal'
        collapsedSize={64}
        sx={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
      >
        <Grid
          sx={{ width: '260px', height: '90%' }}
          onMouseEnter={() => setDispChevron(true)}
          onMouseLeave={() => setDispChevron(false)}
        >
          <Grid
            container
            direction='column'
            p={4}
            pt='32px'
            pl={!sidebarValue?.sidebarOpen ? 2 : undefined}
            pr={!sidebarValue?.sidebarOpen ? 2 : undefined}
            rowSpacing={1}
          >
            {index &&
              <>
                <Grid key='index'>
                  <SidebarMenu routeObject={index} open={sidebarValue?.sidebarOpen} />
                </Grid>
                {((!Array.isArray(props.routeObjects[0]) &&
                      !props.routeObjects[0].path &&
                      !(props.routeObjects[0].handle &&
                        props.routeObjects[0].handle.hideMenu) &&
                      props.routeObjects.length > 1) ||
                  (Array.isArray(props.routeObjects[0]) &&
                    !props.routeObjects[0][0].path &&
                    !(props.routeObjects[0][0].handle &&
                      props.routeObjects[0][0].handle.hideMenu) &&
                    props.routeObjects[0].length > 1)) &&
                  <Grid key='index-divider' mt={1} mb={2}>
                    {sidebarValue?.sidebarOpen && <Divider />}
                  </Grid>
                }
              </>
            }
            {props.routeObjects.map((routeObjectArray, i) =>
              (Array.isArray(routeObjectArray) ?
                <React.Fragment key={`${routeObjectArray[0].path}-${routeObjectArray[0].handle?.sectionTitle}`}>
                  { routeObjectArray[0].handle?.sectionTitle && sidebarValue?.sidebarOpen &&
                    <Grid key={`sectionTitle-${routeObjectArray[0].handle?.sectionTitle}`} mt={2} mb={1} color='grey.800'>
                      <Typography variant='subtitle2'>
                        {t(`${routeObjectArray[0].handle?.sectionTitle}`)}
                        {/* {upperFirst(routeObjectArray[0].handle?.sectionTitle)} */}
                      </Typography>
                    </Grid>
                  }
                  { routeObjectArray.filter(routeObject => routeObject.path &&
                    !(routeObject.handle && routeObject.handle.top) &&
                    !(routeObject.handle && routeObject.handle.hideMenu))
                    .map(routeObject => (
                      <Grid key={`${routeObject.id}-${routeObject.path}`}>
                        {routeObject.handle?.customComponent ?
                          routeObject.handle?.customComponent :
                          <SidebarMenu routeObject={routeObject} open={sidebarValue?.sidebarOpen} />
                        }
                      </Grid>
                    ))}
                  {i + 1 !== props.routeObjects.length &&
                    (!(routeObjectArray[0].handle &&
                      routeObjectArray[0].handle.hideMenu)) &&
                    <Grid key={`${routeObjectArray[0].id}-${routeObjectArray[0].path}-divider`} mt={1} mb={2}>
                      {sidebarValue?.sidebarOpen && <Divider />}
                    </Grid>
                  }
                </React.Fragment> :
                <React.Fragment key={`${routeObjectArray.path}-${routeObjectArray.handle?.sectionTitle}`}>
                  { routeObjectArray.handle?.sectionTitle && sidebarValue?.sidebarOpen &&
                    <Grid key={`sectionTitle-${routeObjectArray.handle?.sectionTitle}`}>
                      <Typography variant='subtitle2' mt={2} mb={1} color='grey.800'>
                        {t(`${routeObjectArray.handle?.sectionTitle}`)}
                        {/* {upperFirst(routeObjectArray.handle?.sectionTitle)} */}
                      </Typography>
                    </Grid>
                  }
                  { routeObjectArray.path &&
                    !(routeObjectArray.handle && routeObjectArray.handle.top) &&
                    !(routeObjectArray.handle && routeObjectArray.handle.hideMenu) &&
                    <Grid key={`${routeObjectArray.id}-${routeObjectArray.path}`}>
                      {routeObjectArray.handle?.customComponent ?
                        routeObjectArray.handle?.customComponent :
                        <SidebarMenu routeObject={routeObjectArray} open={sidebarValue?.sidebarOpen} />
                      }
                    </Grid>
                  }
                </React.Fragment>
              )
            )}
            <Grid />
            {props.bottomComponent &&
              <>
                <Grid key='index-divider' mt={1} mb={2}>
                  {sidebarValue?.sidebarOpen && <Divider />}
                </Grid>
                <Grid key='bottomElements'>
                  {sidebarValue?.sidebarOpen ? props.bottomComponent.open : props.bottomComponent.close}
                </Grid>
              </>
            }
          </Grid>
        </Grid>
      </Collapse>
      <Grid
        sx={{ width: '24px', mr: -3, ml: -3, mt: 4 }}
        onMouseEnter={() => setDispChevron(true)}
        onMouseLeave={() => setDispChevron(false)}
      >
        <Fade in={dispChevron}>
          <Button variant='sidebarChevron' onClick={handleSidebarOpen}>
            {sidebarValue?.sidebarOpen ? <IconChevronLeft size={24} /> : <IconChevronRight size={24} />}
          </Button>
        </Fade>
      </Grid>
    </>
  );
}

export default Sidebar;
