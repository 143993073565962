import React, { ReactElement, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { IconBell } from '@tabler/icons-react';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';

export interface IconsProps {
  iconList?: string[]
}

function Notifications(): ReactElement {
  const [visible] = useState(false);

  return (
    <Grid>
      <Badge variant='dot' color='secondary' invisible={!visible}>
        <Button variant='topbarIcon' size='small'>
          <IconBell size={20} />
        </Button>
      </Badge>
    </Grid>
  );
}

function IconsTopbar(props: IconsProps): ReactElement {
  return (
    <Grid container>
      {props.iconList?.includes('notifications') && <Notifications />}
    </Grid>
  );
}

export default IconsTopbar;
