import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { IDataBlock, Completeness as IComplenetess } from '@deecision/dna-interfaces';
import { ExtrapolatedWeasseDataBlock } from '@deecision/dna-interfaces/dist/dataBlocks/extrapolatedWeasseDataBlock';
import { WeasseDataBlock } from '@deecision/dna-interfaces/dist/dataBlocks/weasseDataBlock';
import Grid from '@mui/material/Grid2';
import { Card, CardHeader } from '@mui/material';
import { IconCalculator, IconCoins, IconDatabase, IconTargetArrow } from '@tabler/icons-react';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { currencyFormatter } from '@/utils';
import Completeness from '../../../../../../components/chips/completeness';
import PieCharts from '../../../../../../components/charts/pie';
import { BaseChartsProps } from '@/components/charts/types';
import Accuracy from '../../../../../../components/chips/accuracy';
import { baseChartHeight, baseChartWidth } from '@/components/charts/utils';

export const weasseDataPaths = ['person.weasse', 'person.extrapolatedWeasse'];

function WeasseChart(): ReactElement {
  const { t } = useTranslation();
  const dataBlock = (useLoaderData() as { data: IDataBlock<WeasseDataBlock>[] }).data.find(d => d.dataInfo.path === 'person.weasse');

  const chartData: BaseChartsProps['datas'][0] = dataBlock?.data.details?.weasse?.components?.map(c => ({
    label: c.entityRef?.name || c.entityRef?.entityId || t('common.utils.unknown'),
    count: c.amount === 0 ? 0 : c.amount || null,
    value: c.entityRef?.name || c.entityRef?.entityId || t('common.utils.unknown'),
    range: {
      min: 0,
      max: 0
    }
  })) || [];

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconCoins size={24} />
            <div style={{ paddingRight: '4px' }} />
            {t('common.financial.weasse.label')}
          </Stack>
        }
      />
      <CardHeader
        sx={{ marginTop: 4 }}
        title={
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='h2'>
              {currencyFormatter({
                value: dataBlock?.data.details?.weasse.components?.reduce((acc, curr) => acc + (curr.amount || 0), 0) as number,
                currency: 'EUR'
              })}
            </Typography>
            <Completeness
              completeness={(dataBlock?.data.values?.weasse?.completeness as IComplenetess).value}
              icon={IconDatabase}
            />
          </Stack>
        }
        subheader={<div style={{ marginTop: '-52px' }}>{t('common.utils.total')}</div>}
      />
      <CardContent sx={{ height: baseChartHeight, minWidth: baseChartWidth, width: '100%' }}>
        <PieCharts
          datas={[chartData]}
          totalCount={dataBlock?.data.details?.weasse?.components?.reduce((acc, curr) => acc + (curr.amount || 0), 0) as number}
          dataType='string'
          countType='amount'
        />
      </CardContent>
    </Card>
  );
}

function ExtrapolatedWeasseChart(): ReactElement {
  const { t } = useTranslation();
  const dataBlock = (useLoaderData() as {
    data: IDataBlock<ExtrapolatedWeasseDataBlock>[]
  }).data.find(d => d.dataInfo.path === 'person.extrapolatedWeasse');

  const chartData: BaseChartsProps['datas'][0] = dataBlock?.data.details?.extrapolatedWeasse?.components?.map(c => ({
    label: c.type || t('common.utils.unknown'),
    count: c.value === 0 ? 0 : c.value || null,
    value: t(`common.financial.extrapolatedWeasse.types.${c.type}`) || t('common.utils.unknown'),
    range: {
      min: 0,
      max: 0
    }
  })) || [];

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={
          <Stack direction='row' spacing={2} alignItems='center'>
            <IconCoins size={24} />
            <IconCalculator size={18} style={{ marginLeft: '-2px', alignSelf: 'end' }} />
            <div style={{ paddingRight: '4px' }} />
            {t('common.financial.extrapolatedWeasse.label')}
          </Stack>
        }
      />
      <CardHeader
        sx={{ marginTop: 4 }}
        title={
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='h2'>
              {currencyFormatter({
                value: dataBlock?.data.details?.extrapolatedWeasse?.components?.reduce((acc, curr) => acc + (curr.value || 0), 0) as number,
                currency: 'EUR'
              })}
            </Typography>
            <Completeness
              completeness={(dataBlock?.data.values?.extrapolatedWeasse?.completeness as IComplenetess)?.value}
              icon={IconDatabase}
            />
            <Accuracy
              accuracy={dataBlock?.data.values?.extrapolatedWeasse?.accuracy}
              icon={IconTargetArrow}
            />
          </Stack>
        }
        subheader={<div style={{ marginTop: '-52px' }}>{t('common.utils.total')}</div>}
      />
      <CardContent sx={{ height: baseChartHeight, minWidth: baseChartWidth, width: '100%' }}>
        <PieCharts
          datas={[chartData]}
          totalCount={dataBlock?.data.details?.extrapolatedWeasse?.components?.reduce((acc, curr) => acc + (curr.value || 0), 0) as number}
          dataType='string'
          countType='amount'
          currency={dataBlock?.data.details?.extrapolatedWeasse?.components?.[0]?.currency}
        />
      </CardContent>
    </Card>
  );
}

function WeasseTabsComponents(): ReactElement {

  return (
    <Grid container spacing={4} width='100%'>
      <Grid size='grow'>
        <WeasseChart/>
      </Grid>
      <Grid size='grow'>
        <ExtrapolatedWeasseChart/>
      </Grid>
    </Grid>
  );
}

export default WeasseTabsComponents;
