import React, { FC, useMemo } from 'react';
import { Chip, darken, lighten, Stack, Typography, useTheme } from '@mui/material';
import { EdgeProps, EdgeLabelRenderer, BaseEdge, getBezierPath } from 'reactflow';

function EdgeLabel({ transform, label, color }: { transform: string, label: string, color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' }) {

  return (
    <Chip
      color={color}
      label={label}
      style={{
        position: 'absolute',
        border: '0.5px solid grey',
        color: '#4B3621',
        fontSize: 24,
        fontWeight: 500,
        transform,
        zIndex: 1000
      }}
      className='nodrag nopan'
    />
  );
}

export const CustomEdge: FC<EdgeProps> = function CustomEdge(props: EdgeProps & { mainEntitie?: string, overlap?: boolean}) {
  const theme = useTheme();
  const offset = (parseInt(props.id.split('-')[1], 10) % 10) * 5;
  const [edgePath, labelX, labelY] = getBezierPath({
    ...props,
    sourceX: props.sourceX + (props.overlap ? offset : 0),
    sourceY: props.sourceY + (props.overlap ? offset : 0),
    targetX: props.targetX + (props.overlap ? offset : 0),
    targetY: props.targetY + (props.overlap ? offset : 0)
  });

  const positionTypesLabel = useMemo(() => (
    Array.isArray(props.label)
      && props.label.length >= 1 &&
      // && props.data.settingsList?.current.some((setting: SettingsProps) => setting.id === 'label' && setting.status) &&
      <EdgeLabelRenderer>
        <Stack
          p={2}
          borderRadius={1}
          bgcolor={props.data.highlight ? lighten(props.data.highlight.color, 0.8) : props.label.includes('adv') ? theme.palette.secondary.light : props.data.labelEdgeColor ? lighten(props.data.labelEdgeColor, 0.7) : '#DEE9F8'}
          border={1}
          borderColor={darken('#DEE9F8', 0.2)}
          style={{
            position: 'absolute',
            // transform: props.data.parentOrChildrenDirection === 'parent' ?  `translate(-50%, 0%) translate(${props.sourceX + 60}px,${props.sourceY - 20}px)` : `translate(-50%, -100%) translate(${props.targetX - 85}px,${props.targetY + 25}px)`
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
          }}>
          {Array.isArray(props.label) && props.label.map((label: string) => (
            <Typography fontWeight={500} fontSize={30}>{label}</Typography>
          ))}
        </Stack>
      </EdgeLabelRenderer>
  ), [
    // props.data.settingsList?.current.some((setting: SettingsProps) => setting.id === 'label' && setting.status),
    props.data.highlight
  ]);

  return (
    <>
      {props.data && props.data.mainEntitie !== 'person' &&
        <svg width='0' height='0'>
          <defs>
            <marker
              id='arrow'
              viewBox='0 0 10 10'
              refX='9'
              refY='5'
              markerWidth='15'
              markerHeight='15'
              orient='auto-start-reverse'
            >
              <path d='M 0 0 L 10 5 L 0 10 z' fill='grey' />
            </marker>
          </defs>
        </svg>
      }
      <BaseEdge
        id={props.id}
        path={edgePath}
        labelShowBg={false}
        markerStart={props.data.arrowAt?.includes('start') ? 'url(#arrow)' : undefined}
        markerEnd={props.data.arrowAt?.includes('end') ? 'url(#arrow)' : undefined}
        style={{
          stroke: props.data.highlight ?
            props.data.highlight.color : (Array.isArray(props.label) && props.label.length >= 1 && props.label.includes('adv') ? '#92E0DA' : props.data.labelEdgeColor ? props.data.labelEdgeColor : darken('#D0DDF1', 0.2)),
          strokeWidth: 3
          // animation: `dashdraw 0.8s linear infinite ${props.sourcePosition === 'left' ? 'reverse' : ''}`,
          // strokeDasharray: 5
        }}/>
      {positionTypesLabel}

      {props.data.sharesPercentage !== undefined && props.data.sharesPercentageWarningLabel &&
        <EdgeLabelRenderer>
          <EdgeLabel
            transform={`translate(-50%, -100%) translate(${props.sourceX + 96 }px,${props.sourceY + 20}px) scale(0.9)`}
            label='Missing data'
            color='warning'
          />
        </EdgeLabelRenderer>
      }
    </>
  );
};

export default CustomEdge;
