import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconChartRadar } from '@tabler/icons-react';
import BaseWidget from '@/main/containers/widgets/base-widget';
import { WidgetProps } from '@/main/containers/widgets/types';
import RadarCharts from '@/components/charts/radar';
import TitleComponent from '@/components/title';

function WidgetsRadar(props: WidgetProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const radarSubjects = [
    t('entities.persons.person.charts.radar.completeness'),
    t('entities.persons.person.charts.radar.alerts'),
    t('entities.persons.person.charts.radar.compliance'),
    t('entities.persons.person.charts.radar.opportunities'),
    t('entities.persons.person.charts.radar.trust')
  ];

  const radarDatas = [
    {
      label: '2022',
      dataKey: 'A' as const,
      color: theme.palette.secondary.main,
      datas: {}
    },
    {
      label: '2023',
      dataKey: 'B' as const,
      color: theme.palette.primary.main,
      datas: {}
    }
  ];

  radarDatas.forEach((radarData, index) => {
    radarSubjects.forEach((radarSubject) => {
      radarDatas[index].datas = { ...radarData.datas, [radarSubject]: (Math.floor(Math.random() * 10) + 1) };
    });
  });

  const datas = [
    radarSubjects.map(radarSubject => ({
      count: (Math.floor(Math.random() * 10) + 1),
      value: radarSubject
    })),
    radarSubjects.map(radarSubject => ({
      count: (Math.floor(Math.random() * 10) + 1),
      value: radarSubject
    }))
  ];

  return (
    <BaseWidget
      {...props}
      title={
        <TitleComponent title={t('entities.persons.person.charts.radar.decisionRadar')} icon={IconChartRadar} small />
      }
      chart
      size='small'
    >
      <Box height='100%' width='100%'>
        <RadarCharts
          datas={datas}
          legends={['2024', '2023']}
          maxValue={10}
          legendPosition='bottom'
          leveled
        />
      </Box>
    </BaseWidget>
  );
}

export default WidgetsRadar;
