import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, FinancialReportDataBlock, IDataBlock } from '@deecision/dna-interfaces';
import { WidgetProps } from '@/main/containers/widgets/types';
import { DataBlocksQueryService } from '@/api/services/query';
import FinancialChart from '@/main/containers/financial/chart';
import { getPathList, parseAndSortFinancialData } from '@/main/containers/financial/utils';

function CompanyWidgetsFinancialChart(props: WidgetProps<CompanyEntity>): ReactElement {
  const [selectedKey, setSelectedKey] = useState(['profitAndLossAccount.turnover']);
  const dataElementQueryService = new DataBlocksQueryService<FinancialReportDataBlock>({ dataPath: 'company.financialReportDetails', entityType: 'deecCompany' });
  const [dataBlocks, setDataBlocks] = useState<IDataBlock<FinancialReportDataBlock>[]>([]);

  const getDataBlock = () => {
    dataElementQueryService.get(props.data.entityId)
      .then((res) => {
        if (res.data) {
          setDataBlocks(parseAndSortFinancialData(res.data));
        }
      });
  };

  useEffect(() => {
    getDataBlock();
  }, [props.data]);

  return (
    <FinancialChart data={dataBlocks} pathList={getPathList(dataBlocks)} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />
  );
}

export default CompanyWidgetsFinancialChart;
