import React, { ReactElement } from 'react';
import { IconButton, useTheme } from '@mui/material';
import { IconMaximize, IconMaximizeOff } from '@tabler/icons-react';
import { useFullscreen } from '@/wrappers/fullscreen';

type FullscreenButtonProps = {
  noBorder?: boolean
}

function FullscreenButton(props: FullscreenButtonProps): ReactElement {
  const theme = useTheme();
  const { isFullscreen, setIsFullscreen } = useFullscreen();

  return (
    <IconButton
      size='small'
      onClick={() => setIsFullscreen(!isFullscreen)}
      sx={{
        border: props.noBorder ? 0 : 1,
        borderColor: '#C0C0C1',
        alignSelf: 'center',
        backgroundColor: !props.noBorder ? theme.palette.grey['50'] : undefined,
        aspectRatio: '1/1',
        '&:hover': {
          backgroundColor: !props.noBorder ? theme.palette.grey['200'] : undefined
        }
      }}
    >
      {isFullscreen ? <IconMaximizeOff /> : <IconMaximize />}
    </IconButton>
  );
}

export default FullscreenButton;