import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  IconCheck,
  IconClipboardData,
  IconEdit,
  IconFolderCheck,
  IconTargetArrow
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import { useSearchParams } from 'react-router-dom';
import CustomizedSteppers from '@/components/steppers/horizontal';
import TargetStepsComponentsCreateFolders from './components/steps/target';
import ChoicesStepsComponentsCreateFolders from './components/steps/choices';
import PreparationStepsComponentsCreateFolders from './components/steps/preparation';
import AnalyseStepsComponentsCreateFolders from './components/steps/analyse';
import ValidationStepsComponentsCreateFolders from './components/steps/validation';

function CreateComponent(): ReactElement {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: `${t('common.utils.step')} 1`,
      desc: t('common.utils.target')
    },
    {
      label: `${t('common.utils.step')} 2`,
      desc: t('common.utils.choices')
    },
    {
      label: `${t('common.utils.step')} 3`,
      desc: t('common.utils.preparation')
    },
    {
      label: `${t('common.utils.step')} 4`,
      desc: t('common.utils.analyse')
    },
    {
      label: `${t('common.utils.step')} 5`,
      desc: t('common.utils.validation')
    }
  ];

  const icons: { [index: string]: React.ReactElement } = {
    1: <IconTargetArrow size={20} />,
    2: <IconFolderCheck size={20} />,
    3: <IconEdit size={20} />,
    4: <IconClipboardData size={20} />,
    5: <IconCheck size={20} />
  };

  const stepComponents = useMemo(() => [
    <TargetStepsComponentsCreateFolders />,
    <ChoicesStepsComponentsCreateFolders />,
    <PreparationStepsComponentsCreateFolders />,
    <AnalyseStepsComponentsCreateFolders />,
    <ValidationStepsComponentsCreateFolders />
  ], []);

  useEffect(() => {
    const activeStepTmp = searchParams.get('step');

    if (activeStepTmp) {
      setActiveStep(parseInt(activeStepTmp, 10) - 1);
    }
  }, [searchParams]);

  return (
    <Grid container spacing={2} pt={2}>
      <Grid size={12}>
        <CustomizedSteppers activeStep={activeStep} icons={icons} steps={steps} />
      </Grid>
      <Grid size={12} mt={8} p={2}>
        {stepComponents[activeStep]}
      </Grid>
    </Grid>
  );
}

export default CreateComponent;
