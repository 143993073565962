import React, { ReactElement, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { EChartOption } from 'echarts';
import { BaseChartsProps } from './types';
import { baseChartColors, wrapEmoji } from './utils';
import './style.css';
import valueParser, { ValueType } from '@/utils/value-parser';
import { mainRadius } from '@/theme/theme';

export interface RadarChartsProps extends Omit<BaseChartsProps, 'totalCount'>, Partial<Pick<BaseChartsProps, 'totalCount'>> {
  maxValue?: number | number[],
  legendType?: ValueType,
  leveled?: boolean
}

function RadarCharts(props: RadarChartsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const onEvents = {
    click: () => undefined
  };

  const option: EChartOption = useMemo(() => {
    const max = props.maxValue || Math.max(...props.datas.map(data => Math.max(...(data?.map(dd => dd.count || 0) || 10))));

    return {
      color: baseChartColors(theme),
      tooltip: {
        trigger: 'item',
        extraCssText: `border-radius: ${mainRadius}px; box-shadow: ${theme.shadows[3]}`,
        backgroundColor: theme.palette.background.paper,
        formatter: params => (Array.isArray(params) ?
          params.map(param => `${param.name}<br />${(param.value as string[]).map((value, index) =>
            `<div style="display: flex; flex-direction: row; align-items: center; gap: 4px">${param.marker} ${wrapEmoji(valueParser({ value: param.data.dataValue[index], valueType: props.dataType, currency: props.currency, t }), props.dataType, true)} <span style="margin-left: auto; padding-left: 8px"><b>${wrapEmoji(valueParser({ value, valueType: props.countType, currency: props.currency, t }), props.countType, true)}</b></span></div>`
          ).join('<br />')}`).join('<br /><br />') :
          `${params.name}<br />${(params.value as string[]).map((value, index) =>
            `<div style="display: flex; flex-direction: row; align-items: center; gap: 4px">${params.marker} ${wrapEmoji(valueParser({ value: params.data.dataValue[index], valueType: props.dataType, currency: props.currency, t }), props.dataType, true)} <span style="margin-left: auto; padding-left: 8px"><b>${wrapEmoji(valueParser({ value, valueType: props.countType, currency: props.currency, t }), props.countType, true)}</b></span></div>`
          ).join('')}`)
      },
      legend: {
        top: props.legendPosition === 'top' ? 0 : props.legendPosition ? undefined : 'center',
        right: !props.legendPosition || props.legendPosition === 'right' ? 0 : undefined,
        bottom: props.legendPosition === 'bottom' ? 0 : undefined,
        left: props.legendPosition === 'left' ? 0 : props.legendPosition && props.legendPosition !== 'right' ? 'center' : undefined,
        orient: props.legendPosition === 'bottom' || props.legendPosition === 'top' ? 'horizontal' : 'vertical',
        textStyle: {
          rich: {
            flagEmojiStyle: {
              fontFamily: 'NotoColorEmojiLimited'
            }
          }
        },
        data: Array.isArray(props.legends)
          ? props.legends || []
          : [props.legends || '']
      },
      radar: {
        left: 0,
        center: props.legendPosition === 'bottom' || props.legendPosition === 'top' ? ['50%', '50%'] : ['40%', '50%'],
        axisName: {
          rich: {
            flagEmojiStyle: {
              fontFamily: 'NotoColorEmojiLimited'
            }
          },
          formatter: (value: string) => wrapEmoji(valueParser({ value, valueType: props.legendType, currency: props.currency, t }), props.legendType),
          color: theme.palette.text.primary
        },
        axisLine: {
          lineStyle: {
            color: theme.palette.text.disabled
          }
        },
        splitLine: {
          lineStyle: {
            color: theme.palette.text.disabled.replace(')', ', 0.5)').replace('rgb', 'rgba')
          }
        },
        splitNumber: 3,
        splitArea: {
          areaStyle: {
            color: props.leveled ? [
              theme.palette.error.light.replace(')', ', 0.5)').replace('rgb', 'rgba'),
              theme.palette.warning.light.replace(')', ', 0.5)').replace('rgb', 'rgba'),
              theme.palette.success.light.replace(')', ', 0.5)').replace('rgb', 'rgba')
            ] : [
              theme.palette.grey['300'],
              'transparent',
              theme.palette.grey['300']
            ]
          }
        },
        indicator: props.datas[0]?.map((d, index) => ({
          name: d.value,
          max: Array.isArray(max) ? max[index] : max
        }))
      },
      series: props.datas.map((data, index) => ({
        type: 'radar',
        areaStyle: {},
        emphasis: {
          lineStyle: {
            width: 4
          }
        },
        label: {
          rich: {
            flagEmojiStyle: {
              fontFamily: 'NotoColorEmojiLimited'
            }
          }
        },
        data: [
          {
            name: props.legends
              ? Array.isArray(props.legends)
                ? props.legends?.[index]
                : props.legends
              : '',
            value: data?.map(d => d.count),
            range: data?.map(d => d.range),
            dataValue: data?.map(d => d.value)
          }
        ]
      }))
    };
  }, [props.datas, props.selected]);

  return (
    <ReactECharts
      option={option}
      onEvents={onEvents}
      lazyUpdate
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export default RadarCharts;
