import React, { ReactElement, useMemo, useState } from 'react';
import {
  SegmentationCriteriaInfo,
  SegmentationPossibleDisplayTypes
} from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { darken, useTheme } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {
  IconArrowsSplit2,
  IconChartBar,
  IconChartDonut,
  IconChartPpf,
  IconDotsVertical,
  IconTrash
} from '@tabler/icons-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { lowerFirst } from 'lodash';
import { SegmentationFilter } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';
import { SegmentationData } from '@deecision/dna-interfaces/dist/segmentation/segmentation';
import Chip from '@mui/material/Chip';
import { BaseChartsProps } from '@/components/charts/types';
import PieCharts from '../../../../../components/charts/pie';
import YesNoCharts from '../../../../../components/charts/yesno';
import BarCharts from '../../../../../components/charts/bar';
import {
  BaseSegmentationProps,
  PotentialSegmentationCriteria
} from '../../types.segmentations';
import { baseChartHeight, baseChartWidth } from '@/components/charts/utils';

export interface ChartDispatchSegmentationsProps extends BaseSegmentationProps {
  criteria: PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0],
  handleDelete?: (segmentationCriteria:  PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0]) => void,
  handleChangeDisplayType?: (displayType: SegmentationPossibleDisplayTypes, segmentationCriteria: PotentialSegmentationCriteria & SegmentationData['segmentationCriterias'][0]) => void,
  totalCount: number
}

function DispatchChartsSegmentations(props: ChartDispatchSegmentationsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState<number>();
  const [halfPieChart, setHalfPieChart] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setValuesOrRanges = () => {
    const value = props.criteria.values?.[selected || 0]?.value || undefined;
    const range = props.criteria.values?.[selected || 0]?.range || undefined;

    props.addFilter({
      id: props.criteria.id || 'unknown',
      filterId: (props.criteria?.filterSpec ? undefined : props.criteria.filterId) || 'unknown',
      label: props.criteria.displayInfo.label || props.criteria.filterLabel,
      on: (props.criteria?.filterSpec ? props.criteria.filterSpec.on : props.criteria?.on) || 'person1',
      valueType: props.criteria.displayInfo.valueType || props.criteria?.valueType || 'string' as const,
      type: props.criteria?.filterSpec ? props.criteria.filterSpec.type : 'filter' as const,
      subItemSpecs: props.criteria?.filterSpec ? props.criteria.filterSpec.subItemSpecs : undefined,
      values: value ? [value] : undefined,
      ranges: range ? [range] : undefined
    } as SegmentationFilter);
    setSelected(undefined);
  };

  const Charts: Record<SegmentationCriteriaInfo['displayInfo']['displayType'], (props: BaseChartsProps) => ReactElement> = {
    pieChart: elementProps => PieCharts({ ...elementProps, halfPieChart }),
    yesNo: elementProps => YesNoCharts(elementProps),
    barChart: elementProps => BarCharts({ ...elementProps })
  };

  const Chart = useMemo(() => Charts[props.criteria.displayInfo.displayType], [props.criteria]);

  return (
    <Paper
      sx={{
        width: '100%',
        bgcolor: theme.palette.background.paper
      }}
    >
      <Stack spacing={2}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          <Stack direction='row' spacing={2} alignItems='center'>
            {props.criteria?.displayInfo?.displayType === 'barChart' && (halfPieChart ? <IconChartPpf /> : <IconChartBar />)}
            {props.criteria?.displayInfo?.displayType === 'pieChart' && <IconChartDonut />}
            {props.criteria?.displayInfo?.displayType === 'yesNo' && <IconArrowsSplit2 />}
            <Typography variant='h5' p={1}>{props.criteria.displayInfo.label || props.criteria.filterLabel}</Typography>
            <Chip
              label={t(`entities.${props.criteria.on}.multiple`)}
              color={props.criteria.on === 'person' ? 'primary' : props.criteria.on === 'company' ? undefined : 'warning'}
              sx={props.criteria.on === 'company' ? {
                bgcolor: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              } : undefined}
              size='small'
            />
          </Stack>
          <IconButton
            size='small'
            id='chart-menu-button'
            onClick={handleClick}
            aria-controls={open ? 'chart-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <IconDotsVertical size={20} />
          </IconButton>
          <Menu
            id='chart-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'chart-menu-button'
            }}
          >
            {props.criteria?.displayInfo?.displayType !== 'barChart' && props.criteria?.possibleDisplayTypes?.includes('barChart') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('barChart', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartBar color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.barChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(props.criteria?.displayInfo?.displayType !== 'pieChart' || halfPieChart) && props.criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(false);
                  props.handleChangeDisplayType?.('pieChart', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartDonut color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.pieChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {(props.criteria?.displayInfo?.displayType !== 'pieChart' || !halfPieChart) && props.criteria?.possibleDisplayTypes?.includes('pieChart') &&
              <MenuItem
                onClick={() => {
                  setHalfPieChart(true);
                  props.handleChangeDisplayType?.('pieChart', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconChartPpf color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.halfPieChart') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {props.criteria?.displayInfo?.displayType !== 'yesNo' && props.criteria?.possibleDisplayTypes?.includes('yesNo') &&
              <MenuItem
                onClick={() => {
                  props.handleChangeDisplayType?.('yesNo', props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconArrowsSplit2 color={theme.palette.text.primary} />
                </ListItemIcon>
                <ListItemText>
                  {`${t('segmentation.builder.displayTypes.transformTo')} ${lowerFirst(t('segmentation.builder.displayTypes.yesNo') || '')}`}
                </ListItemText>
              </MenuItem>
            }
            {props.handleDelete &&
              <MenuItem
                onClick={() => {
                  props.handleDelete?.(props.criteria);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <IconTrash color={theme.palette.error.main} />
                </ListItemIcon>
                <ListItemText sx={{ pr: 4, color: theme.palette.error.main }}>
                  {t('segmentation.builder.custom.delete')}
                </ListItemText>
              </MenuItem>
            }
          </Menu>
        </Stack>
        <Box
          sx={{
            height: baseChartHeight,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative'
          }}
        >
          {props.criteria.values ?
            <Chart
              {...props}
              datas={[props.criteria?.values]}
              on={props.criteria.on === 'person' ? t('entities.persons.label') : props.criteria.on === 'company' ? t('entities.companies.label') : props.criteria.on === 'person2person' ? t('entities.links.multiple') : t('common.utils.unknown')}
              selected={selected}
              dataType={props.criteria?.valueType}
              setSelected={setSelected}
            /> :
            <Stack direction='row' spacing={1} alignItems='center'>
              {props.criteria.displayInfo.displayType === 'pieChart' ? [
                <Skeleton variant='circular' width={baseChartHeight / 2} height={baseChartHeight / 2} />,
                <Stack spacing={2} pl={8}>
                  {[...Array(7)].map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Skeleton key={index} variant='rounded' width={96} height={16} />
                  ))}
                </Stack>
              ] :
                [...Array(7)].map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Skeleton key={index} variant='rectangular' width={baseChartWidth / 9} height='75%' sx={{ borderRadius: '8px' }} />
                ))
              }
            </Stack>
          }
          <Collapse
            in={selected !== undefined}
            sx={{
              bottom: 0,
              bgcolor: theme.palette.background.default,
              borderRadius: 2,
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: '8px',
              border: `1px solid ${theme.palette.divider}`,
              zIndex: 2,
              position: 'absolute',
              width: '100%'
            }}
          >
            <Grid container spacing={2} p={2} width='100%'>
              <Grid size='grow'>
                <Button
                  onClick={() => setSelected(undefined)}
                  sx={{
                    '&:hover': {
                      color: theme.palette.error.main
                    }
                  }}
                  fullWidth
                >
                  {t('segmentation.filters.cancel')}
                </Button>
              </Grid>
              <Grid size='grow'>
                <Button
                  variant='contained'
                  onClick={setValuesOrRanges}
                  sx={{
                    bgcolor: theme.palette.success.main,
                    borderColor: theme.palette.success.dark,
                    '&:hover': {
                      bgcolor: darken(theme.palette.success.main, 0.3),
                      borderColor: theme.palette.success.main
                    }
                  }}
                  fullWidth
                >
                  {t('segmentation.filters.apply')}
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Stack>
    </Paper>
  );
}

export default DispatchChartsSegmentations;
