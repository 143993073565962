import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
  IconArrowRight,
  IconClipboardData, IconFilterSearch,
  IconPlus,
  IconRefresh,
  IconTimelineEventPlus,
  IconTrash
} from '@tabler/icons-react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Tooltip, useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import IconButton from '@mui/material/IconButton';
import dnaConfig from '../../../../../config/dna.config.json';
import CustomAccordion from '../../../../../components/accordion';
import { UserDataCustomSegmentationService } from '../../../../containers/segmentations/services/segmentations.services';
import {
  BaseCustomUserData,
  CustomSegmentationData,
  CustomUserData
} from '../../../../containers/segmentations/types.segmentations';
import { getEntityTypeEquivalent } from '../../../../providers/getter';
import DeleteModalsRenderSegmentations from '../../../../containers/segmentations/render/modals/delete.modals.render.segmentations';
import getSegmentations from '../../../../containers/segmentations/getters/segmentation.getters.segmentations';

function Segmentation(props: { segmentation: BaseCustomUserData<CustomSegmentationData>, disabled?: boolean, userId?: string, updateList: () => void }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dataServicePersonal = new UserDataCustomSegmentationService<BaseCustomUserData>({ userId: props.userId || '' });
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

  const handleOpenConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };
  const handleCloseOpenConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const redirect = () => navigate({
    pathname: `${props.segmentation.groups?.[0] === 'me' ? dnaConfig.childRoutes.segmentations.custom.baseUrl : dnaConfig.childRoutes.segmentations.assisted.baseUrl}/${props.segmentation.groups?.[0] !== 'me' ? `${props.segmentation.groups?.[0]}/` : ''}${props.segmentation._id}`,
    search: location.search
  });

  return (
    <Paper
      key={props.segmentation._id}
      variant={!props.disabled ? 'hoverElevation2' : undefined}
      sx={{
        width: '100%',
        p: 2,
        cursor: !props.disabled ? 'pointer' : undefined,
        opacity: props.disabled ? 0.5 : 1
      }}
      onClick={!props.disabled ? redirect : undefined}
    >
      <Stack
        direction='row'
        spacing={4}
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack direction='row' spacing={2} alignItems='center'>
          <IconClipboardData />
          <Typography variant='h4' pr={3}>
            {props.segmentation.title}
          </Typography>
          <Chip
            label={
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconFilterSearch size={16} />
                <Typography variant='body2'>
                  {`${props.segmentation.data.potentialSegmentationCriterias.length} ${t('segmentation.render.segmentationCriterias.label')}`}
                </Typography>
              </Stack>
            }
            color='primary'
            size='small'
          />
          <Tooltip title={t('common.dates.creationDate')} arrow placement='top'>
            <Chip
              label={
                <Stack direction='row' spacing={1} alignItems='center'>
                  <IconTimelineEventPlus size={16} />
                  <Typography variant='body2'>
                    {props.segmentation?.date
                      ? `${new Date(props.segmentation.date).toLocaleDateString()} ${new Date(props.segmentation.date).toLocaleTimeString()}`
                      : ''}
                  </Typography>
                </Stack>
              }
              color='success'
              size='small'
            />
          </Tooltip>
          <Tooltip title={t('common.dates.lastUpdate')} arrow placement='top'>
            <Chip
              label={
                <Stack direction='row' spacing={1} alignItems='center'>
                  <IconRefresh size={16} />
                  <Typography variant='body2'>
                    {props.segmentation?.lastUpdate
                      ? `${new Date(props.segmentation.lastUpdate).toLocaleDateString()} ${new Date(props.segmentation.lastUpdate).toLocaleTimeString()}`
                      : ''}
                  </Typography>
                </Stack>
              }
              sx={{
                bgcolor: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }}
              size='small'
            />
          </Tooltip>
        </Stack>
        <Stack direction='row' spacing={2} alignItems='center'>
          {!props.disabled && (
            <Button
              variant='containedIcon'
              onClick={(e) => {
                e.stopPropagation();
                redirect();
              }}
            >
              <IconArrowRight size={22} />
            </Button>
          )}
          {props.userId && props.segmentation._id && (
            <IconButton>
              <IconTrash
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenConfirmDelete();
                }}
                color={theme.palette.error.main}
              />
            </IconButton>
          )}
          <DeleteModalsRenderSegmentations
            openConfirmDelete={openConfirmDelete}
            handleCloseOpenConfirmDelete={handleCloseOpenConfirmDelete}
            handleDeleteSegmentation={() =>
              dataServicePersonal
                .delete?.(props.segmentation._id || '')
                .then(() =>
                  (props.updateList ? props.updateList() : navigate(0))
                )
            }
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

function SelectComponentSegmentations(): ReactElement {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const keycloak = useKeycloak();
  const [assistedSegmentations, setAssistedSegmentations] = useState<CustomUserData<CustomSegmentationData>[]>([]);
  const [personalSegmentations, setPersonalSegmentations] = useState<CustomUserData<CustomSegmentationData>[]>([]);
  const entityType = searchParams.get('entityType');

  const updateLists = useCallback(() => {
    if (keycloak.keycloak.tokenParsed) {
      getSegmentations(keycloak.keycloak)
        .then((segmentation) => {
          setPersonalSegmentations(segmentation.filter(seg => seg.ownerType === 'user' && seg.ownerId === keycloak.keycloak.tokenParsed?.preferred_username));
          setAssistedSegmentations(segmentation.filter(seg => seg.ownerType === 'group'));
        });
    }
  }, [keycloak.keycloak]);

  useEffect(() => {
    updateLists();
  }, [keycloak.keycloak.tokenParsed]);

  return (
    <Stack minWidth={600} spacing={4}>
      <Button variant='contained' endIcon={<IconPlus />} sx={{ width: 'fit-content', alignSelf: 'flex-end' }} onClick={() => navigate({ pathname: 'custom/create', search })}>
        {t('segmentation.create')}
      </Button>
      <Stack>
        <CustomAccordion title={t('segmentation.assisted.multiFullLabel')} defaultOpen disabled={assistedSegmentations.length < 1}>
          <Stack p={4} spacing={2}>
            {assistedSegmentations.map((segmentation: BaseCustomUserData<CustomSegmentationData>) => (
              <Segmentation key={segmentation._id} segmentation={segmentation} disabled={!!(entityType && segmentation.data.possibleDataSetIds.every(possibleDataSetId => getEntityTypeEquivalent(possibleDataSetId) !== entityType))} updateList={updateLists} />
            ))}
          </Stack>
        </CustomAccordion>
        <CustomAccordion title={t('segmentation.augmented.multiCustomLabel')} defaultOpen disabled={personalSegmentations.length < 1}>
          <Stack p={4} spacing={2}>
            {personalSegmentations.map((segmentation: BaseCustomUserData<CustomSegmentationData>) => (
              <Segmentation key={segmentation._id} segmentation={segmentation} disabled={!!(entityType && segmentation.data.possibleDataSetIds.every(possibleDataSetId => getEntityTypeEquivalent(possibleDataSetId) !== entityType))} userId={keycloak.keycloak.tokenParsed?.preferred_username} updateList={updateLists} />
            ))}
          </Stack>
        </CustomAccordion>
      </Stack>

    </Stack>
  );
}

export default SelectComponentSegmentations;
