import { Box, Button, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Theme, Tooltip, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { IconFilter, IconRotate } from '@tabler/icons-react';
import { t } from 'i18next';
import Grid from '@mui/material/Grid2';

type FilterProps = {
    filterEntityTypeName: string[],
    filterSchedulingStatusName: string[],
    filterTypeName: string[],
    handleSetters: (setter: string, value: string[]) => void,
    setResetting: (value: boolean) => void
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
  };
}

const valueStatusType = [
  'done',
  'failed',
  'requested',
  'waiting_for_dependencies'
];
const valueTypeType = [
  'deecCompany:abcbourse.simple',
  'deecCompany:bing.news.articles',
  'deecCompany:bodacc.publications.capital',
  'deecCompany:bodacc.publications.raw',
  'deecCompany:bodacc.publications.stats',
  'deecCompany:deecision.company.baseTags',
  'deecCompany:deecision.company.valuation',
  'deecCompany:deecision.listedCompany.valuation',
  'deecCompany:googleCse.search.results',
  'deecCompany:googleCse.search.stats',
  'deecCompany:newsApiOrg.news.articles',
  'deecCompany:newsApiOrg.news.stats',
  'deecCompany:rncs.company.actes',
  'deecCompany:rncs.company.baseInfo',
  'deecCompany:rncs.company.bilans',
  'deecCompany:rncs.company.keyFigures',
  'deecCompany:rncs.company.persons',
  'deecCompany:rncs.company.raw',
  'deecCompany:rncs.searchCompany',
  'deecPerson:deecision.companies.keyFiguresSums',
  'deecPerson:deecision.companies.stats',
  'deecPerson:deecision.person.network',
  'deecPerson:deecision.person.network.keyFigures',
  'deecPerson:rncs.person.companies',
  'deecPerson:rncs.person.raw',
  'deecPerson:rncs.person.search',
  'rncsCompany:rncs.company.actes',
  'rncsCompany:rncs.company.baseInfo',
  'rncsCompany:rncs.company.bilans',
  'rncsCompany:rncs.company.raw',
  'rncsPerson:rncs.person.companies',
  'rncsPerson:rncs.person.raw'

];
const valueEntityType = [
  'deecCompany',
  'deecPerson',
  'rncsCompany',
  'rncsPerson'
];

function Filter( props: FilterProps ) : ReactElement {
  const theme = useTheme();

  return (
    <Grid>
      <IconFilter style={{ marginTop: '1.4%', marginRight: '1%', marginLeft: '1.4%' }} />
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id='demo-multiple-chip-label'>Entity Type</InputLabel>
        <Select
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          value={props.filterEntityTypeName}
          onChange={(event) => {
            props.handleSetters('filterEntityType', event.target.value as string[]);
          }}
          input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: 20 }}>
              {selected.map(value => (
                <Chip key={value} label={value} sx={{ maxHeight: 20 }}/>
              ))}
            </Box>
          )}
        //   MenuProps={MenuProps}
        >
          {valueEntityType.map(name => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, props.filterEntityTypeName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id='demo-multiple-chip-label'>Type</InputLabel>
        <Select
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          value={props.filterTypeName}
          onChange={(event) => {
            props.handleSetters('filterType', event.target.value as string[]);
          }}
          input={<OutlinedInput id='select-multiple-chip' label='Type' />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: 20 }}>
              {selected.map(value => (
                <Chip key={value} label={value} sx={{ maxHeight: 20 }}/>
              ))}
            </Box>
          )}
        //   MenuProps={MenuProps}
        >
          {valueTypeType.map(name => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, props.filterTypeName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id='demo-multiple-chip-label'>Status</InputLabel>
        <Select
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          value={props.filterSchedulingStatusName}
          onChange={(event) => {
            props.handleSetters('filterSchedulingStatus', event.target.value as string[]);
          }}
          input={<OutlinedInput id='select-multiple-chip' label='Status' />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: 20 }}>
              {selected.map(value => (
                <Chip key={value} label={value} sx={{ maxHeight: 20 }} />
              ))}
            </Box>
          )}
        //   MenuProps={MenuProps}
        >
          {valueStatusType.map(name => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, props.filterEntityTypeName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Tooltip style={{ marginTop: '0.8%' }} title={t('Reset')} placement='top' arrow>
        <Button
          variant='textIcon'
          sx={{
            transform: 'rotate(180deg)',
            opacity: 0.8
          }}
          onClick={() => props.setResetting(true)}
        >
          <IconRotate />
        </Button>
      </Tooltip>
    </Grid>
  );
}

export default Filter;
