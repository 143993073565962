import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import { IconArrowRight, IconCornerDownRight } from '@tabler/icons-react';
import {
  IDataBlock,
  VeegilenzCompanyFact,
  VeegilenzFactAllDataBlockContent,
  VeegilenzPersonFact,
  VeegilenzPersonFactGenericDataBlockContent,
  VeegilenzSummaryAllDataBlockContent,
  zVeegilenzPersonFactMandates,
  zVeegilenzCompanyFactBodacc,
  zVeegilenzCompanyFactRisksAML,
  zVeegilenzCompanyFactLinks,
  VeegilenzCompanyIndirectLink, EntityRef
} from '@deecision/dna-interfaces';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { startCase } from 'lodash';
import {
  VeegilenzCompanyFactReasonCode,
  VeegilenzPersonFactReasonCode
} from '@deecision/dna-interfaces/dist/constants';
import TitleComponent from '@/components/title';
import { CardPart } from '@/components/cards/keyvalue.cards';
import Status from '../../../components/status';
import { KeyValue } from '@/components/keyvalue/keyvaluelist';
import { VeegilenzProps } from './types';

function GetIndirectLinks(props: { indirectLinks?: VeegilenzCompanyIndirectLink[] }): ReactElement {
  const { t } = useTranslation();

  const getEntityName = (entityRef: EntityRef, coma?: boolean) => <Typography variant='body2' noWrap>
    {entityRef.name || entityRef.entityId}{coma ? ',' : ''}
  </Typography>;

  return props.indirectLinks ? (
    <Stack spacing={2}>
      {props.indirectLinks.map(indirectLink => (
        <Grid
          container
          key={indirectLink.companyRef.entityId}
          spacing={1}
          alignItems='center'
        >
          <Grid container>
            {indirectLink.level1PersonRefs.map((entity, index) => (
              <Grid key={entity.entityId}>
                {getEntityName(entity, indirectLink.level1PersonRefs.length > 1 && index !== indirectLink.level1PersonRefs.length - 1)}
              </Grid>
            ))}
          </Grid>
          {indirectLink.level1PersonRefs.length > 0 && (
            <Grid>
              <IconArrowRight size={16} />
            </Grid>
          )}
          <Grid container>
            {indirectLink.level2PersonRefs.map((entity, index) => (
              <Grid key={entity.entityId}>
                {getEntityName(entity, indirectLink.level2PersonRefs.length > 1 && index !== indirectLink.level2PersonRefs.length - 1)}
              </Grid>
            ))}
          </Grid>
          {indirectLink.level2PersonRefs.length > 0 && (
            <Grid>
              <IconArrowRight size={16} />
            </Grid>
          )}
          <Grid>
            <Typography variant='body2' noWrap>
              {indirectLink.companyRef.name || indirectLink.companyRef.entityId}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Stack>
  ) : (
    <>{t('common.utils.unknown')}</>
  );
}

function DirectIndexVeegilenz(props: VeegilenzProps): ReactElement {
  const { t } = useTranslation();
  const dataBlocksWithFact = useLoaderData() as {
    data: IDataBlock<VeegilenzFactAllDataBlockContent>[]
  };
  const dataBlocksWithFacts = useLoaderData() as {
    data: IDataBlock<VeegilenzSummaryAllDataBlockContent>[]
  };

  const getTooltipReason = (reasonCode: VeegilenzPersonFactReasonCode | VeegilenzCompanyFactReasonCode) => (reasonCode !== 'noReason'
    ? t(`veegilenz.reason.${reasonCode}`)
    : undefined);

  const values = useMemo(() => Object.values(props.entityType === 'deecPerson' && VeegilenzPersonFact || props.entityType === 'deecCompany' && VeegilenzCompanyFact)
    .map(fact => (dataBlocksWithFact.data.find(dataBlock => (dataBlock.data as VeegilenzPersonFactGenericDataBlockContent).fact === fact)
      ? dataBlocksWithFact.data
        .filter(dataBlock => dataBlock.data.fact === fact)
        .map((dataBlock) => {
          const dataBlockData = dataBlock.data;
          let value: KeyValue['value'] = t('common.utils.unknown');
          let tooltip;
          let flags;

          if (!dataBlockData) return null;

          zVeegilenzPersonFactMandates.options.forEach((option) => {
            if (dataBlockData.fact === option) {

              value = dataBlockData.metadata?.entityRefs.length.toString();
              tooltip = dataBlockData.metadata?.entityRefs
                .map(entityRef =>
                  startCase(entityRef.name?.toLowerCase())
                )
                .join(', ');
            }
          });

          zVeegilenzCompanyFactBodacc.options.forEach((option) => {
            if (dataBlockData.fact === option) {
              value = dataBlockData.metadata?.publications.length.toString();
              tooltip = dataBlockData.metadata?.publications.map(publication => publication.dateparution.replaceAll('-', '/')).join(', ');
            }
          });

          zVeegilenzCompanyFactRisksAML.options.forEach((option) => {
            if (dataBlockData.fact === option) {
              value = dataBlockData.metadata?.isRisky ? t('common.utils.yes') : t('common.utils.no');
              tooltip = getTooltipReason(dataBlockData.reasonCode);
            }
          });

          if (dataBlockData.fact === VeegilenzCompanyFact.STATE) {
            value = dataBlockData.metadata?.state ? t(`common.utils.${dataBlockData.metadata?.state}`) : t('common.utils.unknown');
            tooltip = getTooltipReason(dataBlockData.reasonCode);
          }

          if (dataBlockData.fact === VeegilenzCompanyFact.FINANCIAL_BILANS) {
            value = dataBlockData.metadata?.lastBilanDate
              ? `${dataBlockData.metadata.lastBilanDate.replaceAll('-', '/')} (${t(`veegilenz.bilanType.${dataBlockData.metadata.lastBilanType || 'unknown'}`)})`
              : t(`veegilenz.reason.${dataBlockData.reasonCode}`);
            tooltip = getTooltipReason(dataBlockData.reasonCode);
          }

          if (dataBlockData.fact === VeegilenzCompanyFact.FINANCIAL_STABILITY) {
            value = `${t('veegilenz.ebitdaDiffPercent')} ${dataBlockData.metadata?.ebitdaDiffPercent || t('common.utils.unknown')}% < ${dataBlockData.metadata?.ebitdaDiffTreshold || t('common.utils.unknown')}%, ${t('veegilenz.turnoverDiffPercent')} ${dataBlockData.metadata?.turnoverDiffPercent || t('common.utils.unknown')}% < ${dataBlockData.metadata?.turnoverDiffTreshold || t('common.utils.unknown')}%`;
            tooltip = getTooltipReason(dataBlockData.reasonCode);
          }

          zVeegilenzCompanyFactLinks.options.forEach((option) => {
            if (dataBlockData.fact === option) {
              value = dataBlockData.metadata?.directLinkEntityRefs ? `${dataBlockData.metadata?.directLinkEntityRefs?.length || 0} direct` : dataBlockData.metadata?.indirectLinks ? `${dataBlockData.metadata?.indirectLinks?.length || 0} indirect`: t('common.utils.unknown');
              tooltip = dataBlockData.metadata?.directLinkEntityRefs
                ? dataBlockData.metadata?.directLinkEntityRefs.map(entityRef => startCase(entityRef.name?.toLowerCase()) || entityRef.entityId).join(', ')
                : <GetIndirectLinks indirectLinks={dataBlockData.metadata?.indirectLinks} />;
            }
          });

          if (dataBlockData.fact === VeegilenzPersonFact.RESIDENCE || dataBlockData.fact === VeegilenzPersonFact.NATIONALITY || dataBlockData.fact === VeegilenzCompanyFact.ADDRESS) {
            value = dataBlockData.metadata?.countryCodes;
            tooltip = getTooltipReason(dataBlockData.reasonCode);
            flags = true;
          }

          return {
            key: t(`veegilenz.keys.${fact}`),
            value,
            tooltip,
            align: 'right' as const,
            statusAsInt: dataBlockData.levelAsInt,
            flags
          };
        })
      : [
        {
          key: t(`veegilenz.keys.${fact}`),
          value: t('veegilenz.errorResponse.notSearched'),
          align: 'right' as const
        }
      ])
    )
    .flat(1),
  [dataBlocksWithFact]
  );

  return (
    <Paper variant='hoverElevation1'>
      <Grid container spacing={4} width='min-content' alignItems='center'>
        <Grid size='grow'>
          <TitleComponent title={t('veegilenz.directIndex')} />
        </Grid>
        <Grid>
          <Tooltip
            title={
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body2'>
                  {t(
                    `veegilenz.reason.${dataBlocksWithFacts.data.find(dataBlock => dataBlock.dataInfo.path.includes('summary'))?.data?.reasonCode || 'noReason'}`
                  )}
                </Typography>
                <Status
                  statusAsInt={
                    (dataBlocksWithFacts.data.find(
                      dataBlock =>
                        dataBlock.dataInfo.path.includes('summary')
                    )?.data?.reasonCode === 'atLeastOneBlackFact' &&
                      3) ||
                    (dataBlocksWithFacts.data.find(
                      dataBlock =>
                        dataBlock.dataInfo.path.includes('summary')
                    )?.data?.reasonCode === 'atLeastOneRedFact' &&
                      2) ||
                    (dataBlocksWithFacts.data.find(
                      dataBlock =>
                        dataBlock.dataInfo.path.includes('summary')
                    )?.data?.reasonCode === 'atLeastOneOrangeFact' &&
                      1) ||
                    (dataBlocksWithFacts.data.find(
                      dataBlock =>
                        dataBlock.dataInfo.path.includes('summary')
                    )?.data?.reasonCode === 'moreThanTreeOrangeFacts' &&
                      1) ||
                    (dataBlocksWithFacts.data.find(
                      dataBlock =>
                        dataBlock.dataInfo.path.includes('summary')
                    )?.data?.reasonCode === 'onlyGreenFacts' &&
                      0) ||
                    0
                  }
                  size={16}
                />
              </Stack>
            }
            arrow
            placement='right'
            hidden={
              dataBlocksWithFacts.data.find(
                dataBlock =>
                  dataBlock.dataInfo.path.includes('summary')
              )?.data?.levelAsInt === undefined
            }
          >
            <Box height={24} width={24} mr={2}>
              <Status
                statusAsInt={
                  dataBlocksWithFacts.data.find(
                    dataBlock =>
                      dataBlock.dataInfo.path.includes('summary')
                  )?.data?.levelAsInt || 0
                }
                size={24}
              />
            </Box>
          </Tooltip>
        </Grid>
        <Grid size={12} p={2} pr={4} pl={4}>
          <CardPart
            values={values.map((value, index) => ({
              ...value,
              key:
                index !== 0 && value?.key === values?.[index - 1]?.key ? (
                  <IconCornerDownRight
                    size={16}
                    style={{ marginLeft: '8px' }}
                  />
                ) : (
                  value?.key
                )
            }))}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DirectIndexVeegilenz;
