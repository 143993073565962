import React, { ReactElement, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { IconArrowRight, IconChartCircles } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { darken, lighten } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomAccordion from '../../../../../components/accordion';
import VennDiagramServices from '../../../../containers/vennDiagram/services/vennDiagram.services';
import { VennDiagramConfig } from '../../../../containers/vennDiagram/types.vennDiagram';
import { getEntityTypeI18nKey } from '../../../../providers/getter';

function VennDiagram(props: { vennDiagram: VennDiagramConfig, disabled?: boolean }): ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = () => navigate({ // Change redirect route here
    pathname: `diagram/${props.vennDiagram._id}`,
    search: location.search
  });

  return (
    <Paper
      key={props.vennDiagram._id}
      variant={!props.disabled ? 'hoverElevation2' : undefined}
      sx={{
        width: '100%',
        p: 2,
        cursor: !props.disabled ? 'pointer' : undefined,
        opacity: props.disabled ? 0.5 : 1
      }}
      onClick={!props.disabled ? redirect : undefined}
    >
      <Stack display='flex' direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
        <Stack flexWrap='wrap' rowGap={2} direction='row' spacing={2} alignItems='center'>
          <IconChartCircles />
          <Typography variant='h4' pr={3}>{props.vennDiagram.label}</Typography>
          <Chip
            label={t(getEntityTypeI18nKey(props.vennDiagram.objectType))}
            size='small'
            color={props.vennDiagram.objectType === 'deecPerson' ? 'primary' : props.vennDiagram.objectType === 'deecCompany' ? 'secondary' : 'warning'}
          />
          {props.vennDiagram.segments.map(segment => (
            <Chip
              label={<Typography fontWeight={500} color={darken(segment.color, 0.5)}>{segment.label}</Typography>}
              style={{ backgroundColor: segment.color.includes('#') || segment.color.includes('rgb') ? lighten(segment.color, 0.6) : undefined }}
              size='small'
            />
          ))}
        </Stack>
        {!props.disabled &&
          <Button
            variant='containedIcon'
            onClick={(event) => {
              event.stopPropagation();
              redirect();
            }}
            sx={{
              minWidth: '40px'
            }}
          >
            <IconArrowRight size={22} />
          </Button>
        }
      </Stack>
    </Paper>
  );
}

function SelectComponentVennDiagram(): ReactElement {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [vennList, setVennList] = useState<VennDiagramConfig[]>();
  const entityType = searchParams.get('entityType');
  const vennDataFactoryService = new VennDiagramServices();

  useEffect(() => {
    vennDataFactoryService.getAll({}).then((res) => {
      setVennList(res.data as VennDiagramConfig[]);
    });
  }, [pathname]);

  return (
    <Stack minWidth={600} spacing={4}>
      <Stack>
        <CustomAccordion title='Venn Diagram' defaultOpen disabled={false}>
          <Stack p={4} spacing={2} >
            {(vennList || []).map((venn: VennDiagramConfig) => (
              <VennDiagram vennDiagram={venn} disabled={!entityType ? false : !(venn.objectType?.includes('Person') && entityType?.includes('Person') || (venn.objectType?.includes('Compan') && entityType?.includes('Compan')))}/>
            ))
            }
          </Stack>
        </CustomAccordion>
      </Stack>

    </Stack>
  );
}

export default SelectComponentVennDiagram;
