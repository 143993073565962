import React, { ReactElement, useMemo } from 'react';
import { darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { EChartOption } from 'echarts';
import { BaseChartsProps } from './types';
import { colorTableForVeegilenz, interpolateColors, sortVeegilenzColor, wrapEmoji } from './utils';
import './style.css';
import valueParser from '@/utils/value-parser';
import getPercentage from '@/utils/getPercentage';
import { mainRadius } from '@/theme/theme';

interface BarChartsProps extends BaseChartsProps {
  yAxisID?: string,
  rotateXAxisLabels?: boolean,
  types?: ('bar' | 'line')[],
  line?: boolean
}

function BarCharts(props: BarChartsProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const onDataClick = (params: EChartOption.Tooltip.Format) => {
    const indexSelected = params.dataIndex || 0;
    props.setSelected?.(indexSelected === props.selected ? undefined : indexSelected);

  };

  const onEvents = {
    click: onDataClick
  };

  const option: EChartOption = useMemo(() => {
    const colors = interpolateColors('#6C93CF', '#977599', props.datas.length || 1);
    const marginLeft = 24 + (props.datas[0]?.map(d => valueParser({ value: d.count, valueType: props.countType, currency: props.currency, t })).sort((a, b) => (b?.toString().length || 0) - (a?.toString().length || 0))[0]?.length || 10) * 7;
    const marginBottom = 24 + (props.datas[0]?.map(d => valueParser({ value: d.value, valueType: props.dataType, currency: props.currency, t })).sort((a, b) => (b?.toString().length || 0) - (a?.toString().length || 0))[0]?.length || 10) * 9;
    const xAxisLabelsRotate = (props.datas[0]?.length || 0) > 1 && (props.dataType === 'amount' || props.dataType === 'string' || props.dataType === 'positionTypes' && props.datas[0]?.some(d => (valueParser({ value: d.value, valueType: props.dataType, currency: props.currency, t })?.length || 0) > 10)) ? 45 : 0;

    return {
      color: colors,
      grid: {
        top: props.legend ? 38 : 24,
        right: 24,
        bottom: (xAxisLabelsRotate || props.rotateXAxisLabels) ? marginBottom > 128 ? 128 : marginBottom : 24,
        left: marginLeft
      },
      legend: props.legends ? {
        data: props.legends ? Array.isArray(props.legends) ? props.legends : [props.legends] : []
      } : undefined,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        extraCssText: `border-radius: ${mainRadius}px; box-shadow: ${theme.shadows[3]}`,
        backgroundColor: theme.palette.background.paper,
        rich: {
          flagEmojiStyle: {
            fontFamily: 'NotoColorEmojiLimited'
          }
        },
        formatter: params => (Array.isArray(params) ?
          params.map(param => `${wrapEmoji(valueParser({ value: param.data.dataValue, range: param.data.range, valueType: props.dataType, currency: props.currency, t }), props.dataType, true)}<br /><b>${param.marker} ${wrapEmoji(valueParser({ value: param.value?.toString() || '', valueType: props.countType, currency: props.currency, t }), props.countType, true)} (${param.data.percentage}%)</b>`).join('<br />') :
          `${wrapEmoji(valueParser({ value: params.data.dataValue, range: params.data.range, valueType: props.dataType, currency: props.currency, t }), props.dataType, true)}<br /><b>${params.marker} ${wrapEmoji(valueParser({ value: params.value?.toString() || '', valueType: props.countType, currency: props.currency, t }), props.countType, true)} (${params.data.percentage}%)</b>`),
        valueFormatter: (value: number) => wrapEmoji(valueParser({ value, valueType: props.countType, currency: props.currency, t }), props.countType)
      },
      xAxis: [
        {
          type: 'category',
          triggerEvent: true,
          axisLabel: {
            rotate: xAxisLabelsRotate,
            rich: {
              flagEmojiStyle: {
                fontFamily: 'NotoColorEmojiLimited'
              }
            },
            width: xAxisLabelsRotate ? 160 : undefined,
            overflow: 'truncate',
            formatter: (value: string) => wrapEmoji(value, props.dataType)
          },
          data: props.datas[0]?.sort(sortVeegilenzColor).map(d => `${valueParser({ value: d.value, range: d.range, valueType: props.dataType, currency: props.currency, t })}`) || []
        }
      ],
      yAxis: [
        {
          name: Array.isArray(props.legends) ? props.legends?.[0] : props.legends,
          axisLabel: {
            rich: {
              flagEmojiStyle: {
                fontFamily: 'NotoColorEmojiLimited'
              }
            },
            width: 64,
            overflow: 'truncate',
            ellipsis: '...',
            formatter: (value: number) => wrapEmoji(valueParser({ value, valueType: props.countType, currency: props.currency, t }), props.countType)
          }
        }
      ],
      series: props.datas.map((data, index) => ({
        type: props.line ? 'line' : props.types?.[index] || 'bar',
        name: Array.isArray(props.legends) ? props.legends?.[index] : props.legends,
        barCategoryGap: '10%',
        emphasis: {
          label: {
            show: true,
            fontSize: 20 - ((data?.length || 0) > 6 ? 6 : (data?.length || 0)),
            fontWeight: 'normal'
          }
        },
        label: {
          show: (props.datas[0]?.length || 0) <= 30,
          position: 'top',
          fontSize: 16 - ((data?.length || 0) > 5 ? 5 : (data?.length || 0)),
          rich: {
            flagEmojiStyle: {
              fontFamily: 'NotoColorEmojiLimited'
            }
          },
          formatter: (params: EChartOption.Tooltip.Format) => ((!props.countType || props.countType === 'number') && (props.datas[0]?.length || 0) <= 10 ?
            `${wrapEmoji(valueParser({ value: params.value, valueType: props.countType, currency: props.currency, t }), props.countType)} (${params.data.percentage}%)` :
            wrapEmoji(valueParser({ value: params.value, valueType: props.countType, currency: props.currency, t }), props.countType))
        },
        data: data ? data.map((d, subIndex) => {
          const total = data.reduce((acc, curr) => acc + (curr.count || 0), 0);
          const color = interpolateColors('#6C93CF', '#977599', data.length || 1);
          const colorToDisplay = (props.line ? 'line' : props.types?.[index]) !== 'line' ? colorTableForVeegilenz[d.value] ?? color[subIndex] : colors[index];
          const selected = props.selected === subIndex;

          return {
            value: d.count,
            range: d.range,
            dataValue: d.value,
            percentage: getPercentage(d.count, total),
            borderRadius: '8px',
            itemStyle: (props.line ? 'line' : props.types?.[index]) !== 'line' ? {
              borderColor: darken(colorToDisplay, selected ? 0.5 : 0.2),
              borderWidth: selected ? 2 : 1,
              borderRadius: mainRadius / 2,
              color: darken(colorToDisplay, selected ? 0.2 : 0)
            } : undefined,
            label: {
              fontWeight: selected ? 900 : 'normal',
              color: (props.line ? 'line' : props.types?.[index]) !== 'line' ? darken(colorToDisplay, selected ? 0.6 : 0.3) : darken(colors[index], selected ? 0.6 : 0.3)
            }
          };
        }) : []
      }))
    };
  }, [props.datas, props.selected]);

  return (
    <ReactECharts
      option={option}
      onEvents={onEvents}
      lazyUpdate
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export default BarCharts;
