import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import { IconCoins, IconIdBadge2, IconUsers } from '@tabler/icons-react';
import Grid from '@mui/material/Grid2';
import { useOutletContext } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { AugmentedEntity, getRelations } from '@/main/providers/getter';
import DashboardSection from '@/main/containers/dashboards/section';
import WidgetsRadar from '@/main/containers/widgets/radar';
import CompanyWidgetsShareHolders from '@/main/containers/widgets/companies/shareholders';
import WidgetsNewsList from '@/main/containers/widgets/news-list';
import CompanyWidgetsFinancialChart from '@/main/containers/widgets/companies/financial-chart';
import CompanyWidgetRelationsPositionsRoles from '@/main/containers/widgets/companies/relations/persons/positions-roles';
import WidgetRelationsListMain from '@/main/containers/widgets/relations-list-main';
import IconBuildings from '@/assets/custom/IconBuildings';
import WidgetCompaniesListMain from '@/main/containers/widgets/companies-list-main';
import CompanyWidgetCompaniesApeRncs from '@/main/containers/widgets/companies/relations/companies/ape-rncs';

function DashboardTabsComponents(): ReactElement {
  const { data } = useOutletContext() as { data: CompanyEntity };
  const { t } = useTranslation();
  const [companiesAugmentedEntities, setCompaniesAugmentedEntities] = useState<AugmentedEntity<CompanyEntity>[]>();
  const [personsAugmentedEntities, setPersonsAugmentedEntities] = useState<AugmentedEntity<PersonEntity>[]>();

  console.log(personsAugmentedEntities);

  const getCompanies =  () => {
    getRelations<CompanyEntity>(data, 'deecCompany')
      .then(res => setCompaniesAugmentedEntities(res));
  };

  const getPersons =  () => {
    getRelations<PersonEntity>(data, 'deecPerson')
      .then(res => setPersonsAugmentedEntities(res));
  };

  useEffect(() => {
    getCompanies();
    getPersons();
  }, [data]);

  return (
    <Grid container overflow='auto' spacing={4}>
      <Grid size='auto' minWidth='min-content'>
        <DashboardSection title={t('common.identity.identity')} icon={IconIdBadge2} direction='column'>
          <WidgetsRadar data={data} fullWidth />
          <CompanyWidgetsShareHolders data={personsAugmentedEntities} fullWidth />
          <WidgetsNewsList data={data} fullWidth />
        </DashboardSection>
      </Grid>
      <Grid size='grow' minWidth='min-content' container direction='column'>
        <Grid minWidth='min-content'>
          <DashboardSection title={t('common.financial.label')} icon={IconCoins}>
            <CompanyWidgetsFinancialChart data={data} fullWidth />
          </DashboardSection>
        </Grid>
        <Grid minWidth='min-content'>
          <DashboardSection
            title={t('relations.label')}
            icon={IconUsers}
            chips={[
              <Chip
                key='dashboard-person-relations-chip-1'
                label={personsAugmentedEntities?.length || 0}
                size='small'
              />
            ]}
          >
            <CompanyWidgetRelationsPositionsRoles data={personsAugmentedEntities} fullWidth fullHeight />
            <WidgetRelationsListMain data={personsAugmentedEntities} fullHeight fullWidth />
          </DashboardSection>
        </Grid>
        <Grid minWidth='min-content'>
          <DashboardSection
            title={t('entities.companies.label')}
            icon={IconBuildings}
            chips={[
              <Chip
                key='dashboard-tab-chip-1'
                label={companiesAugmentedEntities?.length || 0}
                size='small'
              />
            ]}
          >
            <CompanyWidgetCompaniesApeRncs data={companiesAugmentedEntities} fullHeight fullWidth />
            <WidgetCompaniesListMain data={companiesAugmentedEntities} fullHeight fullWidth />
          </DashboardSection>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashboardTabsComponents;
