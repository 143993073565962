import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { EntityLinksAggregation } from '@deecision/dna-interfaces';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { IconCash, IconChevronDown, IconEyeOff, IconLink, IconUsers } from '@tabler/icons-react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconBuildings from '@/assets/custom/IconBuildings';
import { TreeLinksLayerProps, TreeLinksProps } from './types';
// eslint-disable-next-line import/no-cycle
import TreeLink from './link';
import { EntityLinksQueryAggregatorService } from '@/api/services/query';

function TreeLinksLayer(props: TreeLinksLayerProps & { personsToDisplay?: 'all' | 'shareholders' | 'shareholdersFromCompany' }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const scrollToRef = useRef<HTMLDivElement>(null);
  const [openPersonLinks, setOpenPersonLinks] = useState(false);
  const [openCompanyLinks, setOpenCompanyLinks] = useState(false);
  const [linksToCompanies, setLinksToCompanies] = useState<EntityLinksAggregation[]>([]);
  const [linksToPersons, setLinksToPersons] = useState<EntityLinksAggregation[]>([]);
  const [linksToShareHolders, setLinksToShareHolders] = useState<EntityLinksAggregation[]>([]);
  const [personsToDisplay, setPersonsToDisplay] = useState<'all' | 'shareholders' | 'shareholdersFromCompany'>(props.personsToDisplay || 'all');
  const [subLayerOpen, setSubLayerOpen] = useState<TreeLinksProps['entity']>();
  const linksServiceToCompany = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: props.entity.entityType,
    toEntityType: 'deecCompany'
  });
  const linksServiceToPerson = new EntityLinksQueryAggregatorService<EntityLinksAggregation>({
    entityType: props.entity.entityType,
    toEntityType: 'deecPerson'
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const applySort = (data: EntityLinksAggregation[], sortList: typeof props.sortList) => (data.filter(link => !props.parentEntities.includes(link.entity2.entityId)).sort((a, b) => {
    let A: string | number = a.entity2.name?.toUpperCase() || a.entity2.entityId.toUpperCase();
    let B: string | number = b.entity2.name?.toUpperCase() || b.entity2.entityId.toUpperCase();

    if (sortList.find(sort => sort.direction)?.id === 'shares') {
      A = a.links.find(link => link.type === 'has.investedCompany' || link.type === 'has.shareholder')?.details?.sharesPercentage || 0;
      B = b.links.find(link => link.type === 'has.investedCompany' || link.type === 'has.shareholder')?.details?.sharesPercentage || 0;
    }

    if (A === B) {
      B = a.entity2.name?.toUpperCase() || a.entity2.entityId.toUpperCase();
      A = b.entity2.name?.toUpperCase() || b.entity2.entityId.toUpperCase();
    }

    if (sortList.find(sort => sort.direction)?.direction === 'dsc') {
      return (B < A) ? -1 : (B > A) ? 1 : 0;
    }

    return (A < B) ? -1 : (A > B) ? 1 : 0;
  }));

  useEffect(() => {
    setPersonsToDisplay(props.personsToDisplay || 'all');

    if (props.personsToDisplay === 'shareholdersFromCompany') {
      setOpenPersonLinks(true);
    }
  }, [props.personsToDisplay]);

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ block: 'center' });
    }
  }, [scrollToRef]);

  useEffect(() => {
    linksServiceToCompany.get(
      props.id,
      props.filters.find(filter => filter.id === 'showActive')?.active && props.filters.find(filter => filter.id === 'showInactive')?.active ? 'all' : !props.filters.find(filter => filter.id === 'showInactive')?.active
    )
      .then((res) => {
        if (res.data) {
          setLinksToCompanies(applySort(res.data, props.sortList));
        }
      })
      .catch(() => setLinksToCompanies([]));
    linksServiceToPerson.get(
      props.id,
      props.filters.find(filter => filter.id === 'showActive')?.active && props.filters.find(filter => filter.id === 'showInactive')?.active ? 'all' : !props.filters.find(filter => filter.id === 'showInactive')?.active
    )
      .then((res) => {
        if (res.data) {
          setLinksToPersons(applySort(res.data, props.sortList));
        }
      })
      .catch(() => setLinksToPersons([]));
  }, [props.filters, props.sortList]);

  useEffect(() => {
    setLinksToShareHolders(linksToPersons.filter(ltp => (
      ltp.links.some(ltpLink => (ltpLink.type === 'has.networkPerson' && ltpLink.details?.nbCompaniesInCommon && ltpLink.details.nbCompaniesInCommon > 0) || ltpLink.type === 'has.shareholder')
    )));
  }, [linksToPersons]);

  useEffect(() => {
    if (openCompanyLinks && openPersonLinks) {
      setOpenPersonLinks(false);
    }
  }, [openCompanyLinks]);

  useEffect(() => {
    if (openCompanyLinks && openPersonLinks) {
      setOpenCompanyLinks(false);
    }
  }, [openPersonLinks]);

  return (
    <Box height='100vh' sx={{ overflowX: props.isSubLayer ? 'visible' : 'auto', mt: props.isSubLayer ? `2px !important` : undefined }}>
      <Stack width={props.personsToDisplay === 'shareholdersFromCompany' ? '2px' : 'max-content'} p={4} pl={props.isSubLayer ? 0 : undefined}>

        {!props.isSubLayer && openPersonLinks ?
          <Stack direction='row' width='max-content' ml={!props.isSubLayer ? '662px' : '150px'}>
            {personsToDisplay === 'all' && linksToPersons.length > 0 &&
              <>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: (linksToPersons.length - 1) * 124 + 54, borderRadius: 2, mt: '54px' }} />
                <Stack spacing={4} ml='-1px'>
                  {linksToPersons.map((link, index) => (
                    <TreeLink
                      key={`${link.entity1.entityId}-${link.entity2.entityId}`}
                      {...props}
                      personsToDisplay={undefined}
                      link={link}
                      subLayerOpen={subLayerOpen}
                      setSubLayerOpen={subLayer => setSubLayerOpen(subLayer)}
                      index={index}
                    />
                  ))}
                </Stack>
              </>
            }
            {personsToDisplay === 'shareholders' && linksToShareHolders.length > 0 &&
              <>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: (linksToPersons.length - 1) * 124 + 54, borderRadius: 2, mt: '54px' }} />
                <Stack spacing={4} mr='-1px'>
                  {linksToShareHolders.map((link, index) => (
                    <TreeLink
                      key={`${link.entity1.entityId}-${link.entity2.entityId}`}
                      {...props}
                      personsToDisplay={personsToDisplay}
                      link={link}
                      subLayerOpen={subLayerOpen}
                      setSubLayerOpen={subLayer => setSubLayerOpen(subLayer)}
                      index={index}
                    />
                  ))}
                </Stack>
              </>
            }
          </Stack> :

          !props.isSubLayer &&
          <Paper
            sx={{
              width: 140,
              ml: !props.isSubLayer ? '592px' : '80px',
              cursor: linksToPersons.length > 0 ? 'pointer' : undefined,
              '&:hover': {
                boxShadow: linksToPersons.length > 0 ? 2 : 1
              }
            }}
            onClick={linksToPersons.length > 0 ? e => handleClick(e) : undefined}
          >
            <Stack direction='row' spacing={2} alignItems='center'>
              <IconUsers size={16} />
              <Typography sx={{ textAlign: 'center' }}>
                {`${linksToPersons.length} ${t('entities.persons.label')}`}
              </Typography>
            </Stack>
          </Paper>
        }

        <Stack direction='row' alignItems={props.isSubLayer ? 'start' : 'center'} height='min-content' sx={{ zIndex: 98 }}>
          {props.children}
          {!props.isSubLayer &&
            <Paper elevation={3} sx={{ height: '24px', p: 1, ml: '-12px' }}>
              <IconLink size={16} color={theme.palette.primary.main} />
            </Paper>
          }

          {personsToDisplay !== 'shareholdersFromCompany' && <Box sx={{ bgcolor: theme.palette.grey['500'], width: 162 - 12, height: 2 }} />}

          <Stack alignItems='start' ml={personsToDisplay === 'shareholdersFromCompany' ? '-46px' : undefined} mt={personsToDisplay === 'shareholdersFromCompany' ? '4px' : undefined}>
            {props.isSubLayer && <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: 56, borderRadius: 2 }} />}

            {openPersonLinks ?
              <Stack spacing={1} alignItems='center' ml={props.isSubLayer ? '-20px' : '-30px'}>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 40, height: 2, borderRadius: 2, transform: 'rotate(-16deg)' }} />
                <Button variant='textIcon' size='small' onClick={e => (personsToDisplay === 'shareholdersFromCompany' ? handleClose() : handleClick(e))} sx={{ width: props.isSubLayer ? '40px' : '60px', '&:hover': { bgcolor: theme.palette.grey['400'] } }}>
                  {props.isSubLayer ?
                    openPersonLinks ?
                      personsToDisplay === 'all' ?
                        <IconUsers size={18} color={theme.palette.grey['700']} /> :
                        <IconCash size={18} color={theme.palette.grey['700']} /> :
                      <IconBuildings size={18} color={theme.palette.grey['700']} /> :
                    <Stack spacing={2} direction='row' pl={1}>
                      {personsToDisplay === 'all' ?
                        <>
                          <IconUsers size={18} color={theme.palette.grey['700']}/>
                          <IconChevronDown size={16} />
                        </> :
                        <>
                          <IconCash size={18} color={theme.palette.grey['700']}/>
                          <IconChevronDown size={16} />
                        </>
                      }
                    </Stack>
                  }
                </Button>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 40, height: 2, borderRadius: 2, transform: 'rotate(-16deg)' }} />
              </Stack> :
              !props.isSubLayer && <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: 40, borderRadius: 2 }} />
            }

            {!props.isSubLayer && <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: 56, borderRadius: 2 }} />}

            {openCompanyLinks ?
              <Stack spacing={1} alignItems='center' ml='-20px' sx={{ cursor: 'pointer' }}>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 40, height: 2, borderRadius: 2, transform: 'rotate(-16deg)' }} />
                <Button variant='textIcon' size='small' onClick={props.isSubLayer && linksToPersons.length > 0 ? e => handleClick(e) : () => setOpenCompanyLinks(!openCompanyLinks)} sx={{ width: '40px', '&:hover': { bgcolor: theme.palette.grey['400'] } }}>
                  <IconBuildings size={18} color={theme.palette.grey['700']} />
                </Button>
                <Box sx={{ bgcolor: theme.palette.grey['500'], width: 40, height: 2, borderRadius: 2, transform: 'rotate(-16deg)' }} />
              </Stack> :
              !props.isSubLayer && <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: 40, borderRadius: 2 }} />
            }
          </Stack>
        </Stack>

        {!props.isSubLayer && openCompanyLinks ?
          <Stack direction='row' width='max-content' ml={!props.isSubLayer ? '662px' : '150px'}>
            <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: (linksToCompanies.length - 1) * 124 + 54, borderRadius: 2 }} />
            {linksToCompanies.length > 0 &&
              <Stack spacing={4} ml='-1px'>
                {linksToCompanies.map((link, index) => (
                  <TreeLink
                    key={`${link.entity1.entityId}-${link.entity2.entityId}`}
                    {...props}
                    personsToDisplay={undefined}
                    link={link}
                    subLayerOpen={subLayerOpen}
                    setSubLayerOpen={subLayer => setSubLayerOpen(subLayer)}
                    index={index}
                  />
                ))}
              </Stack>
            }
          </Stack> :

          !props.isSubLayer ?
            <Paper
              sx={{
                width: 140,
                ml: !props.isSubLayer ? '592px' : '80px',
                cursor: linksToCompanies.length > 0 ? 'pointer' : undefined,
                '&:hover': {
                  boxShadow: linksToCompanies.length > 0 ? 2 : 1
                }
              }}
              onClick={linksToCompanies.length > 0 ? () => setOpenCompanyLinks(!openCompanyLinks) : undefined}
            >
              <Stack direction='row' spacing={2} alignItems='center'>
                <IconBuildings size={16} />
                <Typography sx={{ textAlign: 'center' }}>
                  {`${linksToCompanies.filter(data => !data.links[0].details?.positionTypes?.includes('adv')).length} ${t('entities.companies.label')}`}
                </Typography>
              </Stack>
            </Paper> :

            (openCompanyLinks || openPersonLinks) ?
              <Stack direction='row' width='max-content' ml={!props.isSubLayer ? '662px' : '150px'}>
                {openCompanyLinks && linksToCompanies.length > 0 &&
                  <>
                    <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: (linksToCompanies.length - 1) * 124 + 54, borderRadius: 2 }} />
                    <Stack spacing={4} ml='-1px'>
                      {linksToCompanies.map((link, index) => (
                        <TreeLink
                          key={`${link.entity1.entityId}-${link.entity2.entityId}`}
                          {...props}
                          personsToDisplay={undefined}
                          link={link}
                          subLayerOpen={subLayerOpen}
                          setSubLayerOpen={subLayer => setSubLayerOpen(subLayer)}
                          index={index}
                        />
                      ))}
                    </Stack>
                  </>
                }
                {openPersonLinks && (linksToPersons.length > 0 ?
                  <>
                    <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: (linksToPersons.length - 1) * 124 + 54, ml: personsToDisplay === 'shareholdersFromCompany' ? '-196px' : undefined, borderRadius: 2 }} />
                    <Stack spacing={4} ml='-1px'>
                      {linksToPersons.map((link, index) => (
                        <TreeLink
                          key={`${link.entity1.entityId}-${link.entity2.entityId}`}
                          {...props}
                          personsToDisplay={personsToDisplay}
                          link={link}
                          subLayerOpen={subLayerOpen}
                          setSubLayerOpen={subLayer => setSubLayerOpen(subLayer)}
                          index={index}
                        />
                      ))}
                    </Stack>
                  </> :
                  personsToDisplay === 'shareholdersFromCompany' && <>
                    <Box sx={{ bgcolor: theme.palette.grey['500'], width: 2, height: 54, ml: personsToDisplay === 'shareholdersFromCompany' ? '-196px' : undefined, borderRadius: 2 }} />
                    <Stack direction='row' alignItems='center' ml='-196px' mt='34px'>
                      <Paper
                        sx={{
                          width: 140,
                          height: 38,
                          ml: '0px'
                        }}
                      >
                        <Stack direction='row' spacing={2} alignItems='center'>
                          <IconCash size={16} />
                          <Typography sx={{ textAlign: 'center' }}>
                            {`${linksToPersons.length} ${t('relations.zeroPersonFound')}`}
                          </Typography>
                        </Stack>
                      </Paper>
                      <Box sx={{ bgcolor: theme.palette.grey['500'], width: 56, height: 2, borderRadius: 2 }} />
                    </Stack>
                  </>
                )}
              </Stack> :
              <Paper
                ref={scrollToRef}
                sx={{
                  width: 140,
                  ml: '80px',
                  cursor: linksToCompanies.length + linksToPersons.length > 0 ? 'pointer' : undefined,
                  '&:hover': {
                    boxShadow: linksToCompanies.length + linksToPersons.length > 0 ? 2 : 1
                  }
                }}
                onClick={
                  linksToCompanies.length + linksToPersons.length > 0 ?
                    e => handleClick(e) :
                    undefined
                }
              >
                <Stack spacing={2}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <IconBuildings size={16} />
                    <Typography sx={{ textAlign: 'center' }}>
                      {`${linksToCompanies.filter(data => !data.links[0].details?.positionTypes?.includes('adv')).length} ${t('entities.companies.label')}`}
                    </Typography>
                  </Stack>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <IconUsers size={16} />
                    <Typography sx={{ textAlign: 'center' }}>
                      {`${linksToPersons.length} ${t('entities.persons.label')}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
        }
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ zIndex: 10000 }} // If used with a FullscreenProvider needs to set a zIndex > to the fullscreen zIndex, otherwise the Menu will be hidden under
      >
        {linksToPersons.length > 0 &&
          <>
            {personsToDisplay !== 'shareholdersFromCompany' &&
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenPersonLinks(true);
                  setPersonsToDisplay('all');
                }}
              >
                <Stack direction='row' spacing={2} alignItems='center'>
                  <IconUsers size={16} />
                  <Typography>{props.isSubLayer ? t('entities.persons.label') : t('common.utils.all')}</Typography>
                  {!props.isSubLayer &&<Typography>({linksToPersons.length})</Typography>}
                </Stack>
              </MenuItem>
            }
            {!props.isSubLayer && props.entity.entityType === 'deecPerson' &&
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenPersonLinks(true);
                  setPersonsToDisplay('shareholders');
                }}
              >
                <Stack direction='row' spacing={2} alignItems='center'>
                  <IconCash size={16} />
                  <Typography>{t('common.utils.coShareHolders')}</Typography>
                  <Typography>({linksToShareHolders.length}/{linksToPersons.length})</Typography>
                </Stack>
              </MenuItem>
            }
            {!props.isSubLayer && props.entity.entityType === 'deecCompany' &&
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenPersonLinks(true);
                  setPersonsToDisplay('shareholders');
                }}
              >
                <Stack direction='row' spacing={2} alignItems='center'>
                  <IconCash size={16} />
                  <Typography>{t('common.utils.shareHolders')}</Typography>
                  <Typography>({linksToShareHolders.length}/{linksToPersons.length})</Typography>
                </Stack>
              </MenuItem>
            }
          </>
        }
        {props.isSubLayer && linksToCompanies.length > 0 && !openCompanyLinks &&
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenCompanyLinks(!openCompanyLinks);
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center'>
              <IconBuildings size={16} />
              <Typography>{t('entities.companies.label')}</Typography>
            </Stack>
          </MenuItem>
        }
        {(props.isSubLayer && (openCompanyLinks || openPersonLinks) || !props.isSubLayer && openPersonLinks) &&
          <MenuItem
            onClick={() => {
              handleClose();
              setOpenPersonLinks(false);
              if (props.isSubLayer) {
                setOpenCompanyLinks(false);
              }
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center'>
              <IconEyeOff size={16} />
              <Typography>{t('common.utils.hide')}</Typography>
            </Stack>
          </MenuItem>
        }
      </Menu>
    </Box>
  );
}

export default TreeLinksLayer;
