import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, FormControl, FormHelperText, FormLabel, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconCheck, IconX, IconPlus, IconFilter, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { GroupDesc } from '@deecision/dna-interfaces';
import Grid from '@mui/material/Grid2';
import CustomAccordion from '../../../../../components/accordion';
import { BaseItemVennDiagramBuilderProps, VennDiagramSegment } from '../../types.vennDiagram';
import VennDiagramServices from '../../services/vennDiagram.services';
import TextBuilderVennDiagram from '../text.builder.vennDiagram';
import ActionBehavior from '../../components/venn.action.behavior';
import ActionColor from '../../components/venn.action.color';
import SelectFiltersToAddModal from '../filterModal/builder.select.filters.modal';
import GenericSegmentVennDiagram from '../../components/genericVennDiagram';

function SegmentsVennDiagram(props: BaseItemVennDiagramBuilderProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [groupDesc, setGroupDesc] = useState<GroupDesc[]>();
  const [segmentToUpdate, setSegmentToUpdate] = useState<VennDiagramSegment>();
  const [usedColorsBySegments, setUsedColorsBySegments] = useState<string[]>(props.data.segments.map(segment => segment.color));
  const dataService = new VennDiagramServices();

  const updateSegment = (newSegment: VennDiagramSegment | undefined) => {
    // console.log('saving', segmentToUpdate ? ' should update' : ' set new value', newSegment);
    props.setData({
      ...props.data,
      segments: segmentToUpdate && newSegment ?
        props.data.segments.map(segment => (segment.id === segmentToUpdate.id ? newSegment : segment))
        :
        [...props.data.segments, newSegment as VennDiagramSegment]
    });
    setSegmentToUpdate(undefined);
    setModalOpen(false);
  };

  const modifyBehaviorSegment = (propertyLabel: keyof VennDiagramSegment, value: 'intersection' | 'union', segmentRef: VennDiagramSegment) => {
    if (propertyLabel !== 'behavior') {
      return;
    }

    props.setData({
      ...props.data,
      segments: props.data.segments.length !== 0 ? props.data.segments.map((segment, i) => (
        {
          ...segment,
          behavior: segment.id === segmentRef.id ? value : props.data.segments[i].behavior || 'intersection'
        }
      )) : []
    });
  };

  const modifyColorSegment = (propertyLabel: keyof VennDiagramSegment, value: string, segmentRef: VennDiagramSegment) => {
    if (propertyLabel !== 'color') {
      return;
    }

    props.setData({
      ...props.data,
      segments: props.data.segments.length !== 0 ? props.data.segments.map((segment, i) => (
        {
          ...segment,
          color: segment.id === segmentRef.id ? value : props.data.segments[i].color || 'intersection'
        }
      )) : []
    });
  };

  useEffect(() => {
    dataService.getGroupDescs().then((res) => {
      setGroupDesc(res.data as GroupDesc[] & { label: string });
    });
  }, []);

  useEffect(() => {
    setUsedColorsBySegments(props.data.segments.map(segment => segment.color));
  }, [props.data]);

  return (
    <>
      <CustomAccordion
        title={<Stack direction='row' spacing={2}>
          <Typography variant='h4'>
            Segments
          </Typography>
          {/* TODO: New conditions for Chip Success */}
          {props.data.segments.length > 0 ?
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconCheck size={18} /></Box>}
              color='success'
              size='small' /> :
            <Chip
              sx={{ '& > *': { p: 2 } }}
              label={<Box display='flex' justifyContent='center'><IconX size={18} /></Box>}
              color='error'
              size='small' />}
        </Stack>}
        defaultOpen
      >
        <Stack width='98%' ml='1%' spacing={4}>
          {props.data.segments.map((segment: VennDiagramSegment, index: number) => (
            <Stack>
              <CustomAccordion
                title={<Stack spacing={6} direction='row' alignItems='center' justifyContent='space-between' width='calc(100% - 16px)'>
                  <FormControl required>
                    <TextBuilderVennDiagram
                      data={props.data}
                      setData={props.setData}
                      path={`segments[${index}].label`}
                      icon={<IconFilter />}
                      variant='h4' />
                  </FormControl>
                  <IconTrash
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      props.setData({
                        ...props.data,
                        segments: props.data.segments.filter((__, i) => i !== index)
                      });
                    } }
                    style={{ cursor: 'pointer' }} />
                </Stack>}
                bgcolor={(segment.groupIds?.length || 0) > 0 ? theme.palette.background.default : theme.palette.grey['50']}
                defaultOpen={(segment.groupIds?.length || 0) < 0 || segment.color === undefined || segment.label === undefined}
                detectHover={(segment.groupIds?.length || 0) > 0}
                borderColor={props.data.segments && props.data.segments[index].groupIds?.length > 0 && props.data.segments[index].color && props.data.segments[index].label ? theme.palette.success.main : theme.palette.error.main}
              >
                <Grid container direction='row' alignItems='center'>
                  <Stack width='60%'>
                    <ActionBehavior segment={segment} modifyBehavior={modifyBehaviorSegment} hideUnion={segment.groupIds.length <= 1} />

                    {/* Filter Section TODO: Remove into another component */}
                    <Stack p={4} pt={0}>
                      <FormControl fullWidth required>
                        <FormLabel id='demo-controlled-radio-buttons-group' sx={{ fontSize: '16px', color: theme.palette.text.primary }}>
                          {`${t('segmentation.builder.filter.selected')}`}
                        </FormLabel>

                        <Box
                          border={1}
                          borderRadius={1}
                          p={2}
                          onClick={() => {
                            setSegmentToUpdate(props.data.segments[index]);
                            setModalOpen(true);
                          } }
                          sx={{ cursor: 'pointer' }}
                        >
                          {props.data.segments[index].groupIds?.map(selected => groupDesc?.find(elem => `${elem.baseGroupId}/${elem.subGroupId}` === selected)?.label).join(', ')}
                        </Box>
                        {!props.data.segments[index].groupIds && <FormHelperText>{t('common.utils.required')}</FormHelperText>}
                      </FormControl>
                    </Stack>

                    <ActionColor segment={segment} modifyColor={modifyColorSegment} usedColors={usedColorsBySegments} />
                  </Stack>
                  <Grid display='flex' size={4} justifyContent='center'>
                    <GenericSegmentVennDiagram behavior={segment.behavior} color={segment.color}/>
                  </Grid>
                </Grid>
              </CustomAccordion>
            </Stack>
          ))}
        </Stack>

        <Stack spacing={4} p={4} alignItems='center'>
          <Button
            variant='contained'
            sx={{ width: 'fit-content' }}
            onClick={() => {
              setModalOpen(true);
            } }
            endIcon={<IconPlus />}
          >
            {`${t('common.utils.new')} segment`}
          </Button>
        </Stack>

        <SelectFiltersToAddModal
          segmentToUpdate={segmentToUpdate}
          updateSegment={updateSegment}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          possibleFilters={groupDesc || []}
          objectType={props.data.objectType}
          usedColorsBySegments={usedColorsBySegments}
        />

      </CustomAccordion></>
  );
}

export default SegmentsVennDiagram;
