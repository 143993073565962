import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import AppMenu, { AppMenuProps, logos } from './appmenu';
import UserMenu, { UserMenuProps } from './userbutton.topbar';
import IconsTopbar, { IconsProps } from './icons';
import { useSidebarContext } from '../hooks/sidebar';
import { useDispLanguageContext } from '../hooks/displanguage';
import CustomTopbarMenuComponents, { CustomTopbarMenuComponentsProps } from './customcomponents';
import LanguageSelector from './language';

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    env: true
  }
}

export interface TopbarProps extends UserMenuProps, IconsProps, AppMenuProps, CustomTopbarMenuComponentsProps {
  app: string,
  env?: string,
  customLogo?: ReactElement,
  customTopbarComponents?: ReactElement | ReactElement[]
}

function Topbar(props: TopbarProps): ReactElement {
  const sidebarValue = useSidebarContext();
  const dispLanguageValue = useDispLanguageContext();

  return (
    <Box
      sx={{
        p: 2,
        pl: sidebarValue?.sidebarOpen ? 3 : undefined,
        pr: 3,
        height: '100%'
      }}
    >
      <Grid container spacing={4} alignItems='center'>
        <Grid>
          <AppMenu {...props} />
        </Grid>
        <Grid alignItems='center' mr={3}>
          <Box
            sx={{
              pt: 1,
              pb: 1,
              cursor: 'pointer'
            }}
            onClick={() => window.location.assign(`/${props.app}`)}
          >
            {props.customLogo ?
              props.customLogo :
              <img src={logos.find(logo => logo.name === props.app)?.svg} alt={props.app} height={38} />
            }
          </Box>
        </Grid>
        {props.env && props.env !== 'prod' && props.env !== 'production' &&
          <Grid>
            <Box
              sx={{
                pt: 3,
                pb: 3
              }}
            >
              <Chip variant='env' label={props.env} color='secondary' size='small' />
            </Box>
          </Grid>
        }
        {props.customTopbarComponents &&
          Array.isArray(props.customTopbarComponents) ?
          props.customTopbarComponents.map(customComponent => (
            <Grid key={customComponent.key}>
              {customComponent}
            </Grid>
          )) :
          <Grid>
            {props.customTopbarComponents}
          </Grid>
        }
        <CustomTopbarMenuComponents {...props} />
        <Grid size='grow' />
        <Grid>
          <IconsTopbar {...props} />
        </Grid>
        {dispLanguageValue?.dispLanguage &&
          <Grid>
            <LanguageSelector />
          </Grid>
        }
        <Grid>
          <UserMenu {...props} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Topbar;
