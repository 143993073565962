import React, { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentationData } from '@deecision/dna-interfaces';
import EntitiesListsSegmentations from './entities.lists.segmentations';
import CustomAccordion from '../../../../../components/accordion';
import AbstractSegmentation, { SegmentationDataContext } from '../abstract';
import { getEntityTypeEquivalent } from '../../../../providers/getter';

function List(props: { entityType?: SegmentationData['objectType'], noAccordion?: boolean }): ReactElement {
  const { t } = useTranslation();
  const segmentationData = useContext(SegmentationDataContext);

  const EntitiesList = useMemo(() => {
    if (!segmentationData || props.entityType && props.entityType !== segmentationData.objectType && getEntityTypeEquivalent(props.entityType) !== segmentationData.objectType) {
      return <></>;
    }

    return (
      <EntitiesListsSegmentations
        data={segmentationData}
        entityType={props.entityType || segmentationData.objectType}
      />
    );
  }, [segmentationData, props.entityType]);

  return (props.noAccordion ? EntitiesList :
    <CustomAccordion
      title={`${segmentationData?.totalCount || 0} ${(segmentationData?.totalCount || 0) > 1 ? t('entities.persons.label') : t('entities.persons.person.label')}`}
      defaultOpen
      disabled={!segmentationData?.totalCount}
    >
      {EntitiesList}
    </CustomAccordion>
  );
}

function SimpleSegmentationList(props: { entityType?: SegmentationData['objectType'], noAccordion?: boolean }): ReactElement {

  return (
    <AbstractSegmentation {...props} withEntities>
      <List {...props} />
    </AbstractSegmentation>
  );
}

export default SimpleSegmentationList;
