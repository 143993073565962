import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { EntityLink } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { IconCirclesRelation } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { startCase } from 'lodash';
import { CurrencyFormatterProps } from '@/utils';
import { getProxemeeLevel } from '../../providers/getter';
import EvenOddTable from '@/components/tables/evenodd/table';
import { Column } from '@/components/tables/types';
import dnaConfig from '../../../config/dna.config.json';
import { ListProps } from './types';

function LinkList(props: ListProps<EntityLink>): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  // console.log((i18nPossibleLanguages.find(lang => lang.global === (i18n.language || i18nPossibleLanguages[0].global)) || i18nPossibleLanguages[0]).version);

  const columns: Column<CurrencyFormatterProps>[] = [
    {
      id: 'entity1',
      label: t('entities.links.table.columns.entity1'),
      minWidth: 240,
      noMaxWidth: true
    },
    {
      id: 'link',
      label: t('entities.links.label')
    },
    {
      id: 'entity2',
      label: t('entities.links.table.columns.entity2'),
      minWidth: 240,
      noMaxWidth: true
    },
    {
      id: 'proxemee',
      label: t('proxemee.label'),
      render: value => (
        <Chip label={getProxemeeLevel(value as number, t, theme)?.label} sx={{ color: getProxemeeLevel(value as number, t, theme)?.color, bgcolor: getProxemeeLevel(value as number, t, theme)?.bgcolor }} size='small' />
      )
    },
    {
      id: 'companiesInCommon',
      label: t('relations.inCommon.companies'),
      align: 'right',
      minWidth: 200
    },
    {
      id: 'positionTypes',
      label: t('entities.links.table.columns.positionTypes'),
      noMaxWidth: true
    },
    {
      id: 'roles',
      label: t('entities.links.table.columns.roles'),
      noMaxWidth: true
    }
    /* ,
    {
      id: 'sharesPercentages',
      label: t('common.utils.sharesPercentage'),
      noMaxWidth: true
    }
     */
  ];

  const rows = props.data.map(link => ({
    id: link._id,
    entity1:
      <Link
        href={typeof props.link === 'string' ? props.link : props.link?.(link._id as string, 'p2pNetworkLink') || `${dnaConfig.routes.persons.relativeUrl}/${link.entity1.entityId as string}`}
        sx={{ whiteSpace: 'nowrap', fontSize: 14 }}
      >
        {link.entity1.name}
      </Link>,
    link:
        <Link
          href={typeof props.link === 'string' ? props.link : props.link?.(link._id as string, 'p2pNetworkLink') || `${dnaConfig.routes.relations.relativeUrl}/show/?entity1=${link.entity1.entityId as string}&entity1Type=${link.entity1.entityType}&entity2=${link.entity2.entityId as string}&entity2Type=${link.entity2.entityType}`}
        >
          <IconButton size='small' onClick={e => e.stopPropagation()}>
            <IconCirclesRelation />
          </IconButton>
        </Link>,
    entity2:
      <Link
        href={typeof props.link === 'string' ? props.link : props.link?.(link._id as string, 'p2pNetworkLink') || `${dnaConfig.routes.persons.relativeUrl}/${link.entity2.entityId as string}`}
        sx={{ whiteSpace: 'nowrap', fontSize: 14 }}
      >
        {link.entity2.name}
      </Link>,
    proxemee: link.details?.prxmee?.level?.codeAsInt || 0,
    companiesInCommon: link.details?.nbCompaniesInCommon || 0,
    roles:
      <Stack spacing={2}>
        {link?.details?.companies?.map(company => (
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='h5' display='flex' alignItems='center'>{startCase((company.companyRef.name || company.companyRef.entityId).toLowerCase())}:</Typography>
            <Chip
              label={
                <Stack direction='row' spacing={1}>
                  <Typography>1:</Typography>
                  <Typography>
                    {company.person1.activeRoles.filter(role => role).length > 0 ? company.person1.activeRoles.filter(role => role).map(role => role?.text).join(', ') : company.person1.inactiveRoles.filter(role => role).length < 1  ? t('error.notFound') : ''}
                  </Typography>
                  {company.person1.inactiveRoles.filter(role => role).length > 0 &&
                    <Typography sx={{ textDecoration: 'line-through' }}>
                      {company.person1.inactiveRoles.filter(role => role).map(role => role?.text).join(', ')}
                    </Typography>
                  }
                </Stack>
              }
              size='small'
            />
            <Chip
              label={
                <Stack direction='row' spacing={1}>
                  <Typography>2:</Typography>
                  <Typography>
                    {company.person2.activeRoles.filter(role => role).length > 0 ? company.person2.activeRoles.filter(role => role).map(role => role?.text).join(', ') : company.person2.inactiveRoles.filter(role => role).length < 1  ? t('error.notFound') : ''}
                  </Typography>
                  {company.person2.inactiveRoles.filter(role => role).length > 0 &&
                    <Typography sx={{ textDecoration: 'line-through' }}>
                      {company.person2.inactiveRoles.filter(role => role).map(role => role?.text).join(', ')}
                    </Typography>
                  }
                </Stack>
              }
              size='small'
            />
          </Stack>
        ))}
      </Stack>,
    positionTypes:
      <Stack spacing={2}>
        {link?.details?.companies?.map(company => (
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='h5' display='flex' alignItems='center'>{startCase((company.companyRef.name || company.companyRef.entityId).toLowerCase())}:</Typography>
            <Chip
              label={
                <Stack direction='row' spacing={1}>
                  <Typography>1:</Typography>
                  <Typography>
                    {company.person1.activePositionTypes.filter(pos => pos).length > 0 ? company.person1.activePositionTypes.filter(pos => pos).map(pos => pos).join(', ') : company.person1.inactivePositionTypes.filter(pos => pos).length < 1  ? t('error.notFound') : ''}
                  </Typography>
                  {company.person1.inactivePositionTypes.filter(pos => pos).length > 0 &&
                    <Typography sx={{ textDecoration: 'line-through' }}>
                      {company.person1.inactivePositionTypes.filter(pos => pos).map(pos => pos).join(', ')}
                    </Typography>
                  }
                </Stack>
              }
              size='small'
            />
            <Chip
              label={
                <Stack direction='row' spacing={1}>
                  <Typography>2:</Typography>
                  <Typography>
                    {company.person2.activePositionTypes.filter(pos => pos).length > 0 ? company.person2.activePositionTypes.filter(pos => pos).map(pos => pos).join(', ') : company.person2.inactivePositionTypes.filter(pos => pos).length < 1  ? t('error.notFound') : ''}
                  </Typography>
                  {company.person2.inactivePositionTypes.filter(pos => pos).length > 0 &&
                    <Typography sx={{ textDecoration: 'line-through' }}>
                      {company.person2.inactivePositionTypes.filter(pos => pos).map(pos => pos).join(', ')}
                    </Typography>
                  }
                </Stack>
              }
              size='small'
            />
          </Stack>
        ))}
      </Stack>
    /* ,
    sharesPercentages:
      <Stack spacing={2}>
        {link?.details?.companies?.map(company => (
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='h5' display='flex' alignItems='center'>{startCase((company.companyRef.name || company.companyRef.entityId).toLowerCase())}:</Typography>
            <Chip
              label={
                <Stack direction='row' spacing={1}>
                  <Typography>1:</Typography>
                  <Typography>
                    {company.person1.activePositionTypes.filter(pos => pos).length > 0 ? company.person1.activePositionTypes.filter(pos => pos).map(pos => pos).join(', ') : t('error.notFound')}
                  </Typography>
                  {company.person1.inactivePositionTypes.filter(pos => pos).length > 0 &&
                    <Typography sx={{ textDecoration: 'line-through' }}>
                      {company.person1.inactivePositionTypes.filter(pos => pos).map(pos => pos).join(', ')}
                    </Typography>
                  }
                </Stack>
              }
              size='small'
            />
            <Chip
              label={
                <Stack direction='row' spacing={1}>
                  <Typography>2:</Typography>
                  <Typography>
                    {company.person2.activePositionTypes.filter(pos => pos).length > 0 ? company.person2.activePositionTypes.filter(pos => pos).map(pos => pos).join(', ') : t('error.notFound')}
                  </Typography>
                  {company.person2.inactivePositionTypes.filter(pos => pos).length > 0 &&
                    <Typography sx={{ textDecoration: 'line-through' }}>
                      {company.person2.inactivePositionTypes.filter(pos => pos).map(pos => pos).join(', ')}
                    </Typography>
                  }
                </Stack>
              }
              size='small'
            />
          </Stack>
        ))}
      </Stack> */
  }));

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <EvenOddTable<CurrencyFormatterProps> label='LinkList' totalCount={props.data.length} {...props} entityType='link' columns={columns} rows={rows} disableLocalSort={[...(props.disableLocalSort || []), 'tags']} />
    </Box>
  );
}

export default LinkList;
