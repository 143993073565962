import React from 'react';
import { Outlet } from 'react-router-dom';
import { IconSearch } from '@tabler/icons-react';
import { Search } from './search';
import { CustomRouteObject } from '@/router/types';
import routesPersons from '@/main/modules/entities/persons/routes';
import routesCompanies from '@/main/modules/entities/companies/routes';
import routesRelations from '@/main/modules/entities/relations/routes';

const routesEntities: CustomRouteObject[] = [
  {
    i18nKey: 'search.label',
    path: 'search',
    element: <Outlet />,
    handle: {
      icon: IconSearch,
      sectionTitle: 'entities.label'
    },
    children: [
      {
        index: true,
        element: <Search />
      }
    ]
  },
  routesRelations,
  routesPersons,
  routesCompanies
];

export default routesEntities;
