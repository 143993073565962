import React, { useEffect } from 'react';
import { Box, Button, CircularProgress, darken, Paper, Stack, Typography, useTheme } from '@mui/material';
import { IconAlertTriangle, IconTableExport } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { NodeType } from '../../types';

type UnloadableComponentProps = {
  action: (csvString: BlobPart, fileName?: string) => void,
  nodes: NodeType[],
  convertToCsv: (nodesToCsv: NodeType[]) => string,
  onCsvDownload: boolean,
  isDataReadyToDownload: boolean,
  triggerDataCalculationForCSV: () => void
}

function UnloadableDueToEntityNumberComponent(props: UnloadableComponentProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const onClickHandler = () => {
    if (props.nodes.length < 3 && !props.isDataReadyToDownload) {
      props.triggerDataCalculationForCSV();
    } else {
      props.action(props.convertToCsv(props.nodes));
    }
  };

  useEffect(() => {
    if (props.isDataReadyToDownload && props.nodes.length > 3) {
      props.action(props.convertToCsv(props.nodes));
    };
  }, [props.isDataReadyToDownload, props.nodes]);

  return (
    <Stack display='flex' justifyContent='center'>
      <Paper elevation={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4, height: 'auto', width: '100%', borderColor: darken(theme.palette.warning.light, 0.1), backgroundColor: theme.palette.warning.light }}>
        <IconAlertTriangle color={theme.palette.warning.dark} style={{ marginRight: 10 }}/>
        <Typography display='flex' alignSelf='center' justifyContent='center' color={theme.palette.warning.dark}>
          {t('network.tree.extended.WideNetworkMsg')}
        </Typography>
      </Paper>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{
          marginTop: 24,
          paddingLeft: 4,
          paddingRight: 4
        }}
      >
        <Button variant='outlined' startIcon={<IconTableExport />} onClick={onClickHandler}>
          <Typography>
            {t('common.utils.downloadFile')} (.csv)
          </Typography>
        </Button>
      </Box>
      {props.nodes.length < 3 && !props.isDataReadyToDownload && props.onCsvDownload &&
        <CircularProgress />
      }
    </Stack>
  );
}

export default UnloadableDueToEntityNumberComponent;