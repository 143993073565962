import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { IconCirclesRelation } from '@tabler/icons-react';
import IconButton from '@mui/material/IconButton';
import PersonList from '../lists/person';
import CompanyList from '../lists/company';
import { AugmentedEntity, computeProxemeeScore, getProxemeeLevel, getRelations } from '../../providers/getter';
import dnaConfig from '../../../config/dna.config.json';
import { WEBAPP_NAME } from '@/env/env';

function DisplayRelationsComponents(props: { data: PersonEntity | CompanyEntity, to: 'deecPerson' | 'deecCompany', link?: ((id: string, entityType: string) => string) | string }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [augmentedEntities, setAugmentedEntities] = useState<AugmentedEntity[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRelations(props.data, props.to)
      .then((res) => {
        setLoading(false);
        setAugmentedEntities(res);
      });
  }, [props]);

  const getList = () => {
    switch (props.to) {
    case 'deecPerson':
      if (augmentedEntities.length === 0 || augmentedEntities?.[1]?.entity?.entityType === 'deecCompany') {
        return loading ? <CircularProgress /> : <Typography>{`0 ${t('relations.zeroFound')}`}</Typography>;
      }

      return (
        <PersonList
          data={augmentedEntities.map(augmentedEntity => augmentedEntity.entity as PersonEntity)}
          label='PersonRelationList'
          link={props.link}
          totalCount={augmentedEntities.length}
          implementColumns={props.data.entityType === 'deecPerson' ?
            [
              {
                columnIndex: 0,
                column: {
                  id: 'link',
                  label: t('entities.relations.header.link'),
                  render: value => <IconButton onClick={() => window.open(`/${WEBAPP_NAME}${dnaConfig.routes.relations.relativeUrl}/show?entity1=${props.data.entityId}&entity1Type=${props.data.entityType}&entity2=${value}&entity2Type=${props.to}`)}>
                    <IconCirclesRelation />
                  </IconButton>
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'link',
                  value: augmentedEntity.entity.entityId
                }))
              },
              {
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'tags',
                  value: (augmentedEntity.details.find((aeDetails: any) => aeDetails?.familyNamesInfo) as any)?.familyNamesInfo.hasFamilyNameInCommon ?
                    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
                      <Tooltip key='family' title={t('proxemee.family.same')} arrow placement='top'>
                        <Chip size='small' label={t('proxemee.family.label')} sx={{ color: theme.palette.secondary.dark, bgcolor: theme.palette.secondary.light }} />
                      </Tooltip>
                    </Stack> : undefined
                }))
              },
              {
                columnIndex: 3,
                column: {
                  id: 'prxmee',
                  label: t('proxemee.label'),
                  render: value => (
                    <Chip label={getProxemeeLevel(value as number, t, theme)?.label} sx={{ color: getProxemeeLevel(value as number, t, theme)?.color, bgcolor: getProxemeeLevel(value as number, t, theme)?.bgcolor }} size='small' />
                  )
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'prxmee',
                  value: computeProxemeeScore(augmentedEntity.details.find(aeDetails => aeDetails?.prxmee)?.prxmee?.level?.codeAsInt, augmentedEntity.details.find(aeDetails => aeDetails?.prxmee)?.prxmee?.score2)
                }))
              },
              {
                columnIndex: 4,
                column: {
                  id: 'commonCompanies',
                  label: t('relations.inCommon.companies'),
                  align: 'right'
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'commonCompanies',
                  value: augmentedEntity.details.find(aeDetails => aeDetails?.nbCompaniesInCommon)?.nbCompaniesInCommon || 0
                }))
              }
            ] :
            [
              {
                columnIndex: 1,
                column: {
                  id: 'roles',
                  label: t('common.utils.roles')
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'roles',
                  value:
                    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
                      {augmentedEntity.details.map(details => details?.role).filter(role => role?.text).map(role =>
                        <Chip key={role?.text} size='small' label={role?.text} />
                      )}
                    </Stack>
                }))
              },
              {
                columnIndex: 2,
                column: {
                  id: 'positionTypes',
                  label: t('common.utils.positionTypes')
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'positionTypes',
                  value:
                    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
                      {augmentedEntity.details.map(details => details?.positionTypes).flat(1).filter(positionType => positionType).map(positionType =>
                        <Chip key={positionType} size='small' label={t(`common.positionTypes.${positionType}`)} />
                      )}
                    </Stack>
                }))
              },
              {
                columnIndex: 3,
                column: {
                  id: 'sharePercentage',
                  label: t('common.utils.sharesPercentage'),
                  align: 'right',
                  render: value => (value ? `${value}%` : undefined)
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'sharePercentage',
                  value: augmentedEntity.details.find(detail => detail?.sharesPercentage)?.sharesPercentage
                }))
              }
            ]
          }
          hideColumns={['nbActiveCompanies', 'nbClosedCompanies', 'nbTotalCompanies', 'nbActiveRelations', 'nbInactiveRelations', 'nbTotalRelations']}
          localSort
          disableLocalSort={['positionTypes']}
          hideFooter
        />
      );
    case 'deecCompany':
      if (augmentedEntities.length === 0 || augmentedEntities[1]?.entity?.entityType === 'deecPerson') {
        return loading ? <CircularProgress /> : <Typography>{`0 ${t('relations.zeroFound')}`}</Typography>;
      }

      return (
        <CompanyList
          data={augmentedEntities.map(augmentedEntity => augmentedEntity.entity as CompanyEntity)}
          link={props.link}
          totalCount={augmentedEntities.length}
          implementColumns={props.data.entityType === 'deecPerson' ?
            [
              {
                columnIndex: 1,
                column: {
                  id: 'positionTypes',
                  label: t('common.utils.positionTypes')
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'positionTypes',
                  value:
                    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
                      {uniqBy(augmentedEntity.details.map(details => details?.positionTypes).flat(1).filter(positionType => positionType), undefined).map(positionType =>
                        <Chip key={positionType} size='small' label={t(`common.positionTypes.${positionType}`)} />
                      )}
                    </Stack>
                }))
              },
              {
                columnIndex: 2,
                column: {
                  id: 'roles',
                  label: t('common.utils.roles')
                },
                rows: augmentedEntities.map(augmentedEntity => ({
                  id: augmentedEntity.entity.entityId,
                  columnId: 'roles',
                  value:
                    <Stack direction='row' spacing={2} maxWidth={340} flexWrap='wrap' useFlexGap>
                      {augmentedEntity.details.map(details => details?.role).filter((role, index) => role?.text && (index > 0 && role.text === 'Non précisé')).map(role =>
                        <Chip key={role?.text} size='small' label={role?.text} />
                      )}
                    </Stack>
                }))
              }
            ] : undefined
          }
          disableLocalSort={['positionTypes', 'roles']}
          localSort
          hideFooter
        />
      );
    default:
      return <></>;
    }
  };

  const EntityList = () => useMemo(() => getList(), [augmentedEntities]);

  return <EntityList />;
}

export default DisplayRelationsComponents;
