import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { PersonEntity } from '@deecision/dna-interfaces';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { IconUsers } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid2';
import IconBuildings from '@/assets/custom/IconBuildings';
import { PersonGetter } from '../../providers/getter';
import BaseEntitiesCards, { BaseEntitiesCardsProps } from './base';
import PersonEntitiesChips from '@/main/containers/chips/person';
import KeyValueList from '@/components/keyvalue/keyvaluelist';

function PersonEntitiesCards(props: BaseEntitiesCardsProps & { commonRelationsWithMain? : string }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  const [entity, setEntity] = useState<PersonEntity>(props.entity as PersonEntity);
  const personGetter = useMemo(() => new PersonGetter({
    data: entity,
    errorMsg: t('common.utils.unknown')
  }), [entity]);

  const financial = entity.dataViews?.data?.companies ? [
    {
      key: t('common.financial.turnover.sum'),
      value: personGetter.getTurnoverSum(),
      completeness: personGetter.getTurnoverSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasse.label'),
      value: personGetter.getWeasse(),
      completeness: personGetter.getWeasseCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasseMax.label'),
      value: personGetter.getWeasseMax(),
      completeness: personGetter.getWeasseMaxCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.fortune.label'),
      value: personGetter.getFortune(),
      align: 'right' as const
    }
  ] : [];

  const companiesFinancial = entity.dataViews?.data?.companies ? [
    {
      key: t('common.financial.turnover.sum'),
      value: personGetter.getTurnoverSum(),
      completeness: personGetter.getTurnoverSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.capital.sum'),
      value: personGetter.getCapitalSum(),
      completeness: personGetter.getCapitalSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.ebitda.sum'),
      value: personGetter.getEbitdaSum(),
      completeness: personGetter.getEbitdaSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.valuation.sum'),
      value: personGetter.getValuationSum(),
      completeness: personGetter.getValuationSumCompleteness(),
      align: 'right' as const
    }
  ] : [];

  const relationsFinancial = entity.dataViews?.data?.companies ? [
    {
      key: t('common.financial.turnover.sum'),
      value: personGetter.getRelationsTurnoverSum(),
      completeness: personGetter.getRelationsTurnoverSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.capital.sum'),
      value: personGetter.getRelationsCapitalSum(),
      completeness: personGetter.getRelationsCapitalSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.ebitda.sumOfSum'),
      value: personGetter.getRelationsEbitdaSumOfSum(),
      completeness: personGetter.getRelationsEbitdaSumOfSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.valuation.sumOfSum'),
      value: personGetter.getRelationsValuationSumOfSum(),
      completeness: personGetter.getRelationsValuationSumOfSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasse.sum'),
      value: personGetter.getRelationsWeasseSum(),
      completeness: personGetter.getRelationsWeasseSumCompleteness(),
      align: 'right' as const
    },
    {
      key: t('common.financial.weasseMax.sum'),
      value: personGetter.getRelationsWeasseMaxSum(),
      completeness: personGetter.getRelationsWeasseMaxSumCompleteness(),
      align: 'right' as const
    }
  ] : [];

  useEffect(() => {
    setEntity(props.entity as PersonEntity);
  }, [props.entity]);

  return (
    <BaseEntitiesCards {...props}>
      <Grid container spacing={2}>
        {PersonEntitiesChips({ entity, size: 'small' }).map(chip => (
          <Grid key={chip.key}>
            {chip}
          </Grid>
        ))}
        <Grid>
          <Tooltip
            title={`${t('entities.companies.label')}: ${t('common.utils.active')} / ${t('common.utils.total')}`}
            arrow
            placement='top'
          >
            <Chip
              color={
                ((personGetter.getNbCompanies(true) as number)
                  - (personGetter.getNbActiveCompanies(true) as number))
                <= (personGetter.getNbActiveCompanies(true) as number) ?
                  'success' : 'error'
              }
              label={
                <Stack spacing={1} direction='row' alignItems='center'>
                  <IconBuildings
                    size={14}
                    color={
                      ((personGetter.getNbCompanies(true) as number)
                        - (personGetter.getNbActiveCompanies(true) as number))
                      <= (personGetter.getNbActiveCompanies(true) as number) ?
                        theme.palette.success.dark : theme.palette.error.dark
                    }
                  />
                  <Typography>
                    {`${personGetter.getNbActiveCompanies(true)}/${personGetter.getNbCompanies(true)}`}
                  </Typography>
                </Stack>
              }
              size='small'
            />
          </Tooltip>
        </Grid>
        <Grid>
          <Tooltip
            title={`${t('entities.persons.alterLabels.relations')}: ${t('common.utils.current')} / ${t('common.utils.total')}`}
            arrow
            placement='top'
          >
            <Chip
              color={
                ((personGetter.getNbRelations() as number)
                  - (personGetter.getNbCurrentRelations() as number))
                <= (personGetter.getNbCurrentRelations() as number) ?
                  'success' : 'error'
              }
              label={
                <Stack spacing={1} direction='row' alignItems='center'>
                  <IconUsers
                    size={14}
                    color={
                      ((personGetter.getNbRelations() as number)
                        - (personGetter.getNbCurrentRelations() as number))
                      <= (personGetter.getNbCurrentRelations() as number) ?
                        theme.palette.success.dark : theme.palette.error.dark
                    }
                  />
                  <Typography>
                    {`${personGetter.getNbCurrentRelations()}/${personGetter.getNbRelations()}`}
                  </Typography>
                </Stack>
              }
              size='small'
            />
          </Tooltip>
        </Grid>
      </Grid>
      {!props.compact &&
        <Stack spacing={4} p={4} width='100%' height={props.full ? '100%' : props.commonRelationsWithMain && !props.commonRelationsWithMain.includes('undefined') ? 164 : 120} divider={<Divider />} overflow='auto'>
          {props.commonRelationsWithMain && !props.commonRelationsWithMain.includes('undefined') &&
            <KeyValueList
              key='commonRelationsCard'
              values={[[{
                key: t('common.utils.commonRelationEntity'),
                value: props.commonRelationsWithMain
              }]]
              }
            />
          }
          <KeyValueList
            key='financialCard'
            values={[financial]}
          />
          {props.full && [
            <Stack spacing={4} key='person-full-company-nb'>
              <Tooltip title={`${t('common.utils.active')} / ${t('common.utils.total')}`} arrow placement='right'>
                <Stack direction='row' spacing={2} alignItems='flex-end'>
                  <IconBuildings color={theme.palette.primary.main} />
                  <Typography variant='h5' color={theme.palette.primary.main}>{t('entities.companies.label')}</Typography>
                  <Typography variant='h5' color={theme.palette.primary.main} whiteSpace='nowrap' ml='auto !important'>
                    {`${personGetter.getNbActiveCompanies()}/${personGetter.getNbCompanies()}`}
                  </Typography>
                </Stack>
              </Tooltip>
              <KeyValueList
                key='companiesFinancial'
                values={[companiesFinancial]}
              />
            </Stack>,
            <Stack spacing={4} key='person-full-relations-nb'>
              <Tooltip title={`${t('common.utils.current')} / ${t('common.utils.total')}`} arrow placement='right'>
                <Stack direction='row' spacing={2} alignItems='flex-end'>
                  <IconBuildings color={theme.palette.secondary.main} />
                  <Typography variant='h5' color={theme.palette.secondary.main}>{t('entities.relations.label')}</Typography>
                  <Typography variant='h5' color={theme.palette.secondary.main} whiteSpace='nowrap' ml='auto !important'>
                    {`${personGetter.getNbCurrentRelations()}/${personGetter.getNbRelations()}`}
                  </Typography>
                </Stack>
              </Tooltip>
              <KeyValueList
                key='relationsFinancial'
                values={[relationsFinancial]}
              />
            </Stack>,
            <Stack>
              {props.commonRelationsWithMain &&
                <Typography>{props.commonRelationsWithMain}</Typography>
              }
            </Stack>
          ]}
        </Stack>
      }
    </BaseEntitiesCards>
  );
}

export default PersonEntitiesCards;
