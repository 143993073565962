import React, { ReactElement, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { darken, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { EChartOption } from 'echarts';
import {
  baseChartColors,
  colorTableForVeegilenz,
  FlagType, wrapEmoji
} from './utils';
import { BaseChartsProps } from './types';
import './style.css';
import getPercentage from '@/utils/getPercentage';
import valueParser from '@/utils/value-parser';
import { mainRadius } from '@/theme/theme';

function PieCharts(props: BaseChartsProps & { flagsData?: Array<FlagType>, showDataLabel?: boolean }): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();

  const option: EChartOption = useMemo(() => {
    const series = props.datas.map(data => ({
      type: 'pie',
      width: props.halfPieChart ? '100%' : '80%',
      height: props.halfPieChart ? '100%' : '100%',
      radius: props.halfPieChart ? ['35%', '70%'] : ['30%', '60%'],
      center: props.halfPieChart ? ['50%', '70%'] : ['50%', '50%'],
      avoidLabelOverlap: !props.halfPieChart,
      showEmptyCircle: true,
      ...(props.halfPieChart ? {
        startAngle: 180,
        endAngle: 360
      } : {}),
      itemStyle: {
        borderRadius: 10,
        borderWidth: 1
      },
      label: {
        show: props.showDataLabel !== false,
        position: 'outside',
        rich: {
          b: {
            fontWeight: 'bold'
          },
          flagEmojiStyle: {
            fontFamily: 'NotoColorEmojiLimited'
          }
        },
        formatter: (params: EChartOption.Tooltip.Format) => `${wrapEmoji(params.data.name, props.dataType)}\n{b|${params.data.percentage}%}`
      },
      emphasis: {
        ...props.legendPosition === 'bottom' || props.showDataLabel === false ? null : {
          label: {
            show: true,
            fontSize: 16,
            padding: 4,
            borderRadius: 10,
            fontWeight: 'normal'
          }
        },
        center: props.legendPosition === 'bottom' ? ['50%', '35%'] : '50%'
      },
      data: data?.map((d, subIndex) => {
        const total = data.reduce((acc, curr) => acc + (curr.count || 0), 0);
        const colorToDisplay = colorTableForVeegilenz[d.value] ?? baseChartColors(theme)[subIndex % baseChartColors(theme).length];
        const selected = props.selected === subIndex;

        return {
          name: valueParser({ value: d.value, range: d.range, valueType: props.dataType, currency: props.currency, t }),
          value: d.count,
          range: d.range,
          percentage: getPercentage(d.count, total),
          itemStyle: {
            borderColor: darken(colorToDisplay, selected ? 0.5 : 0.2),
            borderWidth: selected ? 2 : 1,
            color: darken(colorToDisplay, selected ? 0.2 : 0)
          },
          label: {
            fontWeight: selected ? 900 : 'normal',
            color: darken(colorToDisplay, selected ? 0.6 : 0.3)
          }
        };
      }) || []
    }));

    const formatter = (params: EChartOption.Tooltip.Format | EChartOption.Tooltip.Format[]) => (Array.isArray(params) ?
      params.map(param => `${param.marker} ${param.name}<br />${param.value} (${param.percent}%)`).join('<br />') :
      `${wrapEmoji(params.name || '', props.dataType, true)}<br />${params.marker} <span style="font-weight: bold">${wrapEmoji(params.value?.toString() || '', props.countType, true)} (${params.percent}%)</span>`);

    const legendFormatter = (name: string) => {
      const params = series.find(serie => serie)?.data.find(serieData => serieData.name === name);

      return `${wrapEmoji(params?.name || '', props.dataType)}\r\n${(props.halfPieChart || props.legendPosition === 'bottom') ? '' : '\n'}{b|${params?.percentage || 0}%}`;
    };

    const legendTooltipFormatter = (params: EChartOption.Tooltip.Format | EChartOption.Tooltip.Format[]) => {
      const d = series.find(serie => serie)?.data.find(serieData => serieData.name === (Array.isArray(params) ? params[0].name : params.name));

      return `${wrapEmoji(d?.name || '', props.dataType, true)}<br /><b>${wrapEmoji(d?.value?.toString() || '', props.countType, true)} (${d?.percentage || 0}%)</b>`;
    };

    const tooltip: EChartOption['tooltip'] = {
      show: true,
      trigger: 'item',
      extraCssText: `border-radius: ${mainRadius}px; box-shadow: ${theme.shadows[3]}`,
      backgroundColor: theme.palette.background.paper,
      formatter
    };

    return {
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      },
      tooltip,
      legend: {
        show: true,
        type: 'scroll',
        top: (props.halfPieChart || props.legendPosition === 'bottom') ? undefined : 'center',
        bottom: (props.halfPieChart || props.legendPosition === 'bottom') ? 0 : undefined,
        right: (props.halfPieChart || props.legendPosition === 'bottom') ? undefined : 0,
        left: (props.halfPieChart || props.legendPosition === 'bottom') ? 0 : undefined,
        orient: props.halfPieChart || props.legendPosition === 'bottom' ? 'horizontal' : 'vertical',
        itemWidth: 8,
        textStyle: {
          width: (props.halfPieChart || props.legendPosition === 'bottom') ? 120 : 104,
          overflow: (props.halfPieChart || props.legendPosition === 'bottom') ? 'truncate' : 'break',
          rich: {
            b: {
              fontWeight: 'bold'
            },
            flagEmojiStyle: {
              fontFamily: 'NotoColorEmojiLimited'
            }
          }
        },
        tooltip: {
          ...tooltip,
          formatter: legendTooltipFormatter
        },
        formatter: legendFormatter,
        data: series.find(serie => serie)?.data
      },
      series
    };
  }, [props.datas, props.selected]);

  const onDataClick = (params: EChartOption.Tooltip.Format) => {
    const indexSelected = params.data?.dataIndex || option.series?.find(serie => serie)?.data?.findIndex(data => (data as { name: string }).name === params.name) || 0;

    props.setSelected?.(indexSelected === props.selected ? undefined : indexSelected);
  };

  const onEvents = {
    legendselectchanged: onDataClick,
    click: onDataClick
  };

  return (
    <ReactECharts
      option={option}
      onEvents={onEvents}
      lazyUpdate
      style={{ height: '100%', width: '100%' }}
    />
  );
}

export default PieCharts;
