import { z } from 'zod';
import {
  AndItemSpec,
  FilterItemSpec,
  NotItemSpec,
  OrItemSpec,
  PersonWithAnyCompanyItemSpec,
  RefItemSpec,
  SegmentationCriteriaSpec,
  SegmentationFilterRange,
  SegmentationFilterValue,
  SegmentationPossibleDisplayTypes,
  SegmentationPossibleFilter,
  SegmentationPossibleFilterOnEntityTypes,
  SegmentationPossibleFilterTypes,
  SegmentationPossibleGroupTypes,
  SegmentationPossibleOnEntityTypes,
  SegmentationPossibleOtherTypes,
  SegmentationPossibleOutputEntityTypes,
  SegmentationWorkflowIds,
  zSegmentationCriteriaSpec,
  zSegmentationFilter,
  zSegmentationPossibleFilter
} from '@deecision/dna-interfaces';
import { SegmentationFilter } from '@deecision/dna-interfaces/dist/segmentation/segmentationFilters';

export const filterCategories = ['alert', 'neutral', 'opportunity'] as const;
export const filterCriticity = ['low', 'medium', 'high', 'critic'];

export const zCustomSegmentationDataCriteria = zSegmentationCriteriaSpec;

export const zCustomSegmentationDataFilter = zSegmentationPossibleFilter
  .extend(zSegmentationFilter);

export const zCustomSegmentationData = z.object({
  on: z.enum(['persons', 'companies']),
  globalFilteringItems: zCustomSegmentationDataFilter.array().optional(),
  views: zCustomSegmentationDataCriteria.array().optional()
});

export const zCustomSegmentation = z.object({
  _id: z.string().optional(),
  ownerType: z.string(),
  ownerId: z.string(),
  title: z.string(),
  type: z.string().optional(),
  ready: z.boolean().optional(),
  data: zCustomSegmentationData
});

export interface BaseSegmentationProps {
  filters: SegmentationFilter[],
  addFilter: (filter: SegmentationFilter) => void,
  removeFilter: (filterId: string) => void
}

export type GroupFilter = AndItemSpec | OrItemSpec | NotItemSpec;

export type SimpleFilter = FilterItemSpec | RefItemSpec | PersonWithAnyCompanyItemSpec;

export type CustomSegmentationFilter = Omit<Partial<SegmentationPossibleFilter & SimpleFilter & GroupFilter>, 'on' | 'type'> & {
  filterId?: string,
  filterLabel?: string,
  on?: string,
  ready?: boolean,
  type?: SegmentationPossibleGroupTypes | SegmentationPossibleFilterTypes | SegmentationPossibleOtherTypes,
  category?: typeof filterCategories[number],
  criticity?: typeof filterCriticity[number],
  values?: SegmentationFilterValue[],
  ranges?: SegmentationFilterRange[],
  displayType?: {
    valueType?: 'string' | 'number' | 'boolean' | 'amount' | 'countryCode' | 'countryCode[]' // SegmentationPossibleValueTypes
  },
  displayInfo?: {
    label?: string,
    displayType?: SegmentationPossibleDisplayTypes,
    valueType?: 'string' | 'number' | 'boolean' | 'amount' | 'countryCode' | 'countryCode[]' // SegmentationPossibleValueTypes
  },
  subItemSpecs?: CustomSegmentationFilter[],
  filterSpec?: CustomSegmentationFilter
}

export interface CustomSegmentationView {
  id: string,
  label: string,
  ready?: boolean,
  on?: SegmentationPossibleOnEntityTypes,
  outputEntities?: SegmentationPossibleOutputEntityTypes,
  workflowId?: SegmentationWorkflowIds,
  segmentationCriterias?: (Omit<CustomSegmentationFilter, 'values' | 'ranges'> & SegmentationCriteriaSpec)[]
}

export interface CustomSegmentation {
  on?: string,
  preFilters?: CustomSegmentationFilter[],
  views?: CustomSegmentationView[]
}

export type PotentialSegmentationCriteria = SegmentationPossibleFilter & {
  filterId: string,
  filterLabel: string,
  filterSpec?: CustomSegmentationFilter,
  displayInfo: {
    label?: string,
    displayType: SegmentationPossibleDisplayTypes,
    valueType: 'string' | 'number' | 'boolean' | 'amount' | 'countryCode' | 'countryCode[]' // SegmentationPossibleValueTypes
  }
}

export interface CustomSegmentationData {
  possibleDataSetIds: ('deecPersons' | 'deecCompanies')[],
  possibleOutputEntities: SegmentationPossibleOutputEntityTypes[],
  possibleWorkflowIds: SegmentationWorkflowIds[],
  possibleFilterTypes: SegmentationPossibleOnEntityTypes[],
  possibleCriteriaTypes: SegmentationPossibleOnEntityTypes[],
  possibleCriteriaOnTypes: SegmentationPossibleFilterOnEntityTypes[],
  preFilters: CustomSegmentationFilter[],
  potentialSegmentationCriterias: PotentialSegmentationCriteria[]
}

export interface BaseCustomUserData<T = CustomSegmentation> {
  _id?: string,
  ownerId: string,
  ownerType?: 'group' | 'user',
  groups?: CustomUserDataAccess[],
  title: string,
  date?: Date,
  lastUpdate?: Date,
  type?: string,
  ready?: boolean,
  data: T
}

export type CustomUserData<T = CustomSegmentation> = BaseCustomUserData<T> & {
  _id: string
}

export type CustomUserDataAccess = 'me' | 'admins' | 'everyone';
export const customUserDataAccesses = ['me', 'admins', 'everyone'] as const;
