import React, { ReactElement, ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { IconTargetArrow, IconProps, Icon } from '@tabler/icons-react';

export interface AccuracyProps {
  accuracy?: ReactNode,
  size?: 'small' | 'medium',
  title?: string,
  icon?: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<Icon>>
}

function Accuracy(props: AccuracyProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      {props.accuracy !== undefined &&
        <Tooltip
          title={
            `${t('common.utils.accuracy')}: ${typeof props.accuracy === 'number' ? `${(props.accuracy * 100).toFixed(2)}%` : props.accuracy}`
          }
          arrow
          placement='top'
        >
          <Chip
            size={props.size || 'small'}
            sx={{
              width: props.size !== 'medium' ? 68 : undefined,
              height: props.size !== 'medium' ? 20 : undefined,
              bgcolor: typeof props.accuracy === 'number' ? (props.accuracy < 0.33 && theme.palette.error.light ||
                props.accuracy >= 0.33 && props.accuracy < 0.75 && theme.palette.warning.light ||
                props.accuracy >= 0.75 && theme.palette.success.light || undefined) : undefined,
              color: typeof props.accuracy === 'number' ? (props.accuracy < 0.33 && theme.palette.error.dark ||
                props.accuracy >= 0.33 && props.accuracy < 0.75 && theme.palette.warning.dark ||
                props.accuracy >= 0.75 && theme.palette.success.dark || undefined) : undefined,
              mt: props.size !== 'medium' ? '1px !important' : undefined,
              '& .MuiChip-labelSmall': {
                pl: 1,
                pr: 1
              }
            }}
            label={
              <Stack direction='row' alignItems='center' spacing={1}>
                {props.size !== 'small' && <IconTargetArrow size={props.size === 'medium' ? 22 : 16} />}
                {props.title &&
                  <Typography
                    variant={props.size !== 'medium' ? 'body2' : 'body1'}
                    textAlign='center'
                    sx={{
                      width: props.size !== 'medium' ? '40px' : undefined,
                      fontWeight: 400,
                      mt: '1px'
                    }}
                  >
                    {`${props.title}:`}
                  </Typography>
                }
                <Typography
                  variant={props.size !== 'medium' ? 'body2' : 'body1'}
                  textAlign='center'
                  sx={{
                    width: props.size !== 'medium' ? '40px' : undefined,
                    fontWeight: 500
                  }}
                >
                  {`${typeof props.accuracy === 'number' ? `${props.accuracy === 1 ? props.accuracy * 100 : (props.accuracy * 100).toFixed(2)}%` : '?'}`}
                </Typography>
              </Stack>
            }
          />
          {
            /*
            (props.accuracy < 0.33 &&
              <IconCircleLetterC size={16} style={{ color: theme.palette.error.main }}/> ||
              props.accuracy >= 0.33 && props.accuracy < 0.66 &&
              <IconCircleLetterC size={16} style={{ color: theme.palette.warning.main }}/> ||
              props.accuracy >= 0.66 &&
              <IconCircleLetterC size={16} style={{ color: theme.palette.success.main }}/>) ||
            <></>
            */
          }
        </Tooltip>
      }
    </>
  );
}

export default Accuracy;
