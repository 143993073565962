import React, { ReactElement, useEffect, useState } from 'react';
import { CompanyEntity, PersonEntity } from '@deecision/dna-interfaces';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconIdBadge2, IconUsers } from '@tabler/icons-react';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import IconBuildings from '@/assets/custom/IconBuildings';
import DashboardSection from '@/main/containers/dashboards/section';
import PersonWidgetCompaniesApeRncs from '@/main/containers/widgets/persons/relations/companies/ape-rncs';
import WidgetCompaniesListMain from '@/main/containers/widgets/companies-list-main';
import PersonWidgetCompaniesPositionsRoles from '@/main/containers/widgets/persons/relations/companies/positions-roles';
import WidgetRelationsListMain from '@/main/containers/widgets/relations-list-main';
import WidgetsRadar from '@/main/containers/widgets/radar';
import WidgetsNewsList from '@/main/containers/widgets/news-list';
import { AugmentedEntity, getRelations } from '@/main/providers/getter';
import PersonWidgetRelationsPositionsRoles from '@/main/containers/widgets/persons/relations/persons/positions-roles';

function DashboardTabsComponents(): ReactElement {
  const { data } = useOutletContext() as { data: PersonEntity };
  const { t } = useTranslation();
  const [companiesAugmentedEntities, setCompaniesAugmentedEntities] = useState<AugmentedEntity<CompanyEntity>[]>();
  const [personsAugmentedEntities, setPersonsAugmentedEntities] = useState<AugmentedEntity<PersonEntity>[]>();

  const getCompanies =  () => {
    getRelations<CompanyEntity>(data, 'deecCompany')
      .then(res => setCompaniesAugmentedEntities(res));
  };

  const getPersons =  () => {
    getRelations<PersonEntity>(data, 'deecPerson')
      .then(res => setPersonsAugmentedEntities(res));
  };

  useEffect(() => {
    getCompanies();
    getPersons();
  }, [data]);

  return (
    <Grid container overflow='auto' spacing={4}>
      <Grid size='auto' minWidth='min-content'>
        <DashboardSection title={t('common.identity.identity')} icon={IconIdBadge2} direction='column'>
          <WidgetsRadar data={data} fullWidth />
          <WidgetsNewsList data={data} fullWidth />
        </DashboardSection>
      </Grid>
      <Grid size='grow' minWidth='min-content' container direction='column'>
        <Grid minWidth='min-content'>
          <DashboardSection
            title={t('entities.companies.label')}
            icon={IconBuildings}
            chips={[
              <Chip
                key='dashboard-tab-chip-1'
                label={data.dataViews?.data?.companies?.stats?.all?.count || 0}
                size='small'
              />
            ]}
          >
            <PersonWidgetCompaniesApeRncs data={data} fullHeight fullWidth />
            <PersonWidgetCompaniesPositionsRoles data={data} fullHeight fullWidth />
            <WidgetCompaniesListMain data={companiesAugmentedEntities} fullHeight fullWidth />
          </DashboardSection>
        </Grid>
        <Grid minWidth='min-content'>
          <DashboardSection
            title={t('entities.persons.alterLabels.relations')}
            icon={IconUsers}
            chips={[
              <Chip
                key='dashboard-person-relations-chip-1'
                label={data.dataViews?.data?.network?.stats?.all.count || 0}
                size='small'
              />
            ]}
          >
            <PersonWidgetRelationsPositionsRoles data={personsAugmentedEntities} fullHeight fullWidth />
            <WidgetRelationsListMain data={personsAugmentedEntities} fullHeight fullWidth />
          </DashboardSection>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DashboardTabsComponents;
