import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { IconChevronDown, IconInfoCircle } from '@tabler/icons-react';
import { SPECIAL_COUNTRIES_LISTS_TO_DISPLAY } from '@deecision/dna-interfaces';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import CountriesFlags from '@/components/countriesflags';
import TitleComponent from '@/components/title';
import { getCountryName } from '@/utils';

function VeegilenzCountriesLists(): ReactElement {
  const { t, i18n } = useTranslation();

  return (
    <Paper
      variant='hoverElevation1'
      sx={{
        bgcolor: 'transparent',
        p: 0
      }}
    >
      <Stack spacing={2}>
        <Box p={2} pb={0} pr={4}>
          <TitleComponent
            title={t('veegilenz.lists.countries.label')}
            icon={IconInfoCircle}
          />
        </Box>
        <Stack>
          {Object.keys(SPECIAL_COUNTRIES_LISTS_TO_DISPLAY).map(listId => (
            <Accordion key={listId} sx={{ bgcolor: 'transparent', border: 'none', width: '100%' }}>
              <AccordionSummary expandIcon={<IconChevronDown />} disabled={SPECIAL_COUNTRIES_LISTS_TO_DISPLAY[listId as keyof typeof SPECIAL_COUNTRIES_LISTS_TO_DISPLAY].length === 0}>
                {`${t(`veegilenz.lists.countries.${listId}`)} (${SPECIAL_COUNTRIES_LISTS_TO_DISPLAY[listId as keyof typeof SPECIAL_COUNTRIES_LISTS_TO_DISPLAY].length})`}
              </AccordionSummary>
              <AccordionDetails>
                <Stack>
                  {SPECIAL_COUNTRIES_LISTS_TO_DISPLAY[listId as keyof typeof SPECIAL_COUNTRIES_LISTS_TO_DISPLAY].map(country => (
                    <Stack key={country} direction='row' alignItems='center' spacing={1}>
                      <CountriesFlags countries={[country]} key={country} />
                      <Typography variant='h5'>{getCountryName(country, i18n.language)}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      </Stack>
    </Paper>
  );
}

export default VeegilenzCountriesLists;
