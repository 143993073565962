import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Chip, Divider, IconButton, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { IconExternalLink, IconMinus, IconPlus } from '@tabler/icons-react';
import { DataElementDataStatus, IDataElement } from '@deecision/dna-interfaces';
import { DataElementsQueryService } from '../../../../../api/services/query';
import { WEBAPP_NAME } from '../../../../../env/env';

function KeyValueSpacedBetween(props: {title: string, value: string | DataElementDataStatus | undefined, redirectTo?: string}) {
  const theme = useTheme();

  return <Stack
    flexDirection='row'
    justifyContent='space-between'
    sx={props.redirectTo ? { cursor: 'pointer' } : {}}
    onClick={props.redirectTo ? () => {
      window.open(props.redirectTo, '_blank');
    } : undefined}
  >
    <Typography variant='body1' fontWeight={400} color={theme.palette.text.secondary}>
      {props.title}:
    </Typography>
    <Stack flexDirection='row'>
      <Typography variant='h5' fontWeight={600}>
        {props. value}
      </Typography>
      { props.redirectTo && <IconExternalLink size={10} style={{ marginBottom: '1vh' }}/>}
    </Stack>
  </Stack>;
}

function DataElementHeader(props: {dependedDataElement: IDataElement}) {
  const theme = useTheme();

  return <Stack flexDirection='row' alignContent='center'>
    <Stack
      justifyContent='center'
      alignItems='center'
      sx={{ flex: 1 }}
    >
      <Typography variant='h5'
        pr={10}
        color={theme.palette.primary.dark} sx={{ textAlign: 'center' }} noWrap>
        {props.dependedDataElement.type}
      </Typography>
      <Stack  flexDirection='row'
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          window.open(`/${WEBAPP_NAME}/dataelements/${(props.dependedDataElement as unknown as { _id: string })._id}/summary`, '_blank');
        }}
      >
        <Typography variant='h6' color={theme.palette.primary.dark} sx={{ textAlign: 'center' }} noWrap>
          ({props.dependedDataElement.entityRef.name})
        </Typography>
        <IconExternalLink size={13}/>
      </Stack>
    </Stack>
    <Chip
      key='state'
      sx={{ position: 'sticky' }}
      label={props.dependedDataElement.scheduling.status}
      color={props.dependedDataElement.scheduling.status === 'done' ? 'success' : 'error'}
    />
  </Stack>;
}

type DependenciesGraphProps = {
  dependantDataElementIdToFind: string,
  entityType: string,
  actualDataElementToDisplay?: IDataElement
}

function RenderDataElementInformationBox(dependedDataElement: IDataElement/* IDataElement */) {
  const theme = useTheme();
  const [dataElements, setDataElements] = useState<IDataElement[]>([]);

  useEffect(() => {
    const dataElementServices = new DataElementsQueryService({ entityType: 'deecCompany' });
    const dataElementIds: string[] = dependedDataElement.dependencies.flatMap((element: { dataElementIds: string[] }) => element.dataElementIds);

    dataElementServices.findInAllEntities({ _id: dataElementIds }).then((res) => {
      if (res.data) {
        setDataElements(res.data);
      }
    });
  }, []);

  return (
    <Paper variant='hoverElevation3' sx={{ boxShadow: 2, bgcolor: theme.palette.grey[200], pr: 4, height: 'min-content', width: 400 }}>
      <DataElementHeader dependedDataElement={dependedDataElement} />

      <Divider sx={{ mt: 2, mb: 1 }} />
      <KeyValueSpacedBetween title='Status' value={dependedDataElement.dataInfo.status}/>
      <KeyValueSpacedBetween title='entityName' value={dependedDataElement.entityRef.name} redirectTo={`/${WEBAPP_NAME}/entities/${dependedDataElement.entityRef.entityId as string}/summary`}/>
      <KeyValueSpacedBetween title='EntityType' value={dependedDataElement.entityRef.entityType}/>
      <KeyValueSpacedBetween title='entityId' value={dependedDataElement.entityRef.entityId} redirectTo={`/${WEBAPP_NAME}/entities/${dependedDataElement.entityRef.entityId as string}/summary`}/>
      <Divider sx={{ mt: 2, mb: 1 }} />

      {dependedDataElement.dependencies.length !== 0 ?
        <Typography> Has {dependedDataElement.dependencies?.length} dependencies :
          {dependedDataElement.dependencies.map((element: { dataElementIds: string[] }) => (
            element.dataElementIds.map((id: string, index) =>
              <Stack flexDirection='row' alignItems='center' padding={2}>
                <Stack
                  flexDirection='row'
                  sx={{ cursor: 'pointer' }}
                  onClick={ () => {
                    window.open(`/${WEBAPP_NAME}/entities/${dataElements[index]?.entityRef?.entityId as string}/summary`, '_blank');
                  }}
                >
                  <Typography variant='h5' fontWeight={600}>
                      - {dataElements[index]?.entityRef?.name}({dataElements[index]?.entityRef?.entityId})
                  </Typography>
                  <IconExternalLink size={10} style={{ marginBottom: '1vh' }}/>
                </Stack>
                {dataElements && dataElements[index] && dataElements[index].scheduling &&
                    <Chip
                      key='state'
                      sx={{ position: 'sticky', width: '60px', height: '25px', ml: '5px' }}
                      label={dataElements[index].scheduling.status}
                      color={dataElements[index].scheduling.status === 'done' ? 'success' : 'error'}
                    />
                }
              </Stack>
            )))}
        </Typography>
        : <Typography>Has no dependencies</Typography>
      }
      <Divider sx={{ mt: 2, mb: 1 }} />

      {dependedDataElement.provides.dataBlocks.length !== 0  &&
        <Typography>
          Provider:
          {dependedDataElement.provides.dataBlocks.map(element => (
            <Typography>{element.path} - {element.type}</Typography>))
          }
        </Typography>
      }
      {dependedDataElement.scheduling.partialSets.length !== 0  &&
        <Typography>
          PartialSets:
          {dependedDataElement.scheduling.partialSets.map(element => (
            <Typography>{element}</Typography>))}
        </Typography>
      }
    </Paper>
  );
}

function DataElementDependenciesGraph(dependenciesGraphProps: DependenciesGraphProps) {
  const dataElementServices = new DataElementsQueryService({ entityType: dependenciesGraphProps.entityType });
  const [dependedDataElement, setdependedDataElement] = useState<IDataElement>();
  const [hasDependencies, setHasDependencies] = useState(false);
  const [loadNextCard, setLoadNextCard] = useState(false);

  useEffect(() => {
    dataElementServices.findInAllEntities({ _id: dependenciesGraphProps.dependantDataElementIdToFind }).then((res) => {
      if (res.data) {
        setdependedDataElement(res.data[0]);
        setHasDependencies(res.data[0].dependencies.length !== 0);
      }
    });
  }, []);

  useEffect(() => {
    const query: string[] = [];

    dependedDataElement?.dependencies.forEach(dep => query.concat(dep.dataElementIds));
    dataElementServices.findInAllEntities({
      '_id': {
        '$in': query
      }
    }).then((res) => {
      if (res.data) {
        console.log(res);
      }
    });
  }, [dependedDataElement]);

  return (
    <Stack flexDirection='column'>
      { dependedDataElement &&
      <>
        <Stack flexDirection='row' alignItems='center' marginBottom={5}>
          <RenderDataElementInformationBox {...dependedDataElement}/>
          {hasDependencies &&
            <Tooltip title={loadNextCard ? 'Hide dependencies' : 'Show dependencies'}>
              <IconButton
                onClick={() => {
                  setLoadNextCard(!loadNextCard);
                }}
                color='default'
                aria-label='Load Dependencies'
                size='small'
                sx={{ ml: 0, mr: 1, mt: 1000, height: 30, backgroundColor: 'lightblue' }}
              >
                {loadNextCard ? <IconMinus size={20} /> : <IconPlus size={20}/>}
              </IconButton>
            </Tooltip>
          }
        </Stack>
      </>
      }

      {hasDependencies && loadNextCard &&
        dependedDataElement?.dependencies.map(dependencie => (
          <React.Fragment key={dependencie.dataElementIds[0]} >
            { dependencie.dataElementIds.map(dataElementId => (
              <Stack direction='row' width='max-content'>
                <div style={{ width: 450 }} />
                <DataElementDependenciesGraph actualDataElementToDisplay={dependedDataElement}
                  dependantDataElementIdToFind={dataElementId}
                  entityType='deecCompany'/>
              </Stack>
            ))
            }
          </React.Fragment>
        ))
      }
    </Stack>
  );
}

function DependenciesTabs(): ReactElement {
  const dataElementId = window.location.pathname.split('/')[4];
  const localStorageParams = localStorage.getItem(`entityType${dataElementId}`);
  const entityType = (localStorageParams !== null ?  localStorageParams : '');
  const dataElementServices = new DataElementsQueryService({ entityType });
  const [dataElement, setDataElement] = useState<IDataElement[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dataElementServices.findInAllEntities({ _id: dataElementId });
      const res = Array.isArray(data.data) ? data.data : [];
      setDataElement(res);
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ overflowX: 'auto' }}>
      <Stack  direction='row' width='max-content' sx={{ overflowX: 'auto' }}>
        { dataElement.length !== 0 &&
          dataElement[0].dependencies.map(dependencie => (
            dependencie.dataElementIds.map(dataElemId => (
              <DataElementDependenciesGraph actualDataElementToDisplay={dataElement[0]}
                dependantDataElementIdToFind={dataElemId}
                entityType={entityType}/>
            ))
          ))
        }
      </Stack>
    </Box>
  );
}

export default DependenciesTabs;
