import React, { ReactElement } from 'react';
import Typography from '@mui/material/Typography';
import { TablerIcon } from '@tabler/icons-react';
import Grid from '@mui/material/Grid2';

export interface TitleComponentProps {
  title: string,
  icon?: TablerIcon,
  chips?: ReactElement[],
  size?: 'large' | 'medium' | 'small',
  small?: true
}

function TitleComponent(props: TitleComponentProps): ReactElement {
  return (
    <Grid
      container
      spacing={2}
      width='100%'
    >
      <Grid display='flex' alignItems='center'>
        {props.icon &&
          <props.icon
            size={(props.small || props.size === 'small')
              ? 20
              : props.size === 'medium'
                ? 24
                : 28
            }
          />
        }
      </Grid>
      <Grid display='flex' alignItems='center'>
        <Typography
          variant={(props.small || props.size === 'small')
            ? 'h5'
            : props.size === 'medium'
              ? 'h4'
              : 'h3'
          }
        >
          {props.title}
        </Typography>
      </Grid>
      {props.chips && props.chips.filter(chip => chip.key).map(chip => (
        <Grid key={chip.key} display='flex' alignItems='center'>
          {chip}
        </Grid>
      ))}
    </Grid>
  );
}

export default TitleComponent;
